import React from 'react';

export default function LargePhotoTexts({title, subtitle, photo, littleTitle1, littleTitle2, littleTitle3, corps1, corps2, corps3, width}) {

    return(
        <div className={'largePhotoText'} style={{marginBottom: width > 1200 ? 0 : 0}}>
            <p className={'subtitle'} style={{marginBottom: 15, marginTop: 90}}>{subtitle}</p>
            <p className={'title'} style={{width: 600, maxWidth: '90vw', marginTop: 0, marginBottom: 90}}>{title}</p>

            <img
                src={photo}
                alt=""
                className={'photo'}
                style={{
                    width: width < 1200 ? '100vw' : null,
                    height: width < 1200 ? 'calc(100vw * 0.9)' : null,
                    objectFit: width < 1200 ? 'cover' : null,
                }}
            />

            <div
                className={'containerTextParts'}
                style={{
                    flexDirection: width < 1200 ? 'column' : null,
                }}
            >
                <div className={'textPart'} style={{marginRight: 40, width: width < 1200 ? '80vw' : null, marginLeft: width < 1200 ? 50 : null}}>
                    <p className={width > 1200 ? 'titleText' : 'littleTitleText'}>{littleTitle1}</p>
                    <p className={ width > 1200 ? 'corps' : 'littleCorps'} style={{width: width < 1200 ? '88vw' : null}}>{corps1}</p>
                </div>

                <div className={'textPart'} style={{marginRight: 40, width: width < 1200 ? '80vw' : null, marginLeft: width < 1200 ? 50 : null}}>
                    <p className={width > 1200 ? 'titleText' : 'littleTitleText'}>{littleTitle2}</p>
                    <p className={ width > 1200 ? 'corps' : 'littleCorps'} style={{width: width < 1200 ? '88vw' : null}}>{corps2}</p>
                </div>

                <div className={'textPart'} style={{marginRight: width > 1200 ? null : 40, width: width < 1200 ? '80vw' : null, marginLeft: width < 1200 ? 50 : null}}>
                    <p className={width > 1200 ? 'titleText' : 'littleTitleText'}>{littleTitle3}</p>
                    <p className={ width > 1200 ? 'corps' : 'littleCorps'} style={{width: width < 1200 ? '88vw' : null}}>{corps3}</p>
                </div>
            </div>
        </div>
    )
}
