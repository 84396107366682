import React, {useState, useEffect} from 'react';
import cross from '../../Assets/Images/cross.png'
import leftIconThin from '../../Assets/SVGS/leftIconThin.svg'
import rightIconThin from '../../Assets/SVGS/rightIconThin.svg'
import BigMenuFonctionalites from "./BigMenuFonctionalites";
import BigMenuOpusMobile from "./BigMenuOpusMobile";
import BigMenuOpusWeb from "./BigMenuOpusWeb";
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import leftBleuRandom from "../../Assets/SVGS/leftBleuRandom.svg";
import {useTranslation} from "react-i18next";

export default function Header({setOverflow, opus, blue, menuGrand, setMenuGrand}) {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [grandMenu, setGrandMenu] = useState( false)
    const [menuDeDroite, setMenuDeDroite] = useState(false)
    const [grandMenuFiltre, setGrandMenuFiltre] = useState(false)

    const [fonctSelect, setFonctSlect] = useState(true)
    const [mobileSelect, setMobileSlect] = useState(false)
    const [webSelect, setWebSlect] = useState(false)

    const [menuClient, setMenuClient] = useState(true)
    const [deuxiemeMenu, setDeuxiemeMenu] = useState(false)

    const [selectLangue, setSelectLangue] = useState(false)

    const [langue, setLangue] = useState('fr')

    function LienMenu({titre, leftIcon, link, setDeuxiemeMenu, setMenuClient, client, professionnel}) {

        if (client || professionnel) {
            return (
                <div className={'lienMenu'} onClick={() => {
                    if (client) {
                        setMenuClient(true)
                        setDeuxiemeMenu(true)
                    } else {
                        setMenuClient(false)
                        setDeuxiemeMenu(true)
                    }
                }}>
                    <div style={{width: 340}}>
                        <p className={'titleLienMenu'}>{titre}</p>
                    </div>
                    {leftIcon &&
                        <img src={leftIconThin} alt="" className={'leftIconThin'}/>
                    }
                </div>
            )
        } else {
            if (link === 'https://www.o-medias.com') {
                return (
                    <a href={link} target="_blank">
                        <div className={'lienMenu'}>
                            <div style={{width: 340}}>
                                <p className={'titleLienMenu'}>{titre}</p>
                            </div>
                            {leftIcon &&
                                <img src={leftIconThin} alt="" className={'leftIconThin'}/>
                            }
                        </div>
                    </a>
                )
            } else {
                return (
                    <a href={link}>
                        <div className={'lienMenu'}>
                            <div style={{width: 340}}>
                                <p className={'titleLienMenu'}>{titre}</p>
                            </div>
                            {leftIcon &&
                                <img src={leftIconThin} alt="" className={'leftIconThin'}/>
                            }
                        </div>
                    </a>
                )
            }

        }
    }

    function PetitLienMenu({link, titre}) {
        return(
            <a href={link}>
                <div className={'petitLienMenu'}>
                    <div style={{width: 340}}>
                        <p className={'titleLienMenu'}>{titre}</p>
                    </div>
                </div>
            </a>
        )
    }

    return(
        <div className={'headerComponent'} style={{backgroundColor: grandMenu ? null : blue ? '#f3faff' : null}}>
            <div className={'header'}>

                {opus ?
                    <p className={'logo'} style={{fontSize:  30, marginLeft: 20, marginRight: width > 600 ? null : 0, marginTop: 20,}}>OPUS</p>
                    :
                    <a href="/">
                        <p className={'logo'} style={{fontSize:  30, marginLeft: 20, marginRight: width > 600 ? null : 0, marginTop: 20,}}>OPUS</p>
                    </a>
                }

                <div className={'menu'} style={{marginRight: width > 600 ? 20 : 0
                    , marginTop: 20,}}>

                    {width > 600 &&
                        <div
                            className={'menuItem'} onClick={() => {
                            if (grandMenu || menuGrand) {
                                setGrandMenuFiltre(false)
                                setGrandMenu(false)
                                setOverflow(true)
                            } else {
                                setGrandMenuFiltre(true)
                                setGrandMenu(true)
                                setOverflow(false)
                            }

                        }}>
                            {t('translation.ourFunctionalities')}
                        </div>
                    }

                    <div
                        style={{fontSize: width > 600 ? null : 18, marginRight: width > 600 ? null : 0,}}
                        className={'menuItem'}
                        onClick={() => {
                            setMenuDeDroite(true)
                            setGrandMenuFiltre(true)
                            setOverflow(false)
                        }}
                    >
                        {t('translation.menu')}
                    </div>
                </div>
            </div>


            <div className={'grandMenuFiltre'} style={{height: (grandMenuFiltre || menuGrand) ? menuDeDroite ? '100vh' : 'calc(100vh - 68px)' : '0', opacity:  (grandMenuFiltre || menuGrand) ? 1 : 0, marginTop: (grandMenu || menuGrand) ? 68 : 0}}
                 onClick={() => {
                    setGrandMenuFiltre(false)
                    setGrandMenu(false)
                     setMenuDeDroite(false)
                     setOverflow(true)
                }}
            >

            </div>
            <div className={'grandMenu'} style={{height: grandMenu || menuGrand ? 750 : 0}}>
                <div className={'leftPart'}>
                    <div className={'containerElement'} onClick={() => {
                        setWebSlect(false)
                        setMobileSlect(false)
                        setFonctSlect(true)
                    }}>
                        <p className={fonctSelect ? 'titleSelect' : 'title'}>{t('translation.functionalities')}</p>
                        <p className={fonctSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.allTheToolsNeededToMonitor')}</p>
                    </div>


                    <div className={'containerElement'} onClick={() => {
                        setWebSlect(false)
                        setMobileSlect(true)
                        setFonctSlect(false)
                    }}>
                        <p className={mobileSelect ? 'titleSelect' : 'title'}>OPUS Mobile*</p>
                        <p className={mobileSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.yourProjectsAccessibleAtAGlance')}</p>
                    </div>


                    <div className={'containerElement'} onClick={() => {
                        setWebSlect(true)
                        setMobileSlect(false)
                        setFonctSlect(false)
                    }}>
                        <p className={webSelect ? 'titleSelect' : 'title'}>OPUS Web*</p>
                        <p className={webSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.yourProjectsEverywhereAnytime')}</p>
                    </div>

                </div>

                <div>
                    {fonctSelect ?
                        <BigMenuFonctionalites/>
                        : mobileSelect ?
                            <BigMenuOpusMobile/>
                            :
                            <BigMenuOpusWeb/>
                    }

                </div>




                <div className={'containerCross'}
                     onClick={() => {
                         setGrandMenuFiltre(false)
                         setGrandMenu(false)
                         if (menuGrand) {
                             setMenuGrand(false)
                         }
                         setOverflow(true)
                     }}
                >
                    <img src={cross} alt="" className={'cross'}/>
                </div>
            </div>
            <div className={'menuDeDroite'} style={{width: menuDeDroite ? 400 : 0}}>
                <div className={'headerMenu'}>
                    {deuxiemeMenu ?
                        <div className={'containerRightIcon'}
                             onClick={() => {
                                 setDeuxiemeMenu(false)
                             }}
                        >
                            <img src={rightIconThin} alt="" className={'rightIcon'}/>
                        </div>
                        :
                        <p>OPUS</p>
                    }


                    {deuxiemeMenu ? menuClient ?
                        <div className={'containerJeSuis'}>
                            <p className={'jeSuis'}>{t('translation.iAmAClient')}</p>
                        </div>
                        :
                        <div className={'containerJeSuis'}>
                            <p className={'jeSuis'}>{t('translation.iAmAProfessional')}</p>
                        </div>
                        : null
                    }

                    <div className={'containerCross'}
                         onClick={() => {
                             setGrandMenuFiltre(false)
                             setMenuDeDroite(false)
                             setOverflow(true)
                             setDeuxiemeMenu(false)
                         }}
                         style={{
                             marginLeft: deuxiemeMenu ? 0 : 260,

                         }}
                    >
                        <img src={cross} alt="" className={'cross'}/>
                    </div>
                </div>

                <div style={{opacity: menuDeDroite ? 1 : 0, display: 'flex'}} className={'transitionOpacity'}>
                    <div className={'premierMenu'} style={{marginLeft: deuxiemeMenu ? -525 : 0 }}>
                        <p className={'titlePartMenuDroite'} style={{marginTop: 35, fontSize: width < 740 ? 15 : null}}>{t('translation.aGlobalEnvironmentAUniqueApp')}</p>

                        <LienMenu titre={'OPUS Mobile'} link={'/opus-mobile'}/>
                        <LienMenu titre={'OPUS Web'} link={'/opus-web'}/>
                        {width < 740 &&
                            <LienMenu titre={t('translation.functionalities')} link={'/fonctionnalites'}/>
                        }

                        <p className={'titlePartMenuDroite'} style={{fontSize: width < 740 ? 15 : null}}>{t('translation.aTailoredExperience')}</p>

                        <LienMenu titre={t('translation.iAmAClient')} link={'/opus-client'} leftIcon={true}  setMenuClient={setMenuClient} setDeuxiemeMenu={setDeuxiemeMenu}/>
                        <LienMenu titre={t('translation.iAmAProfessional')} link={'/opus-professionnel'} leftIcon={true} setMenuClient={setMenuClient} setDeuxiemeMenu={setDeuxiemeMenu}/>

                        <p className={'titlePartMenuDroite'} style={{fontSize: width < 740 ? 15 : null}}>{t('translation.opusEnvironment')}</p>


                        <LienMenu titre={t('translation.opusResearch')} link={'/opus-research'}/>
                        <LienMenu titre={t('translation.opusHeritage')} link={'/opus-patrimoine'}/>
                        <LienMenu titre={t('translation.contact')} link={'/contact'}/>
                        <LienMenu titre={t('translation.pricing')} link={'/tarifs'}/>
                        <LienMenu titre={t('translation.aboutUs')} link={'/a-propos-de-nous'}/>


                        {/*
                                          <PetitLienMenu titre={'Conditions générales d\'utilisation'} link={'/tarifs'}/>
                        <PetitLienMenu titre={'Conditions générales de vente'} link={'/tarifs'}/>
                        <PetitLienMenu titre={'Politique de confidentialité'} link={'/tarifs'}/>
                        */}

                        <div style={{
                            height:
                                'calc(100vh - 795px)',
                        }}/>

                        {width > 740 &&
                            <div style={{display: 'flex'}}>

                                <a href="https://opusoft.app" target="_blank">
                                    <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{color: '#fff', marginTop: 0, marginLeft: 30}}>
                                        {t('translation.logIn')}
                                        <img src={leftBlanc} alt="" className={'leftBleu'}/>
                                    </div>
                                </a>

                                <a href="https://opusoft.app/OnBoarding" target="_blank">
                                    <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{color: '#5f74fc', marginTop: 0, marginLeft: 0, width: 200, backgroundColor: 'rgba(255,255,255,0)'}}>
                                        {t('translation.singUp')}
                                        <img src={leftBleuRandom} alt="" className={'leftBleu'}/>
                                    </div>
                                </a>

                            </div>
                        }

                    </div>

                    <div className={'deuxiemeMenu'}>

                        {menuClient ?

                            <div>
                                <p className={'titlePartMenuDroite'} style={{marginTop: 35}}>{t('translation.opusYou')}</p>

                                <LienMenu titre={t('translation.monitorMyProjectDailyWithOpus')} link={'/opus-client'}/>

                                <p className={'titlePartMenuDroite'}>{t('translation.chooseOpusForYourProjects')}</p>

                                <LienMenu titre={t('translation.howToAddMyProjectOnOpus')} link={''}/>
                                <LienMenu titre={t('translation.testYourNewProjectEnvironment')} link={''}/>

                            </div>

                            :

                            <div>
                                <p className={'titlePartMenuDroite'} style={{marginTop: 35}}>{t('translation.opusYou')}</p>

                                <LienMenu titre={t('translation.chooseOpusForMyProjectsMyClientsMyTeam')} link={'/opus-professionnel'}/>

                                <p className={'titlePartMenuDroite'}>{t('translation.chooseOpusForYourProjects')}</p>

                                <LienMenu titre={t('translation.integrationOfYourAgencyOnOpus')} link={''}/>
                                <LienMenu titre={t('translation.testYourNewProjectEnvironment')} link={''}/>
                                <LienMenu titre={t('translation.opusResearchProgram')} link={'/opus-research'}/>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
