import React from 'react';
import LouisRichard from '../../Assets/Images/LouisRichard.jpg'
import QuentinGross from '../../Assets/Images/QuentinGross.jpeg'
import JulesAndrieux from '../../Assets/Images/JulesAndrieux.png'
import GabrielRoy from '../../Assets/Images/GabrielRoy.png'
import AntoinePourcelot from '../../Assets/Images/AntoinePourcelot.jpeg'
import {useTranslation} from "react-i18next";

export default function NosFondateurs({width}) {
    const { t } = useTranslation();

    return(
        <div className={'nosFondateurs'} style={{marginBottom: width > 990 ? null : -100}}>

            <p className={'titleNosFondateurs'}>{t('translation.ourTeam')}</p>

            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: 1300}}>

                <div className={'itemFondateur'}>
                    <img src={LouisRichard} alt="" className={'profilPicture'}/>
                    <p className={'nameFondateur'}>Louis Richard</p>
                    <p className={'posteFondateur'}>Co-Founder & CEO, OPUS</p>

                </div>

                <div className={'itemFondateur'}>
                    <img src={QuentinGross} alt="" className={'profilPicture'}/>
                    <p className={'nameFondateur'}>Quentin Gross</p>
                    <p className={'posteFondateur'}>Co-Founder & CTO, OPUS</p>

                </div>

                <div className={'itemFondateur'}>
                    <img src={JulesAndrieux} alt="" className={'profilPicture'}/>
                    <p className={'nameFondateur'}>Jules Andrieux</p>
                    <p className={'posteFondateur'}>Co-Founder & CBO, OPUS</p>
                </div>

                <div className={'itemFondateur'}>
                    <img src={GabrielRoy} alt="" className={'profilPicture'}/>
                    <p className={'nameFondateur'}>Gabriel Roy</p>
                    <p className={'posteFondateur'}>COO, OPUS</p>
                </div>

                <div className={'itemFondateur'}>
                    <img src={AntoinePourcelot} alt="" className={'profilPicture'}/>
                    <p className={'nameFondateur'}>Antoine Pourcelot</p>
                    <p className={'posteFondateur'}>CFO, OPUS</p>

                </div>


            </div>

            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>


            </div>

            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>


            </div>
        </div>
    )
}
