import React, {useState} from 'react';
import Header from "../../components/Header/Header";
import ContentConditionsGenerales from "./ContentConditionsGenerales";

export default function ConditionsGeneralesUtilisation() {

    const [overflow, setOverflow] = useState(true)

    return(
        <div className={'page'}>

            <div className={'legal'}>
                <p className={'littleTitle'}>CONDITIONS GÉNÉRALES D’UTILISATION</p>
                <p className={'title'}>
                    Découvrez, les règles d’utilisation <br/>
                    de votre plateforme suivi de projet
                </p>
                <p className={'miseAJour'}>Mis à jour le : 20 juin 2022</p>

                <ContentConditionsGenerales/>
            </div>

            <Header setOverflow={setOverflow}/>

        </div>
    )
}
