import React, {useState, useEffect} from 'react';
import progressionWidget from "../../Assets/Images/progressionWidget.png";
import financesWidget from "../../Assets/Images/financesWidget.png";
import photosWidget from "../../Assets/Images/photosWidget.png";
import docWidget from "../../Assets/Images/docWidget.png";
import messagerieWidget from "../../Assets/Images/messagerieWidget.png";
import calendrierWidget from "../../Assets/Images/calendrierWidget.png";
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import {useTranslation} from "react-i18next";

export default function BigMenuFonctionalites() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    function ItemBigMenu({titre, sousTitre, img}) {
        return(
            <div className={'itemBigMenu'}>
                <p className={'title'}>{titre}</p>
                <p className={'subtitle'}>{sousTitre}</p>
                <img src={img} alt="" className={'img'}/>
            </div>
        )
    }

    return(
        <div className={'rightPart'}>

            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <div>
                    <p className={'titlePart'}>{t('translation.preciseMonitoring')}</p>
                    <div style={{display: 'flex'}}>
                        <ItemBigMenu titre={t('translation.progression')} sousTitre={t('translation.monitorProgress')} img={progressionWidget}/>
                        <ItemBigMenu titre={t('translation.finances')} sousTitre={t('translation.monitorProgress')} img={financesWidget}/>
                    </div>
                </div>
                <div style={{
                    height: 300,
                    marginLeft: 30,
                    marginRight: 20,
                    width: 1,
                    backgroundColor: '#eeeeee',
                    marginTop: 45,
                }}/>
                <div>
                    <p className={'titlePart'}>{t('translation.documentedMonitoring')}</p>
                    <div style={{display: 'flex'}}>
                        <ItemBigMenu titre={t('translation.photos')} sousTitre={t('translation.monitorProgress')} img={photosWidget}/>
                        <ItemBigMenu titre={t('translation.files')} sousTitre={t('translation.monitorProgress')} img={docWidget}/>
                    </div>
                </div>
            </div>

            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <div>
                    <p className={'titlePart'}>{t('translation.linkedMonitoring')}</p>
                    <div style={{display: 'flex'}}>
                        <ItemBigMenu titre={t('translation.chat')} sousTitre={t('translation.monitorProgress')} img={messagerieWidget}/>
                    </div>
                </div>

                <div>
                    <p style={{
                        marginTop: 85,
                        marginLeft: 100,
                        fontSize: 30,
                        fontWeight: 600,
                    }}>{t('translation.aToolBoxTailored')} <br/> {t('translation.forYou')} </p>
                    <a href="/fonctionnalites">
                        <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{color: '#fff', marginTop: 30, marginLeft: 100, width: 225}}>
                            {t('translation.addMyProjectOnOpus')}
                            <img src={leftBlanc} alt="" className={'leftBleu'}/>
                        </div>
                    </a>

                </div>
            </div>

        </div>
    )
}
