import React, {useEffect, useState} from 'react';
import nouvelleDimension from '../../Assets/Images/Home Page/nouvelleDimension.png'
import flecheBlanche from '../../Assets/Images/Home Page/flecheBlanche.svg'
import {useTranslation} from "react-i18next";
export default function NouvelleDimension() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={'nouvelleDimension'}>
            <div className={'filtreNoir'} style={{justifyContent: 'center', backgroundImage: width < 1300 ? null : 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))', backgroundColor: width < 1300 ? 'rgba(0,0,0,0.35)' : null}}>
                {width > 1300 &&
                    <div style={{width: '50vw'}}/>
                }
                <div style={{height: 700, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <p className={'title'} style={{marginLeft: 0, width: 600,}}>{t('translation.enterANewDimensionForYourProjects')}</p>

                    {width > 900 &&
                        <>
                            <p className={'corps'}>
                                {t('translation.textHomePage1')}
                            </p>

                            <div style={{display: 'flex'}}>
                                <div className={'boutonDecouvrir'} >
                                    {t('translation.rediscoverASereinDailyEnvironment')}
                                    <img src={flecheBlanche} alt="" style={{width: 9, marginLeft: 8,}}/>
                                </div>
                            </div>
                        </>

                    }



                </div>
            </div>
            <img src={nouvelleDimension} alt="" className={'nouvelleDimensionImage'}/>

        </div>
    )
}
