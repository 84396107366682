import React from 'react';
import CategoryFunctionality from './CategoryFunctionality';
import {useTranslation} from "react-i18next";
import {getOptionsList} from "./OptionsList";

export default function TableauFunctionalities() {

	const { t } = useTranslation();
	const optionsList = getOptionsList(t);

	return (
		<div className={'tableauFunctionalities'}>
			{optionsList.map((item) => (
				<CategoryFunctionality key={item.id} category={item} />
			))}
		</div>
	);
}
