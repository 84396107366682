import React, {useState, useEffect} from 'react';
import clientProjet from '../../Assets/Images/Pour aller Plus Loin/clientProjet.png'
import professionnelSecteur from '../../Assets/Images/Pour aller Plus Loin/professionnelSecteur.png'
import opusWeb from '../../Assets/Images/Pour aller Plus Loin/opusWeb.png'
import opusMobile from '../../Assets/Images/Pour aller Plus Loin/opusMobile.png'
import leftBlanc from '../../Assets/SVGS/leftBlanc.svg'
import {useTranslation} from "react-i18next";

export default function PourAllerPlusLoin() {
    const { t } = useTranslation();


    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    function Lien({title, subtitle, img, link}) {

        return(
            <div className={'lien'} style={{maxWidth: '100vw'}}>


                <div className={'textPart'} style={{maxWidth: '90vw', width: width > 1200 ? null : '90vw', height: width > 1200 ?  null : width > 800 ? 350 : 270}}>
                    <p className={'titleLien'}>{title}</p>
                    <p className={'subtitleLien'}>{subtitle}</p>

                    <a href={link}>
                        <div className={'enSavoirPlus'}>
                            {t('translation.getToKnowMore')}
                            <img src={leftBlanc} alt="" className={'leftBlanc'} />
                        </div>
                    </a>

                </div>

                <div>
                    <img src={img} alt="" className={'img'}  style={{maxWidth: '90vw', objectFit: 'cover', width: width > 1200 ? null : '90vw', height: width > 1200 ?  null : width > 800 ? 350 : 270}}/>
                </div>
            </div>
        )
    }

    return(
        <div className={'pourAllerPlusLoin'}>

            <div style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column', marginBottom: width > 1200 ? 20 : 0}}>
                <Lien link={'/opus-mobile'} img={opusMobile}  title={'OPUS Mobile'} subtitle={t('translation.subtitleHomePage1')}/>
                <Lien link={'/opus-web'} img={opusWeb}  title={'OPUS Web'} subtitle={t('translation.subtitleHomePage2')}/>
            </div>

            {/*
                      <div style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column'}}>
                <Lien link={'/opus-professionnel'} img={professionnelSecteur}  title={'Je suis professionnel du secteur'} subtitle={'Offrez à vos clients et collaborateurs des outils de suivi de projet conçus pour eux. '}/>
                <Lien link={'/opus-client'} img={clientProjet} title={'Je suis client d’un projet'} subtitle={'Découvrez la sérénité dans le Suivi de projet en utilisant OPUS au quotidien.'}/>
            </div>
            */}


        </div>
    )
}
