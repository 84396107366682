import React, {useEffect, useState} from 'react';
import offerClientInfography from '../../Assets/Images/Home Page/offerClientInfography.png'
import pourLesPassionnesOPUS from '../../Assets/Images/Home Page/pourLesPassionnesOPUS.png'
import offerClientPhoto from "../../Assets/Images/Home Page/offerClientPhoto.png";
import {useTranslation} from "react-i18next";

export default function PourLesPassionnes() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={'offerYourClient'} >

            {width < 1300 ?
                <div style={{flexDirection: width < 1300 ? 'column' : 'row', backgroundColor: '#FFF', marginTop: 60, width: '90vw', borderRadius: 20, marginBottom: 100}}>
                    <div className={'leftPart'} style={{width: width < 1300 ? '90vw' : null, marginTop: 50, marginBottom: 20}}>

                        <p className={'title'} style={{fontSize: 31, maxWidth: '90vw', letterSpacing: -0.5, textAlign: 'center'}}> <span style={{color: '#5c70f2'}}>{t('translation.architectDecorator')}</span>{t('translation.focus')}<br/>{t('translation.onWhatMatter')}</p>

                    </div>

                    <div className={'photoPart'} style={{width: '90vw', height: 400,}}>

                        <img src={pourLesPassionnesOPUS} alt="" className={'offerClientPhoto'} style={{width: '80vw', borderRadius: 20, height: 400, marginLeft: '5vw'}}/>
                    </div>

                    <div className={'leftPart'} style={{width: width < 1300 ? '90vw' : null, marginTop: 0, display: 'flex', alignItems: 'center'}}>

                        <p className={'corps'} style={{marginTop: 35, maxWidth: '75vw', marginBottom: 30, fontSize: 22, lineHeight: 1.3, fontWeight: '600'}}>
                            {t('translation.textHomePage3')}
                        </p>

                    </div>

                </div>

                :

                <div style={{display: 'flex', flexDirection:'row'}}>
                    <div className={'photoPart'} style={{width: width < 1300 ? '100vw' : null}}>

                        <img src={pourLesPassionnesOPUS} alt="" className={'offerClientPhoto'} style={{width: width < 1300 ? '100vw' : null}}/>
                    </div>

                    <div className={'leftPart'} style={{width: width < 1300 ? '100vw' : null, height: width < 1300 ? 350 : 750, justifyContent: width < 1300 ? 'flex-end' : 'center'}}>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className={'degradeOfferClientRight'} style={{zIndex: 1}}/>

                            <p className={'title'} style={{fontSize: 35, maxWidth: '75vw', letterSpacing: -0.5, zIndex: 2}}><span style={{color: '#5c70f2'}}>{t('translation.architectDecorator')}</span> <br/>{t('translation.focusonWhatMatter')}</p>
                            <div className={'bar'}/>
                            <p className={'corps'} style={{marginTop: 30, maxWidth: '75vw', marginBottom: 30, fontSize: 22, lineHeight: 1.3, fontWeight: '600'}}>
                                {t('translation.textHomePage3')}
                            </p>
                        </div>


                    </div>
                </div>
            }



        </div>
    )
}
