import React, {useState} from 'react';
import Header from "../../components/Header/Header";

export default function Entreprise() {

    const [overflow, setOverflow] = useState(true)

    return(
        <div className={'page'}>

            <Header setOverflow={setOverflow}/>

        </div>
    )
}
