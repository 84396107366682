import React, {useState, useEffect} from 'react';
import Header from "../../components/Header/Header";
import TroisImages from "../../components/TroisImages";
import Footer from "../../components/Footer";
import ContentOpusPatrimoine from "./ContentOpusPatrimoine";

export default function OpusPatrimoine() {

    const [overflow, setOverflow] = useState(true)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'}>
            <Header setOverflow={setOverflow}/>

            <div>
                <ContentOpusPatrimoine width={width}/>
            </div>

            <TroisImages patrimoine={true} width={width}/>

            <div style={{height: width > 1200 ? 150 : 50}}/>

            <Footer/>


        </div>
    )
}
