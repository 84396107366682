import React, {useState, useEffect} from 'react';
import Header from "../../components/Header/Header";
import {CategoryScale} from 'chart.js';
import Chart from 'chart.js/auto';
import Testimonies from "./Testimonies";
import HomePageSecondPart from "./HomePageSecondPart";
import HomePageThirdPart from "./HomePageThirdPart";
import AmbitionWidget from "./AmbitionWidget";
import ambitionPatrimoine from '../../Assets/Images/Ambitions/ambitionPatrimoine.JPG'
import ambitionRecherche from '../../Assets/Images/Ambitions/ambitionRecherche.jpeg'
import HomePageFourthpart from "./HomePageFourthpart";
import Footer from "../../components/Footer";
import triangleBleu from '../../Assets/SVGS/triangleBleu.svg'
import PretAVousLancer from "./PretAVousLancer";
import PourAllerPlusLoin from "./PourAllerPlusLoin";
import TransmettreLInformation from "./TransmettreLInformation";
import NouvelleDimension from "./NouvelleDimension";
import OfferYourClients from "./OfferYourClients";
import PourLesPassionnes from "./PourLesPassionnes";
import VideoOPUS from "./VideoOPUS";
import Simplissime from "./Simplissime";

export default function Accueil() {

    Chart.register(CategoryScale);

    const [overflow, setOverflow] = useState(true)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'}>

            <div style={{zIndex: 3}}>
                <HomePageSecondPart setOverflow={setOverflow}/>
            </div>

            <div style={{zIndex: 4}}>
                <HomePageThirdPart/>
            </div>

            <div>
                <TransmettreLInformation/>
            </div>

            <div>
                <NouvelleDimension/>
            </div>

            <div>
                <OfferYourClients/>
            </div>

            <div>
                <PourLesPassionnes/>
            </div>

            <div>
                <VideoOPUS/>
            </div>

            <div>
                <Simplissime/>
            </div>

            <div style={{zIndex: 8}}>
                <PourAllerPlusLoin/>
            </div>

            <div style={{zIndex: 6}}>
                <Footer/>
            </div>


        </div>
    )
}
