import React, {useState} from 'react';
import dashboardWeb from "../../Assets/Images/Sreen Web/dashboardWeb.png";
import progressionWeb from "../../Assets/Images/Sreen Web/progressionWeb.png";
import financesWeb from "../../Assets/Images/Sreen Web/financesWeb.png";
import albumsPhotos from "../../Assets/Images/Sreen Web/albumsPhotos.png";
import messagerieProjet from "../../Assets/Images/Sreen Web/messagerieProjet.png";
import fichiersWeb from "../../Assets/Images/Sreen Web/fichiersWeb.png";
import {useTranslation} from "react-i18next";

export default function BigMenuOpusWeb() {
    const { t } = useTranslation();

    const [dashboardSelect, setDashboardSelect] = useState(true)
    const [dashboardProgressionSelect, setDashboardProgressionSelect] = useState(false)
    const [progressionSelect, setProgressionSelect] = useState(false)
    const [financesSelect, setFinancesSelect] = useState(false)
    const [photosSelect, setPhotosSelect] = useState(false)
    const [messagerieSelect, setMessagerieSelect] = useState(false)
    const [fichiersSelect, setFichiersSelect] = useState(false)

    return(
        <div className={'rightPart'}>

            <div className={'opus'}>
                <div className={'leftPart'} style={{borderRightWidth: 0, marginTop: 0}}>
                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(true)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={dashboardSelect ? 'titleSelect' : 'title'} style={{fontSize: 20, marginTop: 50,}}>{t('translation.dashboard')}</p>
                        <p className={dashboardSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.yourProjectEvolutionAtAGlance')}</p>
                    </div>


                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(true)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={progressionSelect ? 'titleSelect' : 'title'}>{t('translation.progression')}</p>
                        <p className={progressionSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.monitorTheProgressOfYourProject')}</p>
                    </div>


                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(true)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={financesSelect ? 'titleSelect' : 'title'}>{t('translation.finances')}</p>
                        <p className={financesSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.monitorTheFinancesOfYourProject')}</p>
                    </div>

                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(true)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={photosSelect ? 'titleSelect' : 'title'}>{t('translation.photos')}</p>
                        <p className={photosSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.benefitFromAVisualMonitoring')}</p>
                    </div>

                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(true)
                        setFichiersSelect(false)
                    }}>
                        <p className={messagerieSelect ? 'titleSelect' : 'title'}>{t('translation.chat')}</p>
                        <p className={messagerieSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.chatWithYourProjectStakeholders')}</p>
                    </div>

                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(true)
                    }}>
                        <p className={fichiersSelect ? 'titleSelect' : 'title'}>{t('translation.files')}</p>
                        <p className={fichiersSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.accessAnyDocument')}</p>
                    </div>

                </div>

                <div style={{display: 'flex'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                        <img
                            src={

                                dashboardSelect ?
                                    dashboardWeb
                                    : progressionSelect ?
                                        progressionWeb
                                        : financesSelect ?
                                            financesWeb
                                            : photosSelect ?
                                                albumsPhotos
                                                : messagerieSelect ?
                                                    messagerieProjet
                                                    : fichiersSelect ?
                                                        fichiersWeb
                                                        : null

                            }
                            alt=""
                            className={'web'}/>
                    </div>

                </div>
            </div>

        </div>
    )
}
