import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import "firebase/firestore";
import "firebase/auth";
import Tarifs from "./pages/Tarifs/Tarifs"
import ScrollToTop from "./components/ScrollToTop";
import Accueil from "./pages/Home Page/HomePage";
import Contact from "./pages/Contact/Contact";
import OPUSWeb from "./pages/OPUS Web/OPUSWeb";
import OPUSMobile from "./pages/OPUS Mobile/OPUSMobile";
import Fonctionnalites from "./pages/Fonctionnalites/Fonctionnalites";
import ConditionsGeneralesUtilisation from "./pages/Legal/ConditionsGeneralesUtilisation";
import PolitiqueDeConfidentialite from "./pages/Legal/PolitiqueDeConfidentialite";
import ConditionsGeneralesVente from "./pages/Legal/ConditionsGeneralesVente";
import OpusReasearch from "./pages/OPUS Research/OpusReasearch";
import OpusPatrimoine from "./pages/OPUS Patrimoine/OpusPatrimoine";
import Entreprise from "./pages/Entreprise/Entreprise";
import Emplois from "./pages/Emplois/Emplois";
import OPUSPro from "./pages/Professionnel/OPUSPro";
import OPUSClient from "./pages/Client/OPUSClient";
import AProposDeNous from "./pages/A propos de nous/AProposDeNous";
import Stories from "./pages/Stories/Stories";
import StoryPage from "./pages/Stories/StoryPage";

const App = () => {

  return (
            <Router>
              <ScrollToTop/>
              <Switch>

                {/* Site web final */}

                <Route path="/" exact component={Accueil} />
                <Route path="/contact" exact component={Contact}/>
                <Route path="/opus-mobile" exact component={OPUSMobile} />
                <Route path="/opus-web" exact component={OPUSWeb} />
                <Route path="/fonctionnalites" exact component={Fonctionnalites} />
                <Route path="/condtions-generales-utilisation" exact component={ConditionsGeneralesUtilisation} />
                <Route path="/condtions-generales-vente" exact component={ConditionsGeneralesVente} />
                <Route path="/politique-confidentialite" exact component={PolitiqueDeConfidentialite} />
                <Route path="/opus-research" exact component={OpusReasearch} />
                <Route path="/opus-patrimoine" exact component={OpusPatrimoine} />
                <Route path="/entreprise" exact component={Entreprise} />
                <Route path="/emplois" exact component={Emplois} />
                <Route path="/opus-professionnel" exact component={OPUSPro} />
                <Route path="/opus-client" exact component={OPUSClient} />
                <Route path="/tarifs" exact component={Tarifs} />
                <Route path="/a-propos-de-nous" exact component={AProposDeNous} />
                <Route path="/stories" exact component={Stories} />
                <Route path="/story-page" exact component={StoryPage} />

              </Switch>
            </Router>
  );
};

export default App;
