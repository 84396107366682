import React from 'react';
import leftBlanc from "../Assets/SVGS/leftBlanc.svg";
import {useHistory} from "react-router-dom";

export default function ImageTextComposantLeft({img, button, title, subtitle, corps, setModalDemarrerIntegration, buttonName, width, linkButton, patrimoine}) {

    let history = useHistory();

    return(
        <div className={'imageTextComposant'} style={{flexDirection: width > 990 ? 'row' : 'column', marginBottom: width < 600 ? -50 : 50, alignItems: 'center'}}>
            {width > 990 ?
                <div className={'textPart'} style={{marginRight: width < 600 ? 0 : 100}}>
                    <p className={'titleTextPart'}>{title}</p>
                    <p className={'secondTitle'}>{subtitle}</p>
                    <p className={'corps'} style={{textAlign: 'justify'}}>{corps}</p>

                    {button &&
                        <div style={{display: 'flex', marginTop: 30, marginBottom: 45}}>
                            <a href={linkButton}>
                                <div
                                    className={'blueButton'}
                                    style={{color: '#fff', paddingRight: 14}}
                                    onClick={() => history.push("/opus-professionnel")}
                                >
                                    {buttonName}
                                    <img src={leftBlanc} alt="" className={'leftBleu'}/>
                                </div>
                            </a>
                        </div>
                    }
                </div>
                :

                    <img
                        src={img}
                        alt=""
                        className={'image'}
                        style={{
                            width: width > 740 ? 'calc(100vw - 180px)' : 500,
                            height: patrimoine ? width > 740 ? 'calc(100vw - 180px)' : 500 : null,
                            margin: 0,
                            objectFit: width < 600 ? 'cover' : 'cover',
                        }}
                    />

            }

            {width > 990 ?
                <div
                    className={'imagePart'}
                    style={{
                        width: width < 600 ? 'calc(100vw - 50px)' : null,

                    }}
                >
                    <img
                        src={img}
                        alt=""
                        className={'image'}
                        style={{
                            width: width < 600 ? 'calc(100vw - 50px)' : null,
                            objectFit: width < 600 ? 'cover' : 'contain',
                        }}
                    />
                </div>
                :
                <div className={'phoneTextPart'} style={{marginRight: 0, marginTop: 70, marginLeft: 0, width: '85vw', justifyContent: 'center'}}>
                    <p className={'titleTextPart'}>{title}</p>
                    <p className={'secondTitle'}>{subtitle}</p>
                    <p className={'corps'}>{corps}</p>


                </div>

            }


        </div>
    )
}
