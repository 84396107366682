import React, {useEffect, useState} from 'react';
import videoOpus from "../../Assets/Images/Home Page/videoOpus.mp4";
import flecheBlanche from "../../Assets/Images/Home Page/flecheBlanche.svg";
import {useTranslation} from "react-i18next";

export default function VideoOPUS() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(

        <div className={'videoOpus'}>

            <div className={'filtreNoir'} style={{zIndex: 2}}>
                <div style={{height: 600, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <p className={'title'}>{t('translation.chooseOpusForYourProject')}</p>
                    <div style={{display: 'flex', marginTop: width < 900 ? 30 : null}}>
                        <div className={'boutonDecouvrir'}>
                            {t('translation.addYourProjectNow')}
                            <img src={flecheBlanche} alt="" style={{width: 9, marginLeft: 8,}}/>
                        </div>
                    </div>
                </div>
            </div>

            {width < 900 ?

                <div style={{
                    zIndex: 1,
                    height: 600,
                    width: '100vw',
                    overflow: 'hidden',
                    marginTop: 2,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <video autoPlay loop muted
                           style={{
                               height: 600,
                           }}
                    >
                        <source src={videoOpus} type={'video/mp4'}/>
                    </video>
                </div>

                :

                <div style={{
                    zIndex: 1,
                    height: 600,
                    width: '100vw',
                    overflow: 'hidden',
                    marginTop: 2,
                }}>
                    <video autoPlay loop muted
                           style={{
                               width: '100vw',
                           }}
                    >
                        <source src={videoOpus} type={'video/mp4'}/>
                    </video>
                </div>


            }

        </div>
    )
}
