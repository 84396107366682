import React from 'react';
import ItemPlanChoice from './ItemPlanChoice';
import {useTranslation} from "react-i18next";
import {getPlansList} from "./OptionsList"; // Assurez-vous que le chemin est correct

export default function PlanChoices() {

	const { t } = useTranslation();
	const plansList = getPlansList(t);

	return (
		<div className={'planChoices'}>
			{plansList.map((plan, index) => {
				return <ItemPlanChoice key={index} plan={plan} index={index} />;
			})}
		</div>
	);
}
