import React, {useState, useEffect} from 'react';
import flecheBleuLongue from '../../Assets/Images/Home Page/flecheBleuLongue.svg'
import unArchitecte from '../../Assets/Images/Home Page/unArchitecte.png'
import deuxPartenaires from '../../Assets/Images/Home Page/deuxPartenaires.png'
import transmettezInformation from '../../Assets/Images/Home Page/transmettezInformation.png'
import {useTranslation} from "react-i18next";

export default function TransmettreLInformation() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }



    return(
        <div className={'transmettreInformation'} style={{paddingTop: width > 740 ? 200 : 100, paddingBottom: 160, marginTop: 0}}>

            <div style={{display: 'flex', flexDirection: 'column', width: '100vw', justifyContent: 'center', alignItems: 'center', marginTop: width < 1300 ? 20 : -60}}>

                <p style={{
                    fontSize: 21,
                    fontWeight: '700',
                    color: '#5f74fc',
                    marginBottom: width < 1300 ? 30 : 10,
                }}>{t('translation.withOpusEasily')}</p>

                <p className={width > 740 ? 'titleBlack' : 'littleTitleBlack'} style={{
                        fontSize: width > 740 ? 55 : 50,
                        textAlign: 'center',
                        marginTop: 0,
                    }}>
                        <span style={{fontSize: width > 740 ? 55 : 40,}}>{t('translation.transmitInformation')}</span>
                        <br/>
                        <span style={{color: '#5c70f2'}}>{t('translation.onceForAllStakeholder')}</span>
                        <br/>
                    <span style={{color: 'rgba(92,112,242,0.5)'}}>{t('translation.onceforAllTime')}</span>
                </p>

                <div style={{display: 'flex', flexDirection: width < 1300 ? 'column' : 'row', alignItems: 'center'}}>

                    <div style={{marginTop: width < 1300 ? 0 : 70, transform:  width < 1300 ? 'scale(1.3)' : null}}>
                        <img src={unArchitecte} alt="" style={{width: 200, marginTop: 80, objectFit: 'contain'}}/>
                        <div style={{display: 'flex'}}>
                            <div style={{
                                marginLeft: -40,
                                marginTop: 11,
                                marginRight: 15,
                                width: 27,
                                height: 27,
                                backgroundColor: '#5f74fc',
                                borderRadius: 30,
                                color: '#FFF',
                                fontWeight: 700,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                1
                            </div>
                            <p style={{
                                fontWeight: '700'
                            }}>
                                <span style={{color: '#5f74fc'}}>{t('translation.theArchitectDecorator')}</span>
                                <br/>
                                {t('translation.transmitTheInformation')}
                                <br/>
                                {t('translation.once')}
                            </p>
                        </div>
                    </div>

                    {width > 1300 ?

                        <div style={{width: 200}}/>
                        :

                        <div style={{transform: 'rotate(275deg)', marginLeft: 100, marginTop: 120, marginBottom: -120}}>
                            <img
                                style={{
                                    marginTop: 100,
                                    transform: 'scaleX(-1)',
                                    position: "absolute",
                                }}
                                src={flecheBleuLongue}
                                alt=""
                            />
                        </div>



                    }


                    <img src={transmettezInformation} alt="" style={{width: 350, marginTop: width < 1300 ? 130 : 80,}}/>

                    {width > 1300 ?

                        <div style={{width: 200}}/>
                        :

                        <div style={{transform: 'rotate(275deg)', marginRight: 600, marginTop: 200, marginBottom: -120}}>
                            <img
                                style={{
                                    marginTop: 100,
                                    transform: 'scaleY(-1)',
                                    position: "absolute",
                                }}
                                src={flecheBleuLongue}
                                alt=""
                            />
                        </div>



                    }

                    <div style={{marginTop: width < 1300 ? 30 : 70, transform:  width < 1300 ? 'scale(1.3)' : null}}>
                        <img src={deuxPartenaires} alt="" style={{width: 200, marginTop: 80, objectFit: 'contain'}}/>
                        <div style={{display: 'flex'}}>
                            <div style={{
                                marginLeft: -40,
                                marginTop: 11,
                                marginRight: 15,
                                width: 27,
                                height: 27,
                                backgroundColor: '#5f74fc',
                                borderRadius: 30,
                                color: '#FFF',
                                fontWeight: 700,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                2
                            </div>
                            <p style={{
                                fontWeight: '700'
                            }}>
                                <span style={{color: '#5f74fc'}}> {t('translation.clientAndStakeholders')}</span>
                                <br/>
                                {t('translation.accessTheInformation')}
                                <br/>
                                {t('translation.wheneverTheyWant')}
                            </p>
                        </div>
                    </div>


                </div>

                {width > 1300 &&

                    <div style={{display: 'flex', marginTop: -30,}}>
                        <img src={flecheBleuLongue} alt=""/>
                        <div style={{width: 450}}/>
                        <img src={flecheBleuLongue} alt="" style={{transform: 'scaleX(-1)'}}/>
                    </div>
                }

            </div>
        </div>
    )
}
