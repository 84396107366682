import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import ContentAProposDeNous from "./ContentAProposDeNous";
import NosFondateurs from "../OPUS Patrimoine/NosFondateurs";
import Footer from "../../components/Footer";

export default function AProposDeNous() {

    const [overflow, setOverflow] = useState(true)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'}>

            <div style={{zIndex: 10}}>
                <Header setOverflow={setOverflow} blue={true}/>
            </div>

            <ContentAProposDeNous width={width}/>

            <NosFondateurs width={width}/>

            <Footer/>
        </div>
    )
}
