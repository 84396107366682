import React, {useContext, useEffect, useState} from 'react'
import Header from "../../components/Header/Header";
import MiddlePhoto from "../../components/MiddlePhoto";
import FirstOpusWeb from "../../Assets/Images/FirstOpusWeb.png";
import Defilant from "../../components/Defilant";
import defilant1 from "../../Assets/Images/OPUS Web/defilant1.png";
import petitDefilant2 from "../../Assets/Images/OPUS Web/petitDefilant2.jpeg";
import petitDefilant1 from "../../Assets/Images/OPUS Web/petitDefilant1.jpeg";
import petitDefilant3 from "../../Assets/Images/OPUS Web/petitDefilant3.png";
import defilant2 from "../../Assets/Images/OPUS Web/defilant2.png";
import defilant3 from "../../Assets/Images/OPUS Web/defilant3.png";
import defilant4 from "../../Assets/Images/Opus Mobile/defilant4.png";
import ImageTextComposantRight from "../../components/ImageTextComposantRight";
import ImageTextComposantLeft from "../../components/ImageTextComposantLeft";
import bureau from "../../Assets/Images/bureau.png";
import LargePhotoTexts from "../../components/LargePhotoTexts";
import OPUSWeb1 from "../../Assets/Images/OPUS Web/OPUSWeb1.png";
import OPUSWeb2 from "../../Assets/Images/OPUS Web/OPUSWeb2.png";
import OPUSWeb3 from "../../Assets/Images/OPUS Web/OPUSWeb3.png";
import PretAVousLancer from "../Home Page/PretAVousLancer";
import Footer from "../../components/Footer";
import {useTranslation} from "react-i18next";

export default function OPUSWeb() {
    const { t } = useTranslation();

    const [menuGrand, setMenuGrand] = useState(false)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [overflow, setOverflow] = useState(true)

    return (
        <div className={overflow ? 'page' : 'pageMenu'} style={{overflowY: overflow ? 'scroll' : 'hidden'}}>

            <div style={{zIndex: 10}}>
                <Header setMenuGrand={setMenuGrand} menuGrand={menuGrand} setOverflow={setOverflow}/>
            </div>

            <div className={'opusMobile'} style={{zIndex: 1}}>
                <p className={'littleTitle'} style={{marginTop: width > 740 ? null : 50,}}>OPUS WEB</p>
                <p className={'bigTitle'}>{t('translation.yourAgencyAndAllItsProjectsCentralised')}</p>

                <MiddlePhoto src={FirstOpusWeb} width={width}/>

                <p className={'littleTitle'} style={{fontSize: 16, marginTop: 130}}>{t('translation.daily')}</p>
                <p className={'bigTitle'} style={{fontSize: 32}}>
                    {t('translation.title1OpusWeb')}
                </p>

                <p className={'corps'}>
                    {t('translation.body1OpusWeb')}
                </p>

                <Defilant
                    title1={t('translation.titleOpusWeb1')}
                    title2={t('translation.titleOpusWeb2')}
                    title3={t('translation.titleOpusWeb3')}
                    title4={t('translation.titleOpusWeb4')}
                    subtitle1={t('translation.subTitleOpusWeb1')}
                    subtitle2={t('translation.subTitleOpusWeb2')}
                    subtitle3={t('translation.subTitleOpusWeb3')}
                    subtitle4={t('translation.subTitleOpusWeb4')}
                    img1={width > 740 ? defilant1 : petitDefilant1}
                    img2={width > 740 ? defilant2 : petitDefilant2}
                    img3={width > 740 ? defilant3 : petitDefilant3}
                    img4={defilant4}
                    width={width}
                />

                <div style={{zIndex: 2}}>

                    <ImageTextComposantLeft width={width} img={OPUSWeb1}
                                            title={t('translation.title2OpusWeb1')}
                                            subtitle={t('translation.subTitle2OpusWeb1')}
                                            corps={t('translation.body2OpusWeb1')}/>
                    <ImageTextComposantRight setOverflow={setOverflow} setMenuGrand={setMenuGrand} button={true} buttonName={t('translation.button2OpusWeb2')} width={width}
                                             img={OPUSWeb2} title={t('translation.title2OpusWeb2')}
                                             subtitle={t('translation.subTitle2OpusWeb2')}
                                             corps={t('translation.body2OpusWeb2')}/>
                    <ImageTextComposantLeft button={true} buttonName={t('translation.button2OpusWeb3')} width={width}
                                            img={OPUSWeb3} title={t('translation.title2OpusWeb3')}
                                            subtitle={t('translation.subTitle2OpusWeb3')}
                                            linkButton={'http://localhost:3001/opus-professionnel'}
                                            corps={t('translation.body2OpusWeb3')}/>
                </div>

                <div style={{zIndex: 3}}>
                    <LargePhotoTexts
                        width={width}
                        title={t('translation.title3OpusWeb1')}
                        subtitle={t('translation.subTitle3OpusWeb1')}
                        photo={bureau}
                        littleTitle1={t('translation.littleTitle1OpusWeb')}
                        littleTitle2={t('translation.littleTitle2OpusWeb')}
                        littleTitle3={t('translation.littleTitle3OpusWeb')}
                        corps1={t('translation.body3OpusWeb1')}
                        corps2={t('translation.body3OpusWeb2')}
                        corps3={t('translation.body3OpusWeb3')}
                    />
                </div>

                <div style={{zIndex: 4}}>
                    <PretAVousLancer/>
                </div>

                <div style={{zIndex: 5}}>
                    <Footer/>
                </div>

            </div>

        </div>

    )
}
