import React, {useState, useEffect} from 'react';
import planSendImage from '../../Assets/Images/Home Page/planSendImage.png'
import uneFois from '../../Assets/Images/Home Page/uneFois.svg'
import cinqFois from '../../Assets/Images/Home Page/cinqFois.svg'
import huitFois from '../../Assets/Images/Home Page/huitFois.svg'
import unusefulComFr from '../../Assets/Images/Home Page/unusefulComFr.svg'
import stepsForIntegration from '../../Assets/Images/Home Page/stepsForIntegration.svg'
import freeProjectSimplissime from '../../Assets/Images/Home Page/freeProjectSimplissime.svg'
import littleFreeProjectSimplissime from '../../Assets/Images/Home Page/littleFreeProjectSimplissime.svg'
import checkSimplissime from '../../Assets/Images/Home Page/checkSimplissime.svg'
import buttonAddProject from '../../Assets/Images/Home Page/buttonAddProject.png'
import {useTranslation} from "react-i18next";

export default function Simplissime() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    function ItemCheck({nom}) {
        return(
            <div className={'itemChoice'} style={{transform:  width < 1300 ? 'scale(1.3)' : null}}>
                <img src={checkSimplissime} alt="" className={'checkSimplissime'}/>
                <p className={'nomItemChoice'}>{nom}</p>
            </div>
        )
    }


    return(
        <div className={'simplissime'} style={{paddingTop: width > 740 ? 70 : 50, paddingBottom: 160, marginTop: 0}}>

            <div style={{display: 'flex', flexDirection: 'column', width: '100vw', justifyContent: 'center', alignItems: 'center', marginTop: -60}}>

                <div className={'textPart'} style={{
                    display: 'flex',
                    flexDirection: width > 740 ? 'row' : 'column',

                }}>

                    <div style={{display: 'flex', flexDirection: 'column', width: 700}}>
                        <p style={{
                            fontSize: 21,
                            fontWeight: '700',
                            color: '#5f74fc',
                            marginBottom: 10,

                        }}>{t('translation.startWithOpus')}</p>

                        <p className={width > 740 ? 'titleBlack' : 'littleTitleBlack'} style={{
                            fontSize: width > 1300 ? 55 : 50,
                            marginTop: 20,
                            zIndex: 2
                        }}>
                            {t('translation.easily')}
                        </p>

                        <div className={'degrade'} style={{zIndex: 1}}/>
                        {width > 1300 &&
                            <p className={'corps'} style={{
                                lineHeight: 1.5,
                                fontSize: 17,
                            }}>
                                {t('translation.textHomePage6')} <br/>
                                {t('translation.textHomePage7')}
                            </p>
                        }


                        {width > 1300 &&
                            <a href="https://opusoft.app/OnBoarding" target="_blank">
                                <div className={'callToAction'}>
                                    <img
                                        src={buttonAddProject}
                                        alt=""
                                        className={'buttonAddProject'}
                                    />

                                    <img
                                        src={freeProjectSimplissime}
                                        alt=""
                                        className={'freeProjectSimplissime'}
                                    />
                                </div>
                            </a>


                        }

                    </div>

                    <img src={width < 1300 ? littleFreeProjectSimplissime : stepsForIntegration} alt="" className={'stepsForIntegration'} style={{
                        marginLeft: width < 1300 ? 0 : -150,
                        marginTop: width < 1300 ? 50 : -65,
                    }}/>


                </div>

                {/*
  <div style={{
                    width: '100vw',
                    height: 300,
                }}>
                    <BackgroundVideoPlayer src={videoOpus} style={{ width: '100%', height: '800px'}} />

                </div>
                */}


            </div>

            {width > 1300 &&
            <div style={{display: 'flex', gap: 30, marginTop: 100, flexDirection: width < 1300 ? 'column' : 'row'}}>

                <ItemCheck nom={t('translation.textHomePage8')}/>
                <ItemCheck nom={t('translation.textHomePage9')}/>
                <ItemCheck nom={t('translation.textHomePage10')}/>
                <ItemCheck nom={t('translation.textHomePage11')}/>
            </div>

            }

        </div>
    )
}
