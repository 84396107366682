import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import leftBleu from "../../Assets/SVGS/leftBleu.svg"
import julesBleu from "../../Assets/Images/julesBleu.png"
import Footer from "../../components/Footer";
export default function Stories() {

    const [menuGrand, setMenuGrand] = useState(false)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [overflow, setOverflow] = useState(true)

    const [freeMeeting, setFreeMeeting] = useState(false)

    function ArticleLink({tag, title, description}) {
        return(
            <div className={'articleLink'}>
                <div className={'img'}/>
                <div className={'textPart'}>
                    <p className={'tagArticle'}>{tag}</p>
                    <p className={'titleArticle'}>{title}</p>
                    <p className={'descriptionArticle'}>{description}</p>
                    <div className={'discover'}>
                        Découvrir
                        <img src={leftBleu} alt="" className={'leftBleu'} color={'#6671EB'}/>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'} style={{overflowY: overflow ? 'scroll' : 'hidden'}}>

            <div style={{zIndex: 10}}>
                <Header setMenuGrand={setMenuGrand} menuGrand={menuGrand} setOverflow={setOverflow}/>
            </div>

            <div className={'storiesPage'}>
                <p className={'opus'}>OPUS,</p>
                <p className={'title'}>
                    <span style={{color: '#000'}}>And its thousands</span> <br/>
                    stories to tell.
                </p>

                <p className={'opus'}>Discover some</p>
                <p className={'title'}>
                    <span style={{color: '#000'}}>insightful stories we</span> <br/>
                    wanted to share with you.
                </p>

                <ArticleLink
                    tag={'Our User’s favorite'}
                    title={'Let’s make communication headaches a thing from the past ! 🚀'}
                    description={'Ne laissez pas la communication empiéter sur le coeur de votre métier, retrouvez un nouvel élan créatif au service de vos clients et de leur  '}
                />

                <ArticleLink
                    tag={'Our User’s favorite'}
                    title={'Let’s make communication headaches a thing from the past ! 🚀'}
                    description={'Ne laissez pas la communication empiéter sur le coeur de votre métier, retrouvez un nouvel élan créatif au service de vos clients et de leur  '}
                />

                <ArticleLink
                    tag={'Our User’s favorite'}
                    title={'Let’s make communication headaches a thing from the past ! 🚀'}
                    description={'Ne laissez pas la communication empiéter sur le coeur de votre métier, retrouvez un nouvel élan créatif au service de vos clients et de leur  '}
                />

                <p className={'opus'} style={{marginTop: 100}}>Who knows ?</p>
                <p className={'title'}>
                    <span style={{color: '#000'}}>The next story</span> <br/>
                    will be yours to tell.
                </p>

                <div className={'bookAFreeMeeting'} style={{height: freeMeeting ? 1400 : 400}}>
                    <div style={{display: 'flex', marginTop: 30, marginBottom: 60, alignItems: 'center'}}>
                        <img src={julesBleu} alt="" className={'profilPicture'}/>
                        <div style={{marginLeft: 40, marginRight: 40}}>
                            <p className={'titleMeeting'}>
                                Book a free meeting with one of our
                                Co-founder to get to know more
                            </p>
                            <p className={'descriptionMeeting'}>
                                Ne laissez pas la communication empiéter sur le coeur de votre métier,
                                retrouvez un nouvel élan créatif au service de vos clients et de leur
                            </p>
                            <div className={'discover'} onClick={() => setFreeMeeting(true)}>
                                Book a free meeting
                                <img src={leftBleu} alt="" className={'leftBleu'} color={'#6671EB'}/>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        backgroundColor: '#eeeeee',
                        width: 800,
                        height: 900,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 20,
                    }}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={'https://calendly.com/jules-andrieux/let-s-discuss-together'}
                            frameBorder="0"
                            allowFullScreen
                            allow="xr-spatial-tracking"
                        />

                    </div>
                </div>

            </div>

            <div style={{zIndex: 5}}>
                <Footer/>
            </div>

        </div>
    )
}
