import React from 'react';
import ImageTextComposantRight from "../../components/ImageTextComposantRight";
import imageAPropos from "../../Assets/imageAPropos.png";
import firstProject from "../../Assets/firstProject.png";
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import {useTranslation} from "react-i18next";

export default function ContentAProposDeNous({width}) {
    const { t } = useTranslation();

    return(
        <div className={'aPropoDeNous'}>

            <div style={{display: 'flex', flexDirection: width > 990 ? 'row' : 'column', alignItems: width > 990 ? null : 'center'}} className={'headerAPropos'}>

                    <div className={'textPart'} style={{width: width > 990 ? null : '90vw', alignItems: width > 990 ? 'flex-start' : 'center', display: 'flex', flexDirection: 'column'}}>
                        <p className={'littleTitle'} style={{marginTop: width > 990 ? null : 0}}>{t('translation.aboutUs')}</p>

                        <p className={'bigTitle'}  style={{width: width > 990 ? null : '90vw', textAlign: width > 990 ? null : 'center'}}>{t('translation.text1AboutUs')}</p>

                        <p className={'corpsPage'} style={{marginBottom: width > 740 ? 80 : 100, textAlign: width > 990 ? null : 'center'}}>
                            {t('translation.subTitleAboutUs')}
                        </p>

                        <a href="https://opusoft.app/OnBoarding" target="_blank">
                            <div className={width > 1200 ? 'blueButton' : 'littleBlueButton'} style={{
                                color: '#fff',
                                marginTop: -40
                            }}>
                                {t('translation.startNow')}
                                <img src={leftBlanc} alt="" className={'leftBleu'}/>
                            </div>
                        </a>

                    </div>

                    <div className={'imagePart'}  style={{width: width > 990 ? 'calc(50vw - 150px)' : '90vw'}}>
                        <img src={imageAPropos} alt="" className={'imageAPropos'}  style={{width: width > 990 ? null : '90vw', marginTop: width > 990 ? null : 40}}/>
                    </div>

            </div>


            <ImageTextComposantRight apropos={true} width={width} img={firstProject} title={t('translation.titleFirstPartAboutUs')} subtitle={t('translation.subTitleFirstPartAboutUs')} corps={t('translation.bodyFirstPartAboutUs')}/>

            <div className={'notreMission'}>
                <p className={'littleTitle'}>{t('translation.subTitleSecondPartAboutUS')}</p>
                <p className={'title'} style={{maxWidth: '90vw'}}>{t('translation.titleSecondPartAboutUS')}</p>
                <p className={'corpsPage'}>{t('translation.bodySecondPartAboutUs')}</p>
            </div>

        </div>
    )
}
