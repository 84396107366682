import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import coverArticle from "../../Assets/Images/coverArticle.png"
import clock from "../../Assets/Images/clock.png"
import julesBleu from "../../Assets/Images/julesBleu.png";
import leftBleu from "../../Assets/SVGS/leftBleu.svg";

export default function StoryPage() {


    const [menuGrand, setMenuGrand] = useState(false)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [overflow, setOverflow] = useState(true)

    const [freeMeeting, setFreeMeeting] = useState(false)


    function ArticlePart({title, corps, img}) {

        return(
            <div className={'articlePart'}>
                <p className={'titleArticlePart'}>{title}</p>
                <p className={'corpsArticlePart'}>{corps}</p>
            </div>
        )
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'} style={{overflowY: overflow ? 'scroll' : 'hidden'}}>

            <div style={{zIndex: 10}}>
                <Header setMenuGrand={setMenuGrand} menuGrand={menuGrand} setOverflow={setOverflow}/>
            </div>

            <div className={'storyPage'}>
                <div className={'firstTextPart'}>
                    <p className={'blueTitle'}>We made this masterclass just for you !</p>
                    <p className={'titleArticle'}>Let’s make communication headaches a thing from the past ! 🚀</p>
                    <p className={'firstDescription'}>
                        If you’re an Architect or you just followed a project you know the complexity of communicating and keeping every stakeholder updated with the
                        project’s latest advancement. The clients asking you to resend them the documents they’ve misplaced, partner agencies bombarding you with
                        The same request and your team struggling to locate the blueprints you added to the server : What a nightmare 😓 Well, this is over ….
                    </p>
                    <div className={'temps'}>
                        <img src={clock} alt="" className={'clock'}/>
                        8 mins
                    </div>
                </div>
                <img src={coverArticle} alt="" className={'coverArticle'}/>

                <ArticlePart
                    title={'Let’s make communication headaches a thing from the past ! 🚀'}
                    corps={
                        `Hey there, architects! Welcome to the exciting episode of “Mastering Project Communication.” I’m Jules Andrieux, Co-founder of OPUS, and for the past year, we’ve had the pleasure of working with hundreds of architects, including AD100, to simplify the monitoring of their projects. Today, I’m going to share with you the top tricks I’ve learned to end headaches in your project’s communication.
                        
                        We all know how crucial it is to keep all stakeholders up to date and ensure smooth collaboration. So, without further ado, let’s start with the first trick.`
                    }
                />

                <ArticlePart
                    title={'Trick 1: Centralising your communication by using the fewest channels possible. ✅'}
                    corps={
                        `As architects, it’s essential to establish clear communication channels with your team and project stakeholder. One common headache I’ve witness working with hundred of architects is dealing with multiple communication channels filled with countless chats and messages. I’m sure you’ve experienced the frustration of not being able to find important information either sent by you client or partners agencies. Only because it was scattered across different platforms like SMS, email, WhatsApp, Messenger, and more. 
                        
                        My advice to you is to choose the communication channel that works best for you and stick to it! For example, set up a dedicated project email address where everyone involved can send and receive project-related messages. This helps consolidate communication in one place, making it easier to track and retrieve information. Additionally, consider using platforms like WhatsApp to communicate with your clients. This allows them to easily contact you and access documents related to their project. 
                        
                        If you want to take it a step further, explore project management software that allows real-time collaboration and messaging features. By centralising your communication channels, you’ll avoid the confusion of scattered conversations and ensure that important information reaches everyone involved. 
                        
                        Remember, accessibility is key, which brings us to our next trick.`
                    }
                />

                <ArticlePart
                    title={'Trick 2 : Make the information, easy to access ! 📱'}
                    corps={
                        `Another common headache I’ve witness, is not being able to find the information of the project easily  when you need it. So what do most do ? They either call the partner agency or the team to ask them to re-send the document they already sent. Our study is clear 66 % of information sent have to be re-send because lost, or not accessible at the time needed.
                    
                        So, In order to streamline communication, it’s crucial to make project information easily accessible.  When stakeholders can quickly find the information they need, they won’t need to repeatedly ask for updates or clarification, saving everyone’s time and effort and mostly yours.
                    
                        To achieve this, consider organising project documents and files in a logical and structured manner. Utilise cloud storage solutions or project management software that allow you to categorise and label documents clearly. Ensure that everyone involved has access to the centralised platform, so they can retrieve the information they need whenever they need it.
                    
                        By making project information easily accessible, you eliminate the need for repeated communication and prevent misunderstandings. This simple trick can save you countless headaches throughout the project.`
                    }
                />

                <ArticlePart
                    title={'Trick 3 : Stay Organised throughout your projects 🤝'}
                    corps={
                        `Now that you’ve centralised your communication and made the information easily accessible to all stakeholders, it’s time to tackle another common headache: the lack of consistency in organisational systems. When each team member uses their own organisation system, it can quickly become chaotic, especially when you’re juggling dozens of projects. Finding a document quickly becomes a nightmare !
                    
                        To stay organised throughout your projects, establish a standardised system that everyone follows. This  includes a template for each project where everyone puts the information at the same place, naming conventions for files and folders, clear project tracking methods, and consistent documentation practices. 
                    
                        By implementing a cohesive organisational system, you’ll ensure that everyone is on the same page and can easily find the information they need. 
                    
                        Keeping all project information organised is essential for effective communication. When information is made easily accessible and organised, each stakeholders know where to find it when needed, everything is made retrievable, saving a huge time for everyone.`
                    }
                />

                <ArticlePart
                    title={'Trick 4 : Just send the information your clients need 😮'}
                    corps={
                        `Now, I know this one might sound controversial, but hear me out. Many architects believe that sending more information to their clients leads to better project monitoring. However, that’s not always the case. In reality, most clients simply want to be updated on the essential milestones of their project and be able to visualise its progress.
                    
                        Sending an overwhelming amount of information to your clients not only confuses them with an influx of irrelevant details but also wastes your precious time and resources. When it comes to what you send to your clients, remember: Quality and Consistency over Quantity. 
                    
                        To achieve this, here’s what top architects I’ve worked with do: Some of they send weekly or monthly project advancement reports. These reports highlight the key milestones reached, provide visual progress updates, and focus on the most relevant information for the clients. This approach ensures that clients stay informed without being overwhelmed by unnecessary details.
                    
                        However the best to achieve this are the architects using project monitoring platforms that enable them to transmit just the right amount of information directly to the client. These platforms allow for customised reporting, where architects can curate and present the essential updates tailored to the clients’ preferences, without spending time creating and editing those reports.
                    
                        By focusing on sending only the information your clients need, you streamline communication, enhance their understanding of the project, and save valuable time for both you and your team.
                    
                        Remember, it’s not about bombarding your clients with information; it’s about providing them with the quality and consistency they require.`
                    }
                />

                <ArticlePart
                    title={'Golden Nugget : Using project monitoring platform 🌕'}
                    corps={
                        `You might have overheard me talking about project monitoring platform as the trick Top-Architects use for an amazing and efficient project follow-up. But you might be wondering what are those, and do I really need one ? 
                    
                        So to cut things short, just imagine, having an app centralising all your project information. Being able to access the photos, files, progression, finances, all in one click. Being able to share the newly created information once, for all stakeholder. 
                    
                        Enabling your team and clients to access all the data of the project as well on worksite, than abroad, or remotely. Imagine it like the perfect formula against headaches in your project’s communication. This formula is called OPUS and it was made just for you, so try it out.
                    
                        And there you have it, my top tricks to end headaches in your project’s communication. Remember, effective communication is the key to successful project management.`
                    }
                    blue={true}
                />

                <div className={'bookAFreeMeeting'} style={{height: freeMeeting ? 1400 : 400}}>
                    <div style={{display: 'flex', marginTop: 30, marginBottom: 60, alignItems: 'center'}}>
                        <img src={julesBleu} alt="" className={'profilPicture'}/>
                        <div style={{marginLeft: 40, marginRight: 40}}>
                            <p style={{fontSize: 14, marginTop: 0}}>Jules Andrieux, OPUS COO</p>
                            <p className={'titleMeeting'} style={{color: '#5F74FB'}}>
                                Thanks you for Reading 🌟
                            </p>
                            <p className={'descriptionMeeting'}>
                                I hope you found these tricks valuable, and I encourage
                                you to explore more deeply each one of them. If you liked
                                this video, don’t hesitate to book a call with me so we
                                can discuss further about your situation, and how we can
                                implement these tricks easily directly in your Agency.
                                <br/><br/>
                                In the meantime, Stay Amazing at what you do !
                            </p>
                            <div className={'discover'} onClick={() => setFreeMeeting(true)}>
                                Book a free meeting
                                <img src={leftBleu} alt="" className={'leftBleu'} color={'#6671EB'}/>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        backgroundColor: '#eeeeee',
                        width: 800,
                        height: 900,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 20,
                    }}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={'https://calendly.com/jules-andrieux/let-s-discuss-together'}
                            frameBorder="0"
                            allowFullScreen
                            allow="xr-spatial-tracking"
                        />

                    </div>
                </div>

            </div>

            <div style={{zIndex: 5}}>
                <Footer/>
            </div>

        </div>
    )
}
