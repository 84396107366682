import React, {useState, useEffect} from 'react';
import Header from "../../components/Header/Header";
import Modal from "react-modal";
import Footer from "../../components/Footer";
import crossIcon from "../../Assets/Images/crossIcon.png";
import iphonesStores from "../../Assets/Images/iphonesStores.png";
import prosPhone from "../../Assets/Images/prosPhone.png";
import opusClient1 from "../../Assets/Images/opusClient1.png";
import opusClient2 from "../../Assets/Images/opusClient2.png";
import opusClient3 from "../../Assets/Images/opusClient3.png";
import opusClient4 from "../../Assets/Images/opusClient4.png";
import leftBleuRandom from "../../Assets/SVGS/leftBleuRandom.svg";
import BesoinsRenseignements from "../Home Page/BesoinsRenseignements";
import {useTranslation} from "react-i18next";

export default function OPUSClient() {
    const { t } = useTranslation();

    const [overflow, setOverflow] = useState(true)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [modalQrCode, setModalQrCode] = useState(false)

    function ItemEtape({title, subtitle, img, etape, button, buttonName}) {

        return(
            <div className={'itemEtape'} style={{flexDirection: width > 1200 ? 'row' : 'column', marginTop:  width > 740 ? 150 : 50}}>
                <div className={'textPart'} >
                    <p className={'etapeCount'} style={{fontSize:  width > 740 ? null : 16}}>{t('translation.step')} {etape}</p>
                    <p className={'titleEtape'}>{title}</p>
                    <p className={'subtitleEtape'} style={{marginBottom: width > 1200 ? null : button ? 10 : 40}}>{subtitle}</p>

                    {buttonName === t('translation.downloadOpus') &&

                        <a href={width > 740 ? null : "https://apps.apple.com/fr/app/opus-architecture-design/id1591807716"}>
                            <div style={{display: 'flex', marginBottom: 20,}} onClick={() => {
                                if (width > 740) {
                                    setModalQrCode(true)
                                }

                            }}>
                                <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{width: 200, paddingRight: 7, backgroundColor: '#fff', height: 40, alignItems: 'center', paddingLeft: 0, marginTop: 0}}>
                                    {buttonName}
                                    <img src={leftBleuRandom} alt="" className={'leftBleu'} style={{height: 14, width: 20, objectFit: 'contain', marginLeft: 4, marginRight: 0, marginTop: 0}}/>
                                </div>
                            </div>
                        </a>



                    }

                    {buttonName === t('translation.sendAnEmail') &&

                        <a href="mailto:?subject=Notre nouvel outil de suivi de projets&body=Cher architecte, décorateur, maître d'oeuvre. J'ai fait la découverte d'une application de gestion et suivi de projet que je souhaiterai associer à notre réalisation en cours. Pourrions-nous en discuter ? Voici le lien d'accès au site web : opusoft.fr">
                            <div style={{display: 'flex', marginBottom: 20,}}>
                                <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{width: 200, paddingRight: 7, backgroundColor: '#fff', height: 40, alignItems: 'center', paddingLeft: 0, marginTop: 0}}>
                                    {buttonName}
                                    <img src={leftBleuRandom} alt="" className={'leftBleu'} style={{height: 14, width: 20, objectFit: 'contain', marginLeft: 4, marginRight: 0, marginTop: 0}}/>
                                </div>
                            </div>
                        </a>
                    }

                </div>
                <img src={img} alt=""/>
            </div>
        )
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'} style={{overflow: overflow ? 'scroll' : 'hidden'}}>
            <div style={{backgroundColor: '#F7F9FC', zIndex: 10}}>
                <Header setOverflow={setOverflow} />
            </div>

            <div className={'opusPro'}>

                <div className={'triangleRectangle'}>
                    <div className={'rectangle'}/>
                </div>

                {width > 740 &&
                    <p className={'opusBlanc'}>OPUS</p>
                }


                <div style={{marginTop: -540}}>
                    <div className={'widgetTitle'} style={{flexDirection: width > 740 ? 'row' : 'column'}}>

                        <div className={'leftPart'}>
                            <p className={'blueTitle'} style={{marginTop: 25, marginLeft: width > 740 ? 30 : 0, fontSize:  width > 740 ? null : 16}}>{t('translation.chooseOpusClient')}</p>
                            <p className={'title'} style={{marginLeft: width > 740 ? null : 0}}>
                                {t('translation.4StepsToFollowYourProjectOnOpus')}
                            </p>
                            <p className={'corps'} style={{marginLeft: width > 740 ? null : 0}}>
                                {t('translation.body1Clientpart1')}
                                {t('translation.body2Clientpart1')}
                                {t('translation.body3Clientpart1')}
                                {t('translation.body4Clientpart1')}
                                {t('translation.body5Clientpart1')}
                            </p>
                        </div>
                        <div className={'rightPart'}>
                            <img src={prosPhone} alt="" style={{maxWidth: width > 740 ? 'calc(100vw - 710px)' : null}}/>
                        </div>
                    </div>
                </div>


                <ItemEtape title={t('translation.testAndDownload')} button={true} buttonName={t('translation.cta1Client')} img={opusClient1} subtitle={t('translation.bodyClientpart2')} etape={'1'}/>
                <ItemEtape title={t('translation.contactMyArchitect')} button={true} buttonName={t('translation.cta2Client')} img={opusClient2} subtitle={t('translation.bodyClientpart3')} etape={'2'}/>
                <ItemEtape title={t('translation.myArchitectUploadMyProject')} img={opusClient3} subtitle={t('translation.bodyClientpart4')} etape={'3'}/>
                <ItemEtape title={t('translation.yourJourneywithOpusCanStart')} img={opusClient4} subtitle={t('translation.bodyClientpart5')} etape={'4'}/>

                <div style={{height: 50}}/>

                <BesoinsRenseignements/>

                <Footer/>

            </div>

            <Modal isOpen={modalQrCode} className={'modalTelechagrementStores'} overlayClassName="OverlayModal" closeTimeoutMS={300}>
                <p className={'title'}>{t('translation.opusArchitectureAndDesignOnYourPhone')}</p>
                <img src={crossIcon} alt="crossIcon" className={'crossIcon'} onClick={() => {
                    setModalQrCode(false);
                }}/>

                <img src={iphonesStores} alt="" className={'iphonesStores'}/>
            </Modal>

        </div>
    )
}
