import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";

export default function Defilant({
                                     img1,
                                     img2,
                                     img3,
                                     img4,
                                     title1,
                                     title2,
                                     title3,
                                     title4,
                                     subtitle1,
                                     subtitle2,
                                     subtitle3,
                                     subtitle4,
                                     width
                                 })
{
    const { t } = useTranslation();


    const [numeroPoint, setNumeroPoint] = useState(1)

    return (
        <div className={'containerDefilant'}>

            <div className={'defilant'}>


                <div style={{
                    marginLeft:
                        width > 935 ?
                            numeroPoint === 1 ? 0
                                : numeroPoint === 2 ?
                                    -820
                                    : numeroPoint === 3 ?
                                        -1640
                                        : numeroPoint === 4 ?
                                            -2480 : null
                            : width > 520 ?
                                numeroPoint === 1 ? 0
                                    : numeroPoint === 2 ?
                                        -570
                                        : numeroPoint === 3 ?
                                            -1140
                                            : numeroPoint === 4 ?
                                                -1710 : null
                                :
                                numeroPoint === 1 ? 0
                                    : numeroPoint === 2 ?
                                        'calc(-90vw + 30px)'
                                        : numeroPoint === 3 ?
                                            'calc((-90vw + 30px)*2)'
                                            : numeroPoint === 4 ?
                                                'calc((-90vw + 30px)*3)' : null


                }} className={'animateMarginLeft'}/>

                <div
                    onClick={() => setNumeroPoint(1)}
                    className={'partieDefilant'}
                    style={{marginLeft: width > 935 ? 'calc((100vw - 800px)/2)' : width > 520 ? 'calc((100vw - 550px)/2)' : '10vw'}}>
                    <img src={img1} alt="" className={'imageDefilant'} style={{
                        width: width > 935 ? 800 : width > 520 ? 550 : '80vw',
                        height: width < 520 ? '80vw' : null,
                        objectFit: width < 520 ? 'cover' : 'contain'
                    }}/>
                    <p className={'title'}
                       style={{opacity: numeroPoint === 1 ? 1 : 0, textAlign: 'center'}}>{title1}</p>
                    <p className={'subtitle'} style={{opacity: numeroPoint === 1 ? 1 : 0}}>
                        {subtitle1}
                    </p>
                </div>

                <div
                    onClick={() => setNumeroPoint(2)}
                    className={'partieDefilant'}>
                    <img src={img2} alt="" className={'imageDefilant'} style={{
                        width: width > 935 ? 800 : width > 520 ? 550 : '80vw',
                        height: width < 520 ? '80vw' : null,
                        objectFit: width < 520 ? 'cover' : 'contain'
                    }}/>
                    <p className={'title'}
                       style={{opacity: numeroPoint === 2 ? 1 : 0, textAlign: 'center'}}>{title2}</p>
                    <p className={'subtitle'} style={{opacity: numeroPoint === 2 ? 1 : 0}}>
                        {subtitle2}
                    </p>
                </div>

                <div
                    onClick={() => setNumeroPoint(3)}
                    className={'partieDefilant'}>
                    <img src={img3} alt="" className={'imageDefilant'} style={{
                        width: width > 935 ? 800 : width > 520 ? 550 : '80vw',
                        height: width < 520 ? '80vw' : null,
                        objectFit: width < 520 ? 'cover' : 'contain'
                    }}/>
                    <p className={'title'}
                       style={{opacity: numeroPoint === 3 ? 1 : 0, textAlign: 'center'}}>{title3}</p>
                    <p className={'subtitle'} style={{opacity: numeroPoint === 3 ? 1 : 0}}>
                        {subtitle3}
                    </p>
                </div>

                <div
                    onClick={() => setNumeroPoint(4)}
                    className={'partieDefilant'}>
                    <img src={img4} alt="" className={'imageDefilant'} style={{
                        width: width > 935 ? 800 : width > 520 ? 550 : '80vw',
                        height: width < 520 ? '80vw' : null,
                        objectFit: width < 520 ? 'cover' : 'contain'
                    }}/>
                    <p className={'title'}
                       style={{opacity: numeroPoint === 4 ? 1 : 0, textAlign: 'center'}}>{title4}</p>
                    <p className={'subtitle'} style={{opacity: numeroPoint === 4 ? 1 : 0}}>
                        {subtitle4}
                    </p>
                </div>
                <div style={{marginRight: 50}}/>
            </div>

            <div className={'troisPetitsPoints'}>
                <div onClick={() => setNumeroPoint(1)} className={'hover'}>
                    <div className={'point'} style={{backgroundColor: numeroPoint === 1 ? '#cccccc' : null}}/>
                </div>
                <div onClick={() => setNumeroPoint(2)} className={'hover'}>
                    <div className={'point'} style={{backgroundColor: numeroPoint === 2 ? '#cccccc' : null}}/>
                </div>
                <div onClick={() => setNumeroPoint(3)} className={'hover'}>
                    <div className={'point'} style={{backgroundColor: numeroPoint === 3 ? '#cccccc' : null}}/>
                </div>
                <div onClick={() => setNumeroPoint(4)} className={'hover'}>
                    <div className={'point'} style={{backgroundColor: numeroPoint === 4 ? '#cccccc' : null}}/>
                </div>

            </div>
        </div>

    )
}
