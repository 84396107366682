import React, {useContext, useEffect, useState} from 'react'
import Header from "../../components/Header/Header";
import ImageTextComposantLeft from "../../components/ImageTextComposantLeft";
import OPUSMobile1 from "../../Assets/Images/Opus Mobile/OPUSMobile1.png";
import OPUSMobile2 from "../../Assets/Images/Opus Mobile/OPUSMobile2.png";
import OPUSMobile3 from "../../Assets/Images/Opus Mobile/OPUSMobile3.png";
import OPUSMobile4 from "../../Assets/Images/Opus Mobile/OPUSMobile4.png";
import ImageTextComposantRight from "../../components/ImageTextComposantRight";
import LargePhotoTexts from "../../components/LargePhotoTexts";
import Footer from "../../components/Footer";
import PretAVousLancer from "../Home Page/PretAVousLancer";
import ContentOpusMobile from "./ContentOpusMobile";
import MiddlePhoto from "../../components/MiddlePhoto";
import FirstOpusMobile from "../../Assets/Images/FirstOpusMobile.png";
import Defilant from "../../components/Defilant";
import defilant1 from "../../Assets/Images/Opus Mobile/defilant1.png";
import defilant2 from "../../Assets/Images/Opus Mobile/defilant2.png";
import defilant3 from "../../Assets/Images/Opus Mobile/defilant3.png";
import defilant4 from "../../Assets/Images/Opus Mobile/defilant4.png";
import {useTranslation} from "react-i18next";

export default function OPUSMobile() {
    const { t } = useTranslation();

    const [overflow, setOverflow] = useState(true)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'} style={{overflowY: overflow ? 'scroll' : 'hidden'}}>
            <Header setOverflow={setOverflow}/>

            <div className={'opusMobile'}>

                <p className={'littleTitle'} style={{marginTop: width > 740 ? null : 70,}}>OPUS MOBILE</p>
                <p className={'bigTitle'}>{t('translation.followYourProject')}<br/>{t('translation.inAClick')}</p>

                <MiddlePhoto src={FirstOpusMobile} width={width}/>

                <p className={'littleTitle'} style={{fontSize: 16, marginTop: 130}}>{t('translation.daily')}</p>
                <p className={'bigTitle'} style={{fontSize: 32}}>
                    {t('translation.opusMobileText1')}
                </p>

                <p className={'corps'}>
                    {t('translation.opusMobileText2')}
                </p>

                <Defilant
                    title1={t('translation.opusMobileTitle1')}
                    title2={t('translation.opusMobileTitle2')}
                    title3={t('translation.opusMobileTitle3')}
                    title4={t('translation.opusMobileTitle4')}
                    subtitle1={t('translation.opusMobileSubTitle1')}
                    subtitle2={t('translation.opusMobileSubTitle2')}
                    subtitle3={t('translation.opusMobileSubTitle3')}
                    subtitle4={t('translation.opusMobileSubTitle4')}
                    img1={defilant1}
                    img2={defilant2}
                    img3={defilant3}
                    img4={defilant4}
                    width={width}
                />

                <ImageTextComposantLeft width={width} img={OPUSMobile1}  title={t('translation.opusMobile1Text1')} subtitle={t('translation.opusMobile1SubTitle1')} corps={t('translation.opusMobile1Body1')}/>
                <ImageTextComposantRight button={true} buttonName={t('translation.buttonOpusMobile1')} width={width} img={OPUSMobile2}  title={t('translation.opusMobile1Text2')} subtitle={t('translation.opusMobile1SubTitle2')} corps={t('translation.opusMobile1Body2')}/>
                <ImageTextComposantLeft button={true} buttonName={t('translation.buttonOpusMobile2')} width={width} img={OPUSMobile3}  title={t('translation.opusMobile1Text3')} subtitle={t('translation.opusMobile1SubTitle3')} corps={t('translation.opusMobile1Body3')}/>

                <LargePhotoTexts
                    width={width}
                    title={t('translation.textOpusMobile1')}
                    subtitle={''}
                    photo={OPUSMobile4}
                    littleTitle1={t('translation.littleTitleOpusMobile1')}
                    littleTitle2={t('translation.littleTitleOpusMobile2')}
                    littleTitle3={t('translation.littleTitleOpusMobile3')}
                    corps1={t('translation.body1OpusMobile1')}
                    corps2={t('translation.body1OpusMobile2')}
                    corps3={t('translation.body1OpusMobile3')}
                />

                <PretAVousLancer/>

                <Footer/>
            </div>

        </div>
    )
}
