import React from 'react';
import LargePhoto from "../../components/LargePhoto";
import ambitionPatrimoine from "../../Assets/Images/Ambitions/ambitionPatrimoine.JPG";
import ImageTextComposantLeft from "../../components/ImageTextComposantLeft";
import saintSever1 from "../../Assets/Images/Saint Sever/saintSever1.JPG";
import ImageTextComposantRight from "../../components/ImageTextComposantRight";
import saintSever2 from "../../Assets/Images/Saint Sever/saintSever2.JPG";
import saintSever3 from "../../Assets/Images/Saint Sever/saintSever3.JPG";
import {useTranslation} from "react-i18next";

export default function ContentOpusPatrimoine({width}) {
    const { t } = useTranslation();

    return(
        <div className={'opusResearch'}>

            <div className={'enDev'} style={{marginTop: width > 740 ? null : 0 }}>
                {t('translation.inDevelopment')}
            </div>

            <p className={'littleTitle'}>{t('translation.opusHeritage')}</p>

            <p className={'bigTitle'}>{t('translation.protectAndValue')} <br/> {t('translation.heritage')} <br/> {t('translation.historical')}</p>

            <p className={'corpsPage'} style={{marginBottom: width > 740 ? 150 : 0}}>
                {t('translation.body1Heritage')}
            </p>

            {width > 740 &&
                <LargePhoto src={ambitionPatrimoine} width={width} legende={true}/>

            }

            <ImageTextComposantLeft patrimoine={true} width={width} title={t('translation.titleHeritage1')} img={saintSever1} subtitle={t('translation.subTitleHeritage1')} corps={t('translation.bodyHeritage1')}/>
            <ImageTextComposantRight patrimoine={true} width={width} img={saintSever2} title={t('translation.titleHeritage2')} subtitle={t('translation.subTitleHeritage2')} corps={t('translation.bodyHeritage2')}/>
            <ImageTextComposantLeft patrimoine={true} width={width} img={saintSever3} title={t('translation.titleHeritage3')} subtitle={t('translation.subTitleHeritage3')} corps={t('translation.bodyHeritage3')}/>

        </div>

    )
}
