import React, {useState, useEffect} from 'react';
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import leftBleuRandom from "../../Assets/SVGS/leftBleuRandom.svg";
import blueMail from "../../Assets/SVGS/blueMail.svg";
import bluePhone from "../../Assets/SVGS/bluePhone.svg";
import priceLogo from "../../Assets/SVGS/priceLogo.svg";
import integrationLogo from "../../Assets/SVGS/integrationLogo.svg";
import {useTranslation} from "react-i18next";

export default function BesoinsRenseignements() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={'PretAVousLancer'} style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column', alignItems: width > 1200 ? 'flex-start' : 'center', marginTop: width > 740 ? null : 50, marginBottom: width > 740 ? null : 20}}>

            <div className={'firstPart'}>
                <p className={'title'}>{t('translation.needSomeInfoirmation')}</p>
                <p className={'description'} style={{width: width > 1200 ? null : width > 740 ? 510 : '80vw', marginRight: width > 1200 ? null : 0}}>
                    {t('translation.bodyInformation1')}                </p>

                <div style={{display: 'flex'}}>

                    <a href="/contact">
                        <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{paddingRight: 14, color: '#fff'}}>
                            {t('translation.contactUS')}
                            <img src={leftBlanc} alt="" className={'leftBleu'}/>
                        </div>
                    </a>

                </div>

            </div>

            <div style={{display: 'flex', flexDirection: width > 740 ? 'row' : 'column', marginTop: width > 1200 ? null : width > 740 ? 70 : 0}}>
                <div className={'secondThirdPart'} style={{width: width > 740 ? null : '90vw', marginTop: width > 740 ? null : 50}}>
                    <img src={bluePhone} alt="" style={{marginTop: 20, height: 24}} className={'img'}/>
                    <p className={'title'}>{t('translation.contactUsByPhone')}</p>
                    <p className={'subtitle'} style={{
                        width: width > 740 ? null : '85vw'
                    }}>{t('translation.bodyInformation2')}</p>
                    <a href="tel:0185360070">
                        <div style={{display: 'flex'}} className={'hover'}>
                            <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>+ 33 (0)1 85 36 00 70</p>
                        </div>
                    </a>

                </div>

                <div className={'secondThirdPart'} style={{width: width > 740 ? null : '90vw', marginTop: width > 740 ? null : 20}}>
                    <img src={blueMail} alt="" style={{marginTop: 20, height: 24}} className={'img'}/>
                    <p className={'title'}>{t('translation.startUsingOpus')}</p>
                    <p className={'subtitle'} style={{
                        width: width > 740 ? null : '85vw'
                    }}>{t('translation.ourTeamWillGetBackToYou')}</p>

                    <a href="mailto:sales@opusoft.fr?subject=Demande de renseignements" target="_blank">
                        <div style={{display: 'flex'}} className={'hover'}>
                            <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>sales@opusoft.fr</p>
                            <img src={leftBleuRandom} alt="" className={width > 740 ? 'leftBleu' : 'littleLeftBleu'}/>
                        </div>
                    </a>

                </div>
            </div>



        </div>


    )
}
