import React from 'react';
import leftBlanc from "../Assets/SVGS/leftBlanc.svg";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ImageTextComposantRight({apropos, img, button, title, subtitle, corps, setModalDemarrerIntegration, buttonName, width, setMenuGrand, setOverflow, patrimoine}) {
    let history = useHistory();
    const { t } = useTranslation();

    return(
        <div className={'imageTextComposant'} style={{flexDirection: width > 990 ? 'row' : 'column', marginBottom: width < 600 ? -50 : 50, alignItems: 'center'}}>

            <div style={{width: width > 990 ? 550 : null}}>
                {width > 990 ?
                    <div
                        className={'imagePart'}
                        style={{
                            width: 500,
                            marginLeft: -70
                        }}
                    >
                        <img
                            src={img}
                            alt=""
                            className={'image'}
                            style={{
                                width: 500,
                                height: 500,
                                objectFit: width < 600 ? 'contain' : 'contain',

                            }}
                        />
                    </div>
                    : apropos ?

                        <div className={'phoneTextPart'} style={{marginRight: 0, marginTop: 0, marginLeft: 0, width: '85vw'}}>
                            <p className={'titleTextPart'}>{title}</p>
                            <p className={'secondTitle'}>{subtitle}</p>
                            <p className={'corps'}>{corps}</p>

                            {button &&
                                <div style={{display: 'flex', marginTop: 30, marginBottom: 45, justifyContent: width > 740 ? 'center' : null}}>
                                    <div className={'littleBlueButton'}
                                    style={{color: '#fff', paddingRight: 14}}>
                                        {buttonName}
                                        <img src={leftBlanc} alt="" className={'leftBleu'}/>
                                    </div>
                                </div>
                            }
                        </div>
                        :

                    <div>
                        <img
                            src={img}
                            alt=""
                            className={'image'}
                            style={{
                                width: width > 740 ? 'calc(100vw - 180px)' : 500,
                                height: patrimoine ? width > 740 ? 'calc(100vw - 180px)' : 500 : null,
                                margin: 0,
                                objectFit: width < 600 ? 'cover' : 'cover',
                            }}
                        />
                    </div>
                }
            </div>


            {width > 990 ?
                <div className={'textPart'}>
                    <p className={'titleTextPart'}>{title}</p>
                    <p className={'secondTitle'}>{subtitle}</p>
                    <p className={'corps'} style={{textAlign: 'justify'}}>{corps}</p>

                    {button &&
                        <div style={{display: 'flex', marginTop: 30, marginBottom: 45}}>
                            <div className={'blueButton'} onClick={() => {
                                if (buttonName === t('translation.discoverOurFeatures')) {
                                    if (width > 600) {
                                        setMenuGrand(true)
                                        setOverflow(false)
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth'
                                        });
                                    } else {
                                        history.push("/fonctionnalites")
                                    }
                                } else {
                                    history.push("/opus-professionnel")
                                }
                            }} style={{color: '#fff', paddingRight: 14}}>
                                {buttonName}
                                <img src={leftBlanc} alt="" className={'leftBleu'}/>
                            </div>
                        </div>
                    }
                </div>
                :
                apropos ?

                    <div>
                        <img
                            src={img}
                            alt=""
                            className={'image'}
                            style={{
                                width: width > 740 ? 'calc(100vw - 180px)' : 500,
                                maxWidth: '90vw',
                                height: 500,
                                margin: 0,
                                marginTop: 30,
                                marginBottom: 100,
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    :

                <div className={'phoneTextPart'} style={{marginRight: 0, marginTop: 70, marginLeft: 0, width: '85vw'}}>
                    <p className={'titleTextPart'}>{title}</p>
                    <p className={'secondTitle'}>{subtitle}</p>
                    <p className={'corps'}>{corps}</p>

                    {button &&
                        <div style={{display: 'flex', marginTop: 30, marginBottom: 45}}>
                            <div
                                className={'littleBlueButton'}
                                style={{color: '#fff', paddingRight: 14}}
                                onClick={() => {
                                    if (buttonName === t('translation.discoverOurFeatures')) {
                                        if (width > 600) {
                                            setMenuGrand(true)
                                            setOverflow(false)
                                            window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: 'smooth'
                                            });
                                        } else {
                                            history.push("/fonctionnalites")
                                        }
                                    } else {
                                        history.push("/opus-professionnel")

                                    }
                                }}
                            >
                                {buttonName}
                                <img src={leftBlanc} alt="" className={'leftBleu'}/>
                            </div>
                        </div>
                    }
                </div>

            }


        </div>
    )
}
