import React, {useState} from 'react';
import Header from "../../components/Header/Header";
import ContentPolitiqueConfidentialite from "./ContentPolitiqueConfidentialite";
import Footer from "../../components/Footer";

export default function PolitiqueDeConfidentialite() {

    const [overflow, setOverflow] = useState(true)

    return(
        <div className={'page'}>

            <Header setOverflow={overflow}/>

            <div className={'legal'}>
                <p className={'littleTitle'}>POLITIQUE DE CONFIDENTIALITÉ</p>
                <p className={'title'}>
                    Découvrez notre politique de protection <br/>
                    des données personnelles
                </p>
                <p className={'miseAJour'}>Mis à jour le : 20 juin 2022</p>

                <ContentPolitiqueConfidentialite/>
            </div>


            <Footer/>

        </div>
    )
}
