import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import {NavLink} from "react-router-dom";

class ContentConditionsGenerales extends React.Component {
    constructor() {
        super();

        this.state = {
            height: 0,
            width: 0,
            isLoggedIn: false,
            menuLittleScreen: false,
        };

        window.addEventListener("resize", this.update);
    }

    componentDidMount() {
        this.update();
    }

    update = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    refreshPage = () => {
        setTimeout( () => {
            window.location.reload(false);
        }, 200);
    }

    render(props) {

        if (this.state.width < 101) {
            return(
                <div >

                </div>
            )
        } else if (this.state.width > 101 && this.state.width < 100) {
            return (
                <div >

                </div>
            )
        } else {
            return (

                <div className={'contentPolitiqueConfidentialite'}
                     style={{
                         paddingLeft: this.state.width < 1000 ? this.state.width < 800 ? this.state.width < 800 ? 40 : 60 : 100 : 140,
                         paddingRight: this.state.width < 1000 ? this.state.width < 800 ? this.state.width < 800 ? 40 : 60 : 100 : 140,
                     }}>

                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} className={'numeros'}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>1</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>L'application OPUS</p>

                            <p className={'corps'}>
                                1.1. L’application disponible sous le nom OPUS est éditée par la société OPUS FRANCE (ci-après « Opus France »), société par actions simplifiée au capital social de 100 euros,
                                immatriculée au RCS de Versailles sous le numéro 903 751 246 et dont le numéro d’identification à la TVA est FR57903751246.
                                <br/><br/>
                                Ses coordonnées sont les suivantes : <br/>
                                3, avenue du Général Mangin, 78000 – Versailles <br/>
                                07 69 09 48 54 <br/>
                                contact@opusoft.fr
                                <br/><br/>
                                Le directeur de la publication est Monsieur Louis Richard.
                                <br/><br/>
                                L’Application Opus utilise les services d’hébergement Firebase proposés par la société de droit irlandais Google Ireland Limited, immatriculée sous le numéro 368047,
                                et dont les coordonnées sont les suivantes : <br/>
                                1st and 2nd Floor, Gordon House, Barrow Street, Dublin 4, Irlande <br/>
                                +353 1 436 1000
                                <br/><br/>
                                1.2. Opus France permet à des agences d’architecture, de design et de maitrise d’œuvre, personnes morales clientes et cocontractantes d’Opus France (ci-après les
                                « Clients ») de bénéficier d’outils innovants destinés à l’organisation et à l’optimisation de la gestion et du suivi de ses projets immobiliers, d’architecture et de design
                                (ci-après les « Services »).
                                <br/><br/>
                                Les Services, proposés par le biais d’une application web et/ou mobile disponible sur Apple et Android (ci-après ensemble l’« Application Opus »), comprennent un
                                ensemble de fonctionnalités spécifiquement conçues pour répondre aux besoins des Clients, et notamment un tableau de bord permettant de suivre la progression
                                des différents projets, son évolution au plan financier, les photos illustrant l’avancée de leur réalisation, un calendrier personnel, ainsi qu’un service de messagerie
                                centralisant l’ensemble des communications et des fichiers entre les acteurs d’un même projet.
                                <br/><br/>
                                Les caractéristiques des Services sont présentées de façon exhaustive sur l’Application Opus, tant en version web que mobile.
                                <br/><br/>
                                1.3. L’accès aux Services est accordé par Opus France dans le cadre d’un contrat conclu à titre onéreux avec le Client, ou d’une période d’essai qui lui est accordée
                                à titre gratuit par Opus France, en accord avec les Conditions Générales de Vente d’Opus France (ci-après les « CGV »), ou dans le cadre d’un mode démo.
                                <br/><br/>
                                Le Client peut faire bénéficier des Services de l’Application Opus à deux types d’utilisateurs distincts : <br/>
                                - Les collaborateurs des Clients salariés du Client ou partenaires contractuels y compris toutes agences d’architecture partenaires du Client  (ci-après les
                                « Collaborateurs ») ; et, <br/>
                                - Les clients finaux des Clients (ci-après les « Clients Finaux »), sauf dans le cadre d’une période d’essai.
                                <br/><br/>
                                Les représentants du Client, ainsi que ses Collaborateurs et Clients Finaux sont ensemble ci-après désignés l’« Utilisateur » ou « Vous », « Votre », « Vos ».
                            </p>
                        </div>

                    </div>

                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} className={'numeros'}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>2</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Champ d’application des Conditions Générales d’Utilisation</p>

                            <p className={'corps'}>
                                <span style={{fontWeight: 500}}>2.1 Propriétaire du compte</span>
                                <br/><br/>
                                2.1. Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont vocation à encadrer la navigation sur l’ensemble de l’Application Opus par
                                les Utilisateurs quels qu’ils soient, dont plus particulièrement l’accès et le bénéfice des Services, y compris tout nouveau Service, dont toute nouvelle fonctionnalité,
                                qui serait modifié ou y serait ajouté à l’avenir à la discrétion d’Opus France.
                                <br/><br/>
                                Ainsi, en accédant à l’Application Opus ou en utilisant n’importe lequel des Services à tout titre que ce soit, Vous reconnaissez avoir pris connaissance et avoir
                                accepté les CGU et Vous engagez à les respecter.
                                <br/><br/>
                                A défaut d’acceptation des CGU, Vous êtes invité à ne pas créer de compte personnel sur l’Application Opus et à ne pas naviguer et/ou utiliser l’Application Opus
                                et/ou à interrompre immédiatement toute navigation et/ou utilisation de celle-ci.
                                <br/><br/>
                                2.2. Opus France se réserve le droit de mettre à jour ou modifier les CGU, afin de tenir compte de toute évolution, notamment de nature technique, technologique,
                                commerciale, législative ou règlementaire. Les CGU ainsi mises à jour et modifiées entrent en vigueur à la date de leur publication sur l’Application Opus, sur laquelle
                                elles sont accessibles en permanence à tout Utilisateur.
                                <br/><br/>
                                Nous Vous conseillons de vérifier régulièrement les CGU pour connaître les mises à jour ou modifications susceptibles de Vous concerner, la date mentionnée en tête
                                des présentes CGU indiquant leur dernière mise à jour.
                                <br/><br/>
                                2.3. Le Client se porte fort du respect de ces CGU par ses Collaborateurs et Clients Finaux, cette stipulation caractérisant une obligation essentielle du contrat liant
                                le Client à Opus France et sans laquelle Opus France n’aurait pas contracté. Le Client est par conséquent responsable de leurs actes et omissions dans le cadre de
                                l’utilisation par ces derniers de l’Application Opus.
                                <br/><br/>
                                2.4. En cas de contradiction entre les CGV et les CGU, les CGV devront prévaloir.

                            </p>
                        </div>

                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>3</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Conditions d’accès des Utilisateurs sur l’Application Opus</p>

                            <p className={'corps'}>
                                3.1. Pour naviguer sur l’Application Opus, Vous devez être majeur et avoir la capacité juridique suffisante pour accepter les présentes CGU.
                                <br/><br/>
                                3.2. A l’exception de l’utilisation en mode démo, aux fins d’accès aux Services, tout Utilisateur doit créer un compte personnel sur l’Application Opus, en fournissant les
                                données suivantes : ses noms et prénoms, sa photographie, son numéro de téléphone, son adresse email, professionnelle le cas échéant, et l’agence dont il dépend le
                                cas échéant.
                                <br/><br/>
                                L’Application Opus s’adressant à trois types d’Utilisateurs distincts, chacun bénéficie de comptes propres :
                                <br/>
                                - Des comptes réservés exclusivement aux représentants des Clients (ci-après le « Compte Agence ») ;
                                <br/>
                                - Des comptes réservés exclusivement aux Collaborateurs des Clients (ci-après le « Compte Collaborateur ») ; et,
                                <br/>
                                - Des comptes réservés exclusivement aux Clients Finaux (ci-après le « Compte Client Final »).
                                <br/><br/>
                                Les Comptes Agence, Collaborateur et Client Finaux sont ci-après ensemble dénommés les « Comptes Utilisateurs ».
                                <br/><br/>
                                La création des Comptes Collaborateurs et des Comptes Clients Finaux est autorisée par les Clients, sous leur seule responsabilité et selon les conditions particulières
                                souscrites par le Client auprès d’Opus France dans le cadre d’un contrat soumis aux CGV.
                                <br/><br/>
                                À propos du Compte Agence :
                                <br/><br/>
                                Un Compte Agence ne peut être associé qu’à une seule personne morale, Cliente et cocontractante d’Opus France.
                                <br/><br/>
                                À propos du Compte Collaborateur :
                                <br/><br/>
                                Le Client titulaire d’un Compte Agence peut autoriser, directement ou par délégation accordée à l’un de ses Collaborateurs, la création d’un ou plusieurs Comptes
                                Collaborateurs, afin de permettre à ses Collaborateurs d’accéder aux projets dont ils assurent la gestion et le suivi, et leur suppression le cas échéant.
                                <br/><br/>
                                Le périmètre des droits d’accès de chaque Collaborateur peut être modulé en fonction de son rôle et de ses responsabilités vis-à-vis des projets gérés par le biais
                                de l’Application Opus. Il est par exemple possible d’empêcher l’accès des comptes de Collaborateurs aux données de facturation ou encore aux droits de
                                suppression de projets.
                                <br/><br/>
                                À propos du Compte Client Final :
                                <br/><br/>
                                Le Client titulaire d’un Compte Agence peut autoriser la création, directement ou par délégation accordée à l’un de ses Collaborateurs, d’un ou plusieurs Comptes
                                Client Final, afin de permettre aux Clients Finaux d’accéder exclusivement au suivi de leurs projets par le biais de l’Application Opus, et leur suppression le cas échéant.
                                <br/><br/>
                                3.3. Vous pouvez à tout moment accéder à Vos informations personnelles et les modifier le cas échéant, par le biais de Votre interface dédiée sur Votre compte.  Vous pouvez également demander à tout moment la suppression de Votre propre Compte Utilisateur.
                                <br/><br/>
                                3.4. Vous reconnaissez qu’Opus France utilisera l’adresse e-mail que Vous avez fournie lors de l’ouverture de Votre Compte Utilisateur ou telle qu’actualisée le cas
                                échéant, comme moyen principal de communication avec Vous.

                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>4</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Sécurisation du Compte Utilisateur </p>

                            <p className={'corps'}>
                                4.1. A chaque Compte Utilisateur est attribué un identifiant personnel et un mot de passe personnel et confidentiel. En cas d’oubli du mot de passe permettant
                                la connexion à Votre compte personnel, Vous pouvez à tout moment demander à sa réinitialisation, en suivant les consignes communiquées sur l’Application
                                Opus.
                                <br/><br/>
                                4.2. Il appartient à chaque Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses propres données, dont ses identifiants d’accès à son
                                compte sur l’Application Opus, de la contamination d’éventuels virus circulant sur Internet et à en conserver la confidentialité.


                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>5</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Responsabilité des Utilisateurs </p>

                            <p className={'corps'}>
                                5.1. Les Utilisateurs sont libres de publier sur l’Application My Opus tous contenus, tels que des photos, images, vidéos, graphiques, contenus écrits, fichiers
                                audio, codes, informations, commentaires, avis, messages ou données téléchargés, collectés, stockés, aﬃchés, distribués, transmis ou exposés par le biais de
                                l’Application Opus, y compris au sein de son espace de messagerie entre Utilisateurs (ci-après les « Contenus Utilisateur »).
                                <br/><br/>
                                Les Utilisateurs s’expriment en leur nom, en qualité de personne physique ou morale, et non au nom d’Opus France. Ils sont exclusivement responsables de tous
                                Contenus Utilisateur de toute nature qu’ils pourraient diffuser dans le cadre de l’Application Opus.
                                <br/><br/>
                                5.2. Les Utilisateurs s’obligent à faire une utilisation de l’Application Opus dans un but conforme aux droits et intérêts d’Opus France et des tiers, à faire preuve de
                                bonne foi et de la plus grande modération à l’égard d’Opus France, des autres Utilisateurs, tant Clients que Collaborateurs et Clients Finaux, et plus largement de
                                tous tiers, personnes publiques ou privées, directement identifiées ou indirectement identifiables.
                                <br/><br/>
                                Tous Contenus Utilisateur doivent en tout état de cause, respecter les règles suivantes :
                                <br/><br/>
                                - Ne pas porter atteinte, par leur contenu, aux lois et aux règlements applicables sur le territoire français, tels que de manière non exhaustive, à la vie privée,
                                à l’ordre public, au respect de la personne humaine, à l’égalité entre les hommes et les femmes, à la protection de l’enfance et de l’adolescence, aux droits sur
                                les données à caractère personnel ;
                                <br/>
                                - Ne comprendre aucun propos ou comportement à caractère haineux, injurieux, discriminatoire, diffamatoire, violent, raciste, pornographique, sexiste ou de
                                nature à porter atteinte à la dignité humaine ;
                                <br/>
                                - Ne pas effectuer de publicité clandestine et ne pas se livrer au dénigrement de marques ou sociétés, d’institutions ou d’associations, sauf dans le cadre
                                d’interventions à caractère strictement parodique ou humoristique ;
                                <br/>
                                - Respecter les droits de propriété intellectuelle des tiers et d’Opus France, notamment le droit moral de l’auteur et, en particulier, son droit à la paternité et
                                au respect dû à ses œuvres, ainsi que le droit à l’image ;
                                <br/>
                                - Ne comporter aucune reproduction ni aucun contenu de quelque nature qu’il soit, susceptible de porter atteinte aux droits des tiers et/ou d’Opus France,
                                de donner lieu à des actions judiciaires, notamment pour contrefaçon, concurrence déloyale et/ou parasitaire ;
                                <br/>
                                - Ne pas véhiculer des messages électroniques commerciaux non sollicités ou spams.

                                <br/><br/>
                                5.3. Tout manquement aux règles édictées au sein des présentes CGU sera susceptible d’être sanctionné par Opus France, par une suppression des Contenus
                                Utilisateur litigieux, voire par une suppression du Compte Utilisateur concerné et une interdiction immédiate et définitive d’accès à l’Application Opus de l’Utilisateur
                                concerné.

                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>6</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Propriété intellectuelle</p>

                            <p className={'corps'}>
                                6.1. Les droits de propriété intellectuelle, y compris tous droits d’auteur, portant sur l’ensemble des marques, figuratives ou non, illustrations, images, dessins,
                                croquis, modèles, motifs, logotypes, textes, descriptifs, dénominations sociales, noms commerciaux, enseignes, logiciels, bases de données (ci-après les « Droits
                                de Propriété Intellectuelle »), constituant et/ou figurant notamment sur l’interface graphique de l’Application Opus et/ou exploités à l’occasion de la fourniture des
                                Services, qu’ils soient déposés ou non, sont et demeureront la propriété exclusive d’Opus France dans le monde entier. Les Utilisateurs s’expriment en leur nom,
                                en qualité de personne physique ou morale, et non au nom d’Opus France. Ils sont exclusivement responsables de tous contenus Utilisateur de toute nature qu’ils
                                pourraient diffuser dans le cadre de l’Application Opus.
                                <br/><br/>
                                L’accès et/ou la navigation de l’Utilisateur sur l’Application Opus ne valent donc pas transfert ou cession des Droits de Propriété Intellectuelle d’Opus France,
                                autorisation d’utilisation, de reproduction, de représentation, de modification, totale ou partielle, directement ou indirectement, pour quelque motif et sur quelque
                                support que ce soit, au profit de l’Utilisateur, et sur tous territoires.
                                <br/><br/>
                                Les Parties conviennent en outre que l’ensemble des Droits de Propriété Intellectuelle portant sur tout contenu, photos, images, vidéos, graphiques, contenus écrits,
                                fichiers audio, codes, informations, commentaires, avis, messages ou données qui serait généré et/ou produit de toute manière que ce soit, par le biais de l’Application
                                Opus et grâce à ses fonctionnalités, y compris toute information de progression, financière, évènement du calendrier etc. (ci-après les « Contenus Opus »), appartiendront
                                ab initio ou seront intégralement cédés à titre exclusif à Opus France.
                                <br/><br/>
                                Par conséquent, toute reproduction, représentation, modification, adaptation ou utilisation, totale ou partielle, quelle qu’elle soit de ces Droits de Propriété Intellectuelle
                                appartenant à Opus France, pour quelque motif et sur quelque support que ce soit, à titre gratuit ou onéreux, sans accord exprès préalable et écrit d’Opus France, est
                                strictement interdite sur tous territoires.
                                <br/><br/>
                                Tout Utilisateur qui violerait cette stipulation se verra poursuivi et immédiatement interdit d’utiliser l’Application Opus de façon définitive, et devra effacer et détruire toute
                                reproduction illicite des contenus protégés par des Droits de Propriété Intellectuelle, et ce, sur tous supports.
                                <br/><br/>
                                6.2. En revanche, la publication sur l’Application Opus, y compris sur son espace de messagerie, de tous Contenus Utilisateur n’emporte aucune cession de Droits de
                                Propriété Intellectuelle au profit d’Opus France et demeure, le cas échéant, la propriété de l’Utilisateur, et en tout état de cause sous sa responsabilité conformément à
                                l’article 5.1. des CGU.
                                <br/><br/>
                                En téléchargeant, stockant, affichant, distribuant, transmettant ou exposant des Contenus Utilisateur par le biais de l’Application Opus, Vous acceptez néanmoins
                                expressément que les autres Utilisateurs puissent y accéder si leurs droits d’accès le permettent.
                                <br/><br/>
                                6.3. Tout Utilisateur bénéficiant d’une autorisation spécifique à cette fin demeure libre de supprimer de l’Application Opus à tout moment toutes données,
                                informations et contenus, y compris tout Contenu Utilisateur, auxquels il est en mesure d’accéder depuis son Compte Utilisateur.


                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>7</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Limite de responsabilité</p>

                            <p className={'corps'}>
                                1. Vous comprenez et acceptez expressément que, dans la mesure permise par les lois en vigueur, OPUS ne sera pas responsable de dommages directs,
                                indirects, accessoires, spéciaux, consécutifs ou exemplaires, y compris, mais sans s’y limiter, les dommages-intérêts pour manque à gagner, achalandage,
                                utilisation, données ou autres pertes intangibles résultant de l’utilisation ou de l’impossibilité d’utiliser le Service.
                                <br/><br/>
                                2. Dans la mesure permise par les lois applicables, OPUS ou nos fournisseurs ne pourront en aucun cas être tenus responsables des profits perdus ni des
                                dommages spéciaux, accessoires ou consécutifs découlant de ou en relation avec notre site, nos Services ou les présentes Conditions d’utilisation
                                (toutefois résultant, y compris la négligence). Vous acceptez de nous indemniser et de protéger notre société mère, nos filiales, nos sociétés affiliées,
                                nos partenaires, dirigeants, administrateurs, agents, employés et fournisseurs d’OPUS, de toute réclamation ou demande, y compris des honoraires
                                raisonnables d’avocats, faite par un tiers en raison ou résultant de votre violation des présentes Conditions d’utilisation ou des documents qu’elle incorpore
                                par référence (y compris l’AUP), ou de votre violation de toute loi ou des droits d’une tierce partie.
                                <br/><br/>
                                3. Votre utilisation des Services est à vos risques et périls. Les services sont fournis « tels quels » et « tels que disponibles » sans aucune garantie ni
                                condition, expresse, implicite ou légale.
                                <br/><br/>
                                4. OPUS ne garantit pas que les Services seront ininterrompus, rapides, sécurisés ou sans erreur.
                                <br/><br/>
                                5. OPUS ne garantit pas que les résultats pouvant être obtenus en utilisant les Services seront exacts ou fiables.
                                <br/><br/>
                                6. OPUS ne garantit pas que la qualité des produits, services, informations ou autres matériels obtenus par vous par le biais des Services répondra à vos
                                attentes, ou que toute erreur dans les Services sera corrigée.

                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>8</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Exclusions et limitations de responsabilité d’Opus France </p>

                            <p className={'corps'}>
                                8.1. Opus France fait ses meilleurs efforts pour que l’Application Opus soit accessible aux Utilisateurs 24 heures sur 24 et 7 jours sur 7. A cet effet, elle assure,
                                avec la plus grande réactivité et régularité, des mises à jour de l’Application Opus, tant sa version web que mobile, afin de répondre aux bugs, erreurs et
                                dysfonctionnements, qui pourraient notamment être signalés par un Utilisateur.
                                <br/><br/>
                                Toutefois, l’Utilisateur est informé du fait que le réseau Internet et les systèmes informatiques et de télécommunication peuvent connaitre des dysfonctionnements
                                et des interruptions et que des opérations de maintenance technique limitant l’accès à l’Application Opus sont susceptibles d’intervenir.
                                <br/><br/>
                                Opus France ne pourra être tenue responsable de toute inaccessibilité temporaire ou permanente à celle-ci, que cela soit de son fait ou d’une circonstance
                                extérieure, ni de tout dommage direct ou indirect qui pourrait en résulter pour son Utilisateur ou tout tiers.
                                <br/><br/>
                                8.2. Opus France décline toute responsabilité quant à la teneur, la licéité, la qualité et la viabilité technique, technologique ou commerciale notamment, des
                                Contenus Utilisateur publiés par les Utilisateurs et des produits ou services procurés par tout Client à ses propres Clients Finaux, par le biais de l’Application
                                Opus et quant à tous dommages qui pourraient être causés de ce chef à tout Utilisateur ou tiers.
                                <br/><br/>
                                L’Utilisateur est par ailleurs expressément informé du fait que les Contenus Opus produits et générés au sein de l’Application Opus par le biais de ses
                                fonctionnalités, Opus France ne sont fournis aux Utilisateurs qu’à titre informatif et indicatif, et qu’Opus France ne pourra en aucun cas être tenue responsable
                                de leur exactitude et exhaustivité.
                                <br/><br/>
                                Opus France ayant toutefois à cœur de proposer à ses Utilisateurs une expérience optimale de l’Application Opus, tout Utilisateur témoin d’une publication de
                                Contenus Utilisateur, de commentaires, d’avis, ou de messages de nature inappropriée ou illicite et/ou contrevenant aux présentes CGU, est invité à contacter
                                Opus France à l’adresse : violation-report@opusoft.fr.
                                <br/><br/>
                                8.3. Opus France ne pourra être tenu responsable de tout dommage qui pourrait résulter d’une faille de sécurité et d’une connexion à un Compte Utilisateur
                                générée ou facilitée par une divulgation du mot de passe, tant volontaire qu’involontaire, en raison du manque de diligence de l’Utilisateur dans sa protection.
                                <br/><br/>
                                8.4. En tout état de cause, Vous reconnaissez qu’Opus France ne pourra pas être considérée comme responsable des dommages indirects et/ou imprévisibles
                                envers l’Utilisateur ou tous tiers, résultant de l’accès et/ou la navigation sur l’Application Opus, ainsi que de l’utilisation des Services procurés par celle-ci, dont
                                plus particulièrement les fonctionnalités de suivi et de gestion de projets immobiliers et architecturaux, y compris en cas de préjudices financier ou commercial,
                                de manque à gagner, de pertes de bénéfices, d’économies escomptées, de clientèle, de données, d’attractivité et de réputation de l’Utilisateur.
                                <br/><br/>
                                8.5. Le cas échéant, Vous serez tenus d’indemniser Opus France de l’ensemble des dommages engendrés par tout usage de l’Application Opus en violation
                                des présentes CGU ou de la publication sur cette dernière de tous Contenus Utilisateur, et de rembourser la totalité des dommages et intérêts auxquels Opus
                                France serait condamnée par une décision de justice passée en force de chose jugée, y compris les frais de procédure, d’avocats et d’expertise le cas échéant.

                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>9</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Service d’assistance technique </p>

                            <p className={'corps'}>
                                Opus France met à disposition de ses Utilisateurs des livrets de support techniques consultables sur l’Application Opus, aux fins de la présenter, de découvrir
                                ses différentes fonctionnalités, de faciliter son utilisation par les différents Utilisateurs et l’intégration et la gestion de nouveaux projets.
                                <br/><br/>
                                Si ceux-ci ne suffisaient pas, les Services comprennent également un service d’assistance téléphonique et/ou email en fonction de la formule d’abonnement
                                choisie par le Client, afin de répondre à toute question relative aux Services de tout Utilisateur, quelle que soit sa qualité, notamment tous problèmes techniques,
                                questions ou suggestions suscitées à l’occasion de la navigation sur l’Application Opus ou de la prise de connaissance des présentes CGU.
                                <br/><br/>
                                Ses coordonnées sont les suivantes : <br/>
                                support@opusoft.fr <br/>
                                07 69 09 48 54
                                <br/><br/>
                                Le service d’assistance technique d’Opus France est disponible du lundi au samedi de 9 heures à 21 heures hors jours fériés ou 24h/24 et 7j/7 en fonction de
                                la formule d’abonnement choisie par le Client.
                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>10</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Divers</p>

                            <p className={'corps'}>
                                10.1. Le non-exercice par Opus France de tout droit ou toute stipulation des CGU ne constitue pas une renonciation définitive à ce droit ou à cette stipulation.
                                <br/><br/>
                                10.2. Si une stipulation des CGU est jugée non valide, illégale ou inapplicable pour toute raison que ce soit, cette stipulation sera modifiée et interprétée de
                                manière à atteindre au mieux les objectifs de la stipulation originale dans toute la mesure permise par la loi.
                                <br/><br/>
                                Les autres stipulations des CGU resteront alors pleinement en vigueur.

                            </p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>11</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Loi applicable et juridictions compétentes </p>

                            <p className={'corps'}>
                                11.1. Les présentes CGU doivent être régies et interprétées conformément aux lois françaises qui s’y appliquent, à l’exclusion de ses règles de conflit de lois.
                                <br/><br/>
                                11.2. Tout litige relatif à la conclusion, l’interprétation et l’exécution des présentes CGU sera soumis à la compétence exclusive des tribunaux du ressort de la
                                Cour d’appel de Versailles, sous réserve de toute disposition légale contraire impérative.
                            </p>
                        </div>
                    </div>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>
                        OPUS France Inc.
                        <br />
                        Chief Privacy Officer
                        <br />
                        3 avenue du général Mangin
                        <br />
                        Rez-de-chaussée,
                        <br />
                        Versailles, 78000
                        <br />
                        France
                    </p>

                </div>
            )
        }
    }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<ContentConditionsGenerales />, rootElement);

export default ContentConditionsGenerales;
