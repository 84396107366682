import React, {useState} from 'react';
import firstDashboardIphone from '../../Assets/Images/Fonctionnalites/firstDashboardIphone.png'
import financesiPhone from '../../Assets/Images/Fonctionnalites/financesiPhone.png'
import progressioniPhone from '../../Assets/Images/Fonctionnalites/progressioniPhone.png'
import photosiPhone from '../../Assets/Images/Fonctionnalites/photosiPhone.png'
import messagerieiPhone from '../../Assets/Images/Fonctionnalites/messagerieiPhone.png'
import fichiersiPhone from '../../Assets/Images/Fonctionnalites/fichiersiPhone.png'
import chekDoubleBleu from '../../Assets/SVGS/chekDoubleBleu.svg'
import {useTranslation} from "react-i18next";

export default function BigMenuOpusMobile() {
    const { t } = useTranslation();

    const [dashboardSelect, setDashboardSelect] = useState(true)
    const [dashboardProgressionSelect, setDashboardProgressionSelect] = useState(false)
    const [progressionSelect, setProgressionSelect] = useState(false)
    const [financesSelect, setFinancesSelect] = useState(false)
    const [photosSelect, setPhotosSelect] = useState(false)
    const [messagerieSelect, setMessagerieSelect] = useState(false)
    const [fichiersSelect, setFichiersSelect] = useState(false)

    return(
        <div className={'rightPart'}>

            <div className={'opus'}>
                <div className={'leftPart'} style={{borderRightWidth: 0, marginTop: 0}}>
                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(true)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={dashboardSelect ? 'titleSelect' : 'title'} style={{fontSize: 20, marginTop: 50,}}>{t('translation.dashboard')}</p>
                        <p className={dashboardSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.yourProjectEvolutionAtAGlance')}</p>
                    </div>


                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(true)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={progressionSelect ? 'titleSelect' : 'title'}>{t('translation.progression')}</p>
                        <p className={progressionSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.monitorTheProgressOfYourProject')}</p>
                    </div>


                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(true)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={financesSelect ? 'titleSelect' : 'title'}>{t('translation.finances')}</p>
                        <p className={financesSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.monitorTheFinancesOfYourProject')}</p>
                    </div>

                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(true)
                        setMessagerieSelect(false)
                        setFichiersSelect(false)
                    }}>
                        <p className={photosSelect ? 'titleSelect' : 'title'}>{t('translation.photos')}</p>
                        <p className={photosSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.benefitFromAVisualMonitoring')}</p>
                    </div>

                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(true)
                        setFichiersSelect(false)
                    }}>
                        <p className={messagerieSelect ? 'titleSelect' : 'title'}>{t('translation.chat')}</p>
                        <p className={messagerieSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.chatWithYourProjectStakeholders')}</p>
                    </div>

                    <div className={'containerElement'} onClick={() => {
                        setDashboardSelect(false)
                        setProgressionSelect(false)
                        setFinancesSelect(false)
                        setPhotosSelect(false)
                        setMessagerieSelect(false)
                        setFichiersSelect(true)
                    }}>
                        <p className={fichiersSelect ? 'titleSelect' : 'title'}>{t('translation.files')}</p>
                        <p className={fichiersSelect ? 'subtitleSelect' : 'subtitle'}>{t('translation.accessAnyDocument')}</p>
                    </div>

                </div>

                <div style={{display: 'flex'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                        <img
                            src={

                            dashboardSelect ?
                                firstDashboardIphone
                                : progressionSelect ?
                                    progressioniPhone
                                    : financesSelect ?
                                        financesiPhone
                                        : photosSelect ?
                                            photosiPhone
                                            : messagerieSelect ?
                                                messagerieiPhone
                                                : fichiersSelect ?
                                                    fichiersiPhone
                                                    : null

                            }
                            alt=""
                            className={'iphone'}/>
                    </div>

                </div>

                <div className={'textPart'} style={{justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 35, marginLeft: 10,}}>
                    <p
                        style={{
                            marginTop: 0,
                            marginLeft: 60,
                            fontSize: 25,
                            fontWeight: '700'
                        }}
                    >
                        {
                            dashboardSelect ?
                                t('translation.yourProjectAtAGlance')
                                : progressionSelect ?
                                    t('translation.monitorPreciselyYourProjectAdvancement')
                                    : financesSelect ?
                                        t('translation.keepAnEyeOnYourProjectFinances')
                                        : photosSelect ?
                                            t('translation.benefitFromAVisualMonitoringEnhancingPictureSharing')
                                            : messagerieSelect ?
                                                t('translation.chatWithYourProjectStakeholders')
                                                : fichiersSelect ?
                                                    t('translation.accessYourDocumentsEverywhereAnytime')
                                                    : null
                        }
                    </p>

                    <p
                        style={{
                            marginTop: 0,
                            marginLeft: 60,
                            fontSize: 16,
                            width: 450,
                            lineHeight: 1.5,
                            fontWeight: 600,
                            opacity: 0.7,
                        }}
                    >
                        {
                            dashboardSelect ?
                                t('translation.subtitleFunctionalities1')
                                : progressionSelect ?
                                    t('translation.subtitleFunctionalities2')
                                    : financesSelect ?
                                        t('translation.subtitleFunctionalities3')
                                        : photosSelect ?
                                            t('translation.subtitleFunctionalities4')
                                            : messagerieSelect ?
                                                t('translation.subtitleFunctionalities5')
                                                : fichiersSelect ?
                                                    t('translation.subtitleFunctionalities6')
                                                    : null
                        }
                    </p>

                    <div style={{marginTop: 25, display: 'flex'}}>
                        <img src={chekDoubleBleu} alt="" style={{height: 20, marginLeft: 65}}/>
                        <p
                            style={{
                                marginLeft: 16,
                                fontSize: 16,
                                width: 450,
                                marginTop: 0,
                            }}
                        >
                            {
                                dashboardSelect ?
                                    t('translation.point1Functionalities1')
                                    : progressionSelect ?
                                        t('translation.point1Functionalities2')
                                        : financesSelect ?
                                            t('translation.point1Functionalities3')
                                            : photosSelect ?
                                                t('translation.point1Functionalities4')
                                                : messagerieSelect ?
                                                    t('translation.point1Functionalities5')
                                                    : fichiersSelect ?
                                                        t('translation.point1Functionalities6')
                                                        : null
                            }
                        </p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex'}}>
                        <img src={chekDoubleBleu} alt="" style={{height: 20, marginLeft: 65}}/>
                        <p
                            style={{
                                marginLeft: 16,
                                fontSize: 16,
                                width: 450,
                                marginTop: 0,
                            }}
                        >
                            {
                                dashboardSelect ?
                                    t('translation.point2Functionalities1')
                                    : progressionSelect ?
                                        t('translation.point2Functionalities2')
                                        : financesSelect ?
                                            t('translation.point2Functionalities3')
                                            : photosSelect ?
                                                t('translation.point2Functionalities4')
                                                : messagerieSelect ?
                                                    t('translation.point2Functionalities5')
                                                    : fichiersSelect ?
                                                        t('translation.point2Functionalities6')
                                                        : null
                            }
                        </p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex'}}>
                        <img src={chekDoubleBleu} alt="" style={{height: 20, marginLeft: 65}}/>
                        <p
                            style={{
                                marginLeft: 16,
                                fontSize: 16,
                                width: 450,
                                marginTop: 0,
                            }}
                        >
                            {
                                dashboardSelect ?
                                    t('translation.point3Functionalities1')
                                    : progressionSelect ?
                                        t('translation.point3Functionalities2')
                                        : financesSelect ?
                                            t('translation.point3Functionalities3')
                                            : photosSelect ?
                                                t('translation.point3Functionalities4')
                                                : messagerieSelect ?
                                                    t('translation.point3Functionalities5')
                                                    : fichiersSelect ?
                                                        t('translation.point3Functionalities6')
                                                        : null
                            }
                        </p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex'}}>
                        <img src={chekDoubleBleu} alt="" style={{height: 20, marginLeft: 65}}/>
                        <p
                            style={{
                                marginLeft: 16,
                                fontSize: 16,
                                width: 450,
                                marginTop: 0,
                            }}
                        >
                            {
                                dashboardSelect ?
                                    t('translation.point4Functionalities1')
                                    : progressionSelect ?
                                        t('translation.point4Functionalities2')
                                        : financesSelect ?
                                            t('translation.point4Functionalities3')
                                            : photosSelect ?
                                                t('translation.point4Functionalities4')
                                                : messagerieSelect ?
                                                    t('translation.point4Functionalities5')
                                                    : fichiersSelect ?
                                                        t('translation.point4Functionalities6')
                                                        : null
                            }
                        </p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex'}}>
                        <img src={chekDoubleBleu} alt="" style={{height: 20, marginLeft: 65}}/>
                        <p
                            style={{
                                marginLeft: 16,
                                fontSize: 16,
                                width: 450,
                                marginTop: 0,
                            }}
                        >
                            {
                                dashboardSelect ?
                                    t('translation.point51Functionalities1')
                                    : progressionSelect ?
                                        t('translation.point51Functionalities11')
                                        : financesSelect ?
                                            t('translation.point51Functionalities2')
                                            : photosSelect ?
                                                t('translation.point51Functionalities3')
                                                : messagerieSelect ?
                                                    t('translation.point51Functionalities4')
                                                    : fichiersSelect ?
                                                        t('translation.point51Functionalities5')
                                                        : null
                            }
                        </p>
                    </div>


                </div>
            </div>

        </div>
    )
}
