import React from 'react';
import '../_choosePlanPage.scss';
import whiteCheckPlan from '../../../Assets/whiteCheckPlan.svg';
import blueCheckPlan from '../../../Assets/blueCheckPlan.svg';
import opusErvices from '../../../Assets/Images/opusErvices.png';
import blackCheckPlan from '../../../Assets/blackCheckPlan.svg';
import {useTranslation} from "react-i18next";
export default function ItemPlanChoice({ plan, index }) {
	const { t } = useTranslation();
	function CheckOption({ title }) {
		return (
			<div className={'checkOption'}>
				<img
					src={index === 0 ? blueCheckPlan : whiteCheckPlan}
					alt=""
					className={'simpleCheck'}
				/>
				{title}
			</div>
		);
	}

	return (
		<div
			className={
				 index === 0 ? 'itemPlanChoiceLightBlue' : 'itemPlanChoiceBlue'
			}>
			<div>
				<p className={'titlePlan'}>{plan.title}</p>
				<p className={'subtitlePlan'}>{plan.subtitle}</p>

				{plan.checks.map((option, index) => (
					<CheckOption key={index} title={option}/>
				))}

				<div className={'bar'}/>

				<p className={'price'}>
					<span style={{textDecoration: plan.discount && 'line-through'}}>$ {plan.price}</span>{' '}
				</p>
				<p className={'subtitlePrice'}>{plan.subtitlePrice}</p>

				<div className={'buttonPlan'}>{t('translation.chooseThisPlan')}</div>
			</div>

			{index === 1 && <img src={opusErvices} alt="" className={'illustration'}/>}

		</div>
	);
}
