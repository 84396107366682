import React from 'react';
import LargePhoto from "../../components/LargePhoto";
import photoOpusResearch from "../../Assets/Images/OPUS Reasearch/photoOpusResearch.png";
import ImageTextComposantLeft from "../../components/ImageTextComposantLeft";
import opusResearch2 from "../../Assets/Images/OPUS Reasearch/opusResearch2.png";
import ImageTextComposantRight from "../../components/ImageTextComposantRight";
import opusResearch3 from "../../Assets/Images/OPUS Reasearch/opusResearch3.png";
import opusReasearch4 from "../../Assets/Images/OPUS Reasearch/opusReasearch4.png";
import {useTranslation} from "react-i18next";

export default function ContentOpusResearch({width}) {
    const { t } = useTranslation();

    return(
        <div className={'opusResearch'}>

            <p className={'littleTitle'} style={{marginTop: width > 740 ? null : 0 }}>{t('translation.opusResearchProgram')}</p>
            <p className={'bigTitle'}>{t('translation.imagineTheFuture')} <br/> {t('translation.together')}</p>

            <p className={'corpsPage'} style={{marginBottom: width > 740 ? 150 : 100}}>
                {t('translation.bodyOpusResearch1')}
            </p>

            <LargePhoto src={photoOpusResearch} width={width}/>

            <ImageTextComposantLeft width={width} title={t('translation.titleOpusResearch1')} img={opusResearch2} subtitle={t('translation.subTitleOpusResearch1')} corps={t('translation.body1OpusResearch1')}/>
            <ImageTextComposantRight width={width} img={opusResearch3} title={t('translation.titleOpusResearch2')} subtitle={t('translation.subTitleOpusResearch2')} corps={t('translation.body1OpusResearch2')}/>
            <ImageTextComposantLeft width={width} img={opusReasearch4} title={t('translation.titleOpusResearch3')} subtitle={t('translation.subTitleOpusResearch3')} corps={t('translation.body1OpusResearch3')}/>

        </div>
    )
}
