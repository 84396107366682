import React, {useContext, useEffect, useState} from 'react'
import Header from "../../components/Header/Header";
import FirstFonctionnalites from '../../Assets/Images/FirstFonctionnalites.png'
import littleFirstFonctionnalites from '../../Assets/Images/littleFirstFonctionnalites.jpeg'
import MiddlePhoto from "../../components/MiddlePhoto";
import firstDashboardIphone from '../../Assets/Images/Fonctionnalites/firstDashboardIphone.png'
import projetsListiPhone from '../../Assets/Images/Fonctionnalites/projetsListiPhone.png'
import portfolioiPhone from '../../Assets/Images/Fonctionnalites/portfolioiPhone.png'
import fichiersiPhone from '../../Assets/Images/Fonctionnalites/fichiersiPhone.png'
import calendrieriPhone from '../../Assets/Images/Fonctionnalites/calendrieriPhone.png'
import progressioniPhone from '../../Assets/Images/Fonctionnalites/progressioniPhone.png'
import messagerieiPhone from '../../Assets/Images/Fonctionnalites/messagerieiPhone.png'
import photosiPhone from '../../Assets/Images/Fonctionnalites/photosiPhone.png'
import financesiPhone from '../../Assets/Images/Fonctionnalites/financesiPhone.png'
import missioniPhone from '../../Assets/Images/Fonctionnalites/missioniPhone.png'
import projetsWeb from '../../Assets/Images/Fonctionnalites/projetsWeb.png'
import organisationWeb from "../../Assets/Images/Sreen Web/organisationWeb.png";
import collaborateursWeb from "../../Assets/Images/Sreen Web/collaborateursWeb.png";
import messagerieGlobaleWeb from "../../Assets/Images/Sreen Web/messagerieGlobaleWeb.png";
import intervenantsWeb from "../../Assets/Images/Sreen Web/intervenantsWeb.png";
import dashboardWeb from "../../Assets/Images/Sreen Web/dashboardWeb.png";
import progressionWeb from "../../Assets/Images/Sreen Web/progressionWeb.png";
import financesWeb from "../../Assets/Images/Sreen Web/financesWeb.png";
import albumsPhotos from "../../Assets/Images/Sreen Web/albumsPhotos.png";
import messagerieProjet from "../../Assets/Images/Sreen Web/messagerieProjet.png";
import fichiersWeb from "../../Assets/Images/Sreen Web/fichiersWeb.png";
import portfolioWeb from "../../Assets/Images/Sreen Web/portfolioWeb.png";
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import Modal from "react-modal";
import cross from "../../Assets/Images/cross.png";
import PretAVousLancer from "../Home Page/PretAVousLancer";
import Footer from "../../components/Footer";
import {useTranslation} from "react-i18next";

export default function Fonctionnalites() {
    const { t } = useTranslation();

    const [overflow, setOverflow] = useState(true)
    const [modalDetailFonct, setModalDetailFonct] = useState(false)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [opusMobile, setOpusMobile] = useState(true)

    function ItemFonctionnaliteMobile({title, subtitle, screen, web, link}) {

        const [isInfos, setisInfos] = useState(false)

        return (
            <div className={'hoverFunctionality'}>
                <div className={web ? 'itemFonctionnaliteWeb' : 'itemFonctionnalite'}
                     style={{zIndex: 1, flexDirection: isInfos ? 'row' : 'column', width: isInfos ? 800 : null}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <p className={'title'}>{title}</p>
                        <p className={'subtitle'}>{subtitle}</p>
                    </div>
                    <img src={screen} alt="" className={'iphone'} style={{marginTop: isInfos ? 70 : null}}/>
                </div>

                {/*
                                <div className={'filtreNoir'}>
                    <div className={'enSavoirPlus'} onClick={() => setModalDetailFonct(true)}>
                        En savoir plus
                        <img src={leftBlanc} alt="" className={'leftBlanc'} />
                    </div>
                </div>
                */}

            </div>

        )
    }

    function ItemFonctionnaliteWeb({title, subtitle, screen, multiple, screen1, screen2, screen3}) {

        const [isInfos, setisInfos] = useState(false)

        return (
            <div className={'hoverFunctionality'}>
                <div className={'itemFonctionnaliteWeb'} style={{
                    zIndex: 1,
                    flexDirection: isInfos ? 'row' : 'column',
                    width: isInfos ? 800 : null,
                    height: multiple ? null : 550
                }}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <p className={'title'}>{title}</p>
                        <p className={'subtitle'}>{subtitle}</p>
                    </div>

                    {multiple ?
                        <div style={{display: 'flex', marginBottom: 20}}>
                            <img src={screen1} alt="" className={'petitWeb'} style={{marginTop: isInfos ? 70 : null}}/>
                            <img src={screen2} alt="" className={'petitWeb'} style={{marginTop: isInfos ? 70 : null}}/>
                            <img src={screen3} alt="" className={'petitWeb'} style={{marginTop: isInfos ? 70 : null}}/>

                        </div>
                        :
                        <div style={{
                            width: 700,
                            maxWidth: '90vw',
                            justifyContent: width > 740 ? 'center' : 'flex-start',
                            display: 'flex'
                        }}>
                            <img src={screen} alt="" className={'web'}
                                 style={{marginTop: isInfos ? 70 : null, marginLeft: width > 740 ? null : 10}}/>

                        </div>
                    }

                </div>

                {/*
                                <div className={'filtreNoir'}>
                    <div className={'enSavoirPlus'} onClick={() => setModalDetailFonct(true)}>
                        En savoir plus
                        <img src={leftBlanc} alt="" className={'leftBlanc'} />
                    </div>
                </div>
                */}

            </div>

        )
    }

    return (
        <div className={overflow ? 'page' : 'pageMenu'}>
            {!modalDetailFonct &&
                <Header setOverflow={setOverflow}/>
            }
            <div className={'fonctionnalites'}>
                <p className={'littleTitle'} style={{marginTop: width > 740 ? null : 0,}}>{t('translation.functionalities')}</p>
                <p className={'bigTitle'}>{t('translation.aSetOfToolsTailoredForYou')}</p>

                <MiddlePhoto src={width > 740 ? FirstFonctionnalites : littleFirstFonctionnalites} width={width}/>

                <p className={'littleTitle'} style={{fontSize: 16, marginTop: 130}}>OPUS</p>
                <p className={'bigTitle'} style={{fontSize: 32}}>
                    {t('translation.aUniqueEnvrionnmentTwoApplication')}
                </p>

                <p className={'corps'}>
                    {t('translation.corps1')}
                </p>


                <div className={'choiceOpus'}>
                    <div>
                        <div style={{display: 'flex'}}>
                            <div className={'containerNameChoice'} style={{marginRight: 80}}
                                 onClick={() => setOpusMobile(true)}>
                                <p className={'nameChoice'}>OPUS Mobile</p>
                            </div>

                            <div className={'containerNameChoice'} onClick={() => setOpusMobile(false)}>
                                <p className={'nameChoice'}>OPUS Web</p>
                            </div>

                        </div>
                        <div
                            className={'bar'}
                            style={{
                                width: opusMobile ? 100 : 82,
                                marginLeft: opusMobile ? 0 : 180,
                            }}
                        />
                    </div>

                </div>

                {opusMobile ?
                    <div className={'listeFonct'}>
                        <div className={'containerPartName'}>
                            <p className={'titlePart'}>{t('translation.forTheProfessionalAndTheirCleints')}</p>
                            <p className={'subtitlePart'}>{t('translation.subtitlefunctionalities')}</p>
                        </div>
                        <ItemFonctionnaliteMobile screen={projetsListiPhone} title={t('translation.titlefunctionalities1')}
                                                  subtitle={t('translation.subtitlefunctionalities1')}/>
                        <ItemFonctionnaliteMobile screen={firstDashboardIphone} title={t('translation.titlefunctionalities2')}
                                                  subtitle={t('translation.subtitlefunctionalities2')}/>
                        <ItemFonctionnaliteMobile screen={calendrieriPhone} title={t('translation.titlefunctionalities3')}
                                                  subtitle={t('translation.subtitlefunctionalities3')}/>
                        <ItemFonctionnaliteMobile screen={progressioniPhone} title={t('translation.titlefunctionalities4')}
                                                  subtitle={t('translation.subtitlefunctionalities4')}/>
                        <ItemFonctionnaliteMobile screen={financesiPhone} title={t('translation.titlefunctionalities5')}
                                                  subtitle={t('translation.subtitlefunctionalities5')}/>
                        <ItemFonctionnaliteMobile screen={messagerieiPhone} title={t('translation.titlefunctionalities6')}
                                                  subtitle={t('translation.subtitlefunctionalities6')}/>
                        <ItemFonctionnaliteMobile screen={photosiPhone} title={t('translation.titlefunctionalities7')}
                                                  subtitle={t('translation.subtitlefunctionalities7')}/>
                        <ItemFonctionnaliteMobile screen={fichiersiPhone} title={t('translation.titlefunctionalities8')}
                                                  subtitle={t('translation.subtitlefunctionalities8')}/>
                        <div className={'containerPartName'} style={{marginTop: 70}}>
                            <p className={'titlePart'}>{t('translation.exclusivelyForProfessionals')}</p>
                            <p className={'subtitlePart'}>{t('translation.corps2')}</p>
                        </div>
                        <ItemFonctionnaliteMobile screen={portfolioiPhone} title={t('translation.yourAgencyPortfolio')}
                                                  subtitle={t('translation.corps3')}/>
                        <ItemFonctionnaliteMobile screen={missioniPhone} title={t('translation.workingOrganisation')}
                                                  subtitle={t('translation.corps4')}/>

                    </div>

                    :

                    <div className={'listeFonct'}>
                        <div className={'containerPartName'}>
                            <p className={'titlePart'}>{t('translation.functionalitiesTailoredForPro')}</p>
                            <p className={'subtitlePart'}>{t('translation.corps5')}</p>
                        </div>
                        <ItemFonctionnaliteWeb screen={dashboardWeb} title={t('translation.titlefunctionalities9')}
                                               subtitle={t('translation.subtitlefunctionalities9')}/>
                        <ItemFonctionnaliteWeb screen={organisationWeb} title={t('translation.titlefunctionalities10')}
                                               subtitle={t('translation.subtitlefunctionalities10')}/>
                        <ItemFonctionnaliteWeb screen={progressionWeb} title={t('translation.titlefunctionalities11')}
                                               subtitle={t('translation.subtitlefunctionalities11')}/>
                        <ItemFonctionnaliteWeb screen={financesWeb} title={t('translation.titlefunctionalities12')}
                                               subtitle={t('translation.subtitlefunctionalities12')}/>
                        <ItemFonctionnaliteWeb screen={messagerieProjet} title={t('translation.titlefunctionalities13')}
                                               subtitle={t('translation.subtitlefunctionalities13')}/>
                        <ItemFonctionnaliteWeb screen={albumsPhotos} title={t('translation.titlefunctionalities14')}
                                               subtitle={t('translation.subtitlefunctionalities14')}/>
                        <ItemFonctionnaliteWeb screen={fichiersWeb} title={t('translation.titlefunctionalities15')}
                                               subtitle={t('translation.subtitlefunctionalities15')}/>
                        <ItemFonctionnaliteWeb screen={intervenantsWeb}
                                               title={t('translation.titlefunctionalities16')}
                                               subtitle={t('translation.subtitlefunctionalities16')}/>
                        <div style={{width: '100vw'}}/>
                        <ItemFonctionnaliteWeb multiple={true} screen1={portfolioWeb} screen2={collaborateursWeb}
                                               screen3={messagerieGlobaleWeb} title={t('translation.titlefunctionalities17')}
                                               subtitle={t('translation.subtitlefunctionalities17')}/>

                    </div>
                }


            </div>

            <PretAVousLancer/>

            <Footer/>

            <Modal isOpen={modalDetailFonct} className={'modalFonct'} overlayClassName="OverlayModal"
                   closeTimeoutMS={300}>
                <div style={{width: '100vw', display: 'flex', justifyContent: 'flex-end'}}>
                    <div onClick={() => {
                        setModalDetailFonct(false)
                        setOverflow(true)
                    }}>
                        <img src={cross} alt="" className={'cross'}/>
                    </div>

                </div>

            </Modal>


        </div>
    )
}
