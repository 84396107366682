import React from 'react';
import {useTranslation} from "react-i18next";

export default function LargePhoto({src, legende}) {
    const { t } = useTranslation();

    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <img src={src} alt="" className={'largePhoto'}/>

            {legende &&
                <p style={{
                    marginTop: -30,
                    color: "#fff",
                    marginRight: 10,
                    fontWeight: 600,
                    fontSize: 14
                }}>Hôtel de Bourrouilhan, 40500 Saint-Sever</p>
            }
        </div>
    )
}
