import React from 'react';

export default function middlePhoto({src, width, dimensions}) {
    return(
        <img src={src} alt="" className={'middlePhoto'} style={{
            width: width < 520 ? '100vw' : null,
            height: width < 520 ? '100vw' : null,
            objectFit: width < 520 ? 'cover' : 'contain',
        }}/>
    )
}
