import firebase from 'firebase/app';

export const firebaseOpus = firebase.initializeApp({
    apiKey: "AIzaSyBwCjS22RiKBSMwp55_npgbRsDDeabmt5Y",
    authDomain: "opus-f2c9b.firebaseapp.com",
    databaseURL: "https://opus-f2c9b-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "opus-f2c9b",
    storageBucket: "opus-f2c9b.appspot.com",
    messagingSenderId: "264700689625",
    appId: "1:264700689625:web:0236ef4da0dd230f1c92c8",
    measurementId: "G-KMS6TJ76FW"
});

