import React, {useState} from "react";
import ReactDOM from "react-dom";
import blueLaptopIcon from "../../Assets/Images/blueLaptopIcon.png";
import bluePhoneIcon from "../../Assets/Images/bluePhoneIcon.png";
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import {NavLink} from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import {useTranslation} from "react-i18next";

export default function LiensApplications() {
    const { t } = useTranslation();

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    if (width < 994) {
        return(
            <div>

            </div>
        )
    } else if (width > 994 && width < 1250) {
        return (
            <div className={'liensApplications'}>

            </div>
        )
    } else {
        return (
            <div className={'liensApplications'}>
                <p className={'textBleu'}>{t('translation.linkAppPricingTitle')}</p>
                <p className={'title'}>
                    {t('translation.linkAppPricingSubTitle1')} <br/>
                    {t('translation.linkAppPricingSubTitle2')}
                </p>
                <div style={{display: 'flex'}}>
                    <div className={'opusVersions'}>
                        <div style={{display: 'flex'}}>
                            <img src={bluePhoneIcon} alt="" className={'bluePhoneIcon'}/>
                            <p className={'nameVersion'}>OPUS Mobile ®</p>
                        </div>
                        <p className={'corps'}>{t('translation.linkAppPricingBody1')}
                        </p>

                        <BrowserRouter>
                            <ScrollToTop/>
                            <NavLink exact to="/opus-mobile">
                                <p onClick={this.refreshPage} className={'contactlink'}>{t('translation.discoverTheOpusMobileApp')}</p>
                            </NavLink>
                        </BrowserRouter>

                    </div>

                    <div className={'opusVersions'}>
                        <div style={{display: 'flex'}}>
                            <img src={blueLaptopIcon} alt="" className={'blueLaptopIcon'}/>
                            <p className={'nameVersion'}>OPUS Web ®</p>
                        </div>
                        <p className={'corps'}>{t('translation.linkAppPricingBody2')}
                        </p>

                        <BrowserRouter>
                            <ScrollToTop/>
                            <NavLink exact to="/opus-web">
                                <p onClick={this.refreshPage} className={'contactlink'}>{t('translation.discoverTheOpusDesktopApp')}</p>
                            </NavLink>
                        </BrowserRouter>

                    </div>
                </div>
            </div>
        )
    }

}

