import React from 'react';
import opusReasearch5 from "../Assets/Images/OPUS Reasearch/opusReasearch5.png";
import opusResearch7 from "../Assets/Images/OPUS Reasearch/opusResearch7.png";
import opusResearch6 from "../Assets/Images/OPUS Reasearch/opusResearch6.png";
import vrArchitect from "../Assets/Images/OPUS Patrimoine/vrArchitect.jpg";
import carcassonne from "../Assets/Images/OPUS Patrimoine/carcassonne.jpg";
import {useTranslation} from "react-i18next";

export default function TroisImages({patrimoine, width}) {
    const { t } = useTranslation();

    return(
        <div>
            <div className={'troisImage'}>
                <p style={{
                    fontSize: 26,
                    fontWeight: 600,
                    marginTop: 100,
                    marginBottom: 70,
                }}>{t('translation.whyOpus')} {patrimoine ? t('translation.heritage') :  t('translation.research')} ?</p>
                <div style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column'}}>
                    <div className={'image'}>
                        <img src={patrimoine ? carcassonne : opusReasearch5} alt="" className={'img'} style={{height: 300, objectFit: 'cover'}}/>
                        <p className={'title'}>{patrimoine ? t('translation.titleSubHeritage1') : t('translation.titleSubResearch1')}</p>
                        <p className={'corpsImage'}>{
                            patrimoine ?
                                t('translation.textSubHeritage1')
                            :
                                t('translation.textSubResearch1')
                        }</p>
                    </div>

                    <div className={'image'} style={{marginTop: width > 1200 ? null : 65}}>
                        <img src={opusResearch6} alt="" className={'img'} style={{height: 300, objectFit: 'cover'}}/>
                        <p className={'title'}>{patrimoine ? t('translation.titleSubHeritage2') : t('translation.titleSubResearch2')}</p>
                        <p className={'corpsImage'}>{
                            patrimoine ?
                                t('translation.textSubHeritage2')
                            :
                                t('translation.textSubResearch2')
                        }</p>
                    </div>

                    <div className={'image'} style={{marginTop: width > 1200 ? null : 65}}>
                        <img src={patrimoine ? vrArchitect : opusResearch7} alt="" className={'img'} style={{height: 300, objectFit: 'cover'}}/>
                        <p className={'title'}>{patrimoine ? t('translation.titleSubHeritage3') : t('translation.titleSubResearch3')}</p>
                        <p className={'corpsImage'}>{
                            patrimoine ?
                                t('translation.textSubHeritage3')
                            :
                                t('translation.textSubResearch3')
                        }
                        </p>
                    </div>
                </div>

            </div>
        </div>

    )
}
