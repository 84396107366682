import React, {useEffect, useState} from 'react';
import offerClientInfography from '../../Assets/Images/Home Page/offerClientInfography.png'
import offerClientPhoto from '../../Assets/Images/Home Page/offerClientPhoto.png'
import pourLesPassionnesOPUS from "../../Assets/Images/Home Page/pourLesPassionnesOPUS.png";
import {useTranslation} from "react-i18next";

export default function OfferYourClients() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={'offerYourClient'}>

            {width < 1300 ?

                <div style={{flexDirection: width < 1300 ? 'column' : 'row', backgroundColor: '#FFF', marginTop: 100, width: '90vw', borderRadius: 20, marginBottom: 0}}>
                    <div className={'leftPart'} style={{width: width < 1300 ? '90vw' : null, marginTop: 50, marginBottom: 20}}>

                        <p className={'title'} style={{fontSize: 35, maxWidth: '75vw', letterSpacing: -0.5, textAlign: 'center'}}>{t('translation.offerYour')} <span style={{color: '#5c70f2'}}>{t('translation.yourClients')}</span> {t('translation.bestMonitoringExperience')}</p>

                    </div>

                    <div className={'photoPart'} style={{width: '90vw', height: 400,}}>
                        <div className={'hoverPhoto'} style={{width: '80vw', height: 400}}>
                            <img src={offerClientInfography} alt="" className={'offerClientInfography'} style={{width: '80vw', marginLeft: '5vw', marginBottom: 20}}/>
                        </div>

                        <img src={offerClientPhoto} alt="" className={'offerClientPhoto'} style={{width: '80vw', borderRadius: 20, height: 400, marginLeft: '5vw'}}/>

                    </div>

                    <div className={'leftPart'} style={{width: width < 1300 ? '90vw' : null, marginTop: 0, display: 'flex', alignItems: 'center'}}>

                        <p className={'corps'} style={{marginTop: 35, maxWidth: '75vw', marginBottom: 30, fontSize: 22, lineHeight: 1.3, fontWeight: '600'}}>
                            {t('translation.textHomePage2')}
                        </p>

                    </div>

                </div>

                :

                <div style={{display: 'flex', flexDirection:'row'}}>
                    <div className={'leftPart'} style={{width: width < 1300 ? '100vw' : null, height: width < 1300 ? 350 : 750, justifyContent: width < 1300 ? 'flex-end' : 'center'}}>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className={'degradeOfferClientLeft'} style={{zIndex: 1}}/>

                            <p className={'title'} style={{fontSize: 35, maxWidth: '75vw', letterSpacing: -0.5, zIndex: 20}}>{t('translation.offerYour')} <span style={{color: '#5c70f2'}}>{t('translation.yourClients')}</span> {t('translation.theBest')} <br/> {t('translation.monitoringExperience')}</p>

                            <div className={'bar'}/>

                            <p className={'corps'} style={{marginTop: 20, maxWidth: '75vw', marginBottom: 30, fontSize: 22, lineHeight: 1.3, fontWeight: '600'}}>
                                {t('translation.textHomePage2')} </p>
                        </div>


                    </div>

                    <div className={'photoPart'} style={{width: width < 1300 ? '100vw' : null, height: width < 1300 ? 600 : null}}>
                        <div className={'hoverPhoto'} style={{width: width < 1300 ? '100vw' : null, height: width < 1300 ? 600 : null}}>
                            <img src={offerClientInfography} alt="" className={'offerClientInfography'} style={{width: width < 1300 ? '100vw' : null}}/>
                        </div>

                        <img src={offerClientPhoto} alt="" className={'offerClientPhoto'} style={{width: width < 1300 ? '90vw' : null, marginLeft: width < 1300  ? '5vw' : null, borderRadius: width < 1300 ? 20 : 0, height: width < 1300 ? 600 : null}}/>
                    </div>
                </div>

            }



        </div>
    )
}
