import React from 'react';
import '../_choosePlanPage.scss';
import {useTranslation} from "react-i18next";

export default function HeaderChoosePlanPage() {
	const { t } = useTranslation();
	return (
		<>
			<p className={'planTarifs'}>{t('translation.plansAndPricing')}</p>
			<p className={'mainTitleTarifs'}>{t('translation.titlePricing1')}</p>
		</>
	);
}
