import React from 'react';
import {useTranslation} from "react-i18next";

export const getPlansList = (t) =>
	[
	{
		title: 'OPUS',
		subtitle: t('translation.subTitlePrincing2'),
		checks: [t('translation.check1Princing2'), t('translation.training'), t('translation.conserved10y')],
		price: '28',
		subtitlePrice: t('translation.subTitlePrince'),
	},
	{
		title: 'OPUS Services',
		subtitle: t('translation.subTitlePrincing3'),
		checks: [t('translation.allOPUS'), t('translation.consultantDedicated'), t('translation.participationOPUSResearch')],
		price: '48',
		subtitlePrice: t('translation.subTitlePrince'),
	},
];

export const getOptionsList = (t) => [
	{
		title: t('translation.titlePart1List'),
		options: [
			{
				title: t('translation.titlePart1Point1'),
				opusShare: t('translation.unlimited'),
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart1Point2'),
				opusShare: t('translation.opusSharePoint2'),
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart1Point3'),
				opusShare: t('translation.oneYear'),
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart1Point4'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.titlePart1Point5'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
		],
	},

	{
		title: t('translation.titlePart2List'),
		options: [
			{
				title: t('translation.titlePart2Point1'),
				opusShare: 'true',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.titlePart2Point2'),
				opusShare: t('translation.opusShare2Point2'),
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart2Point3'),
				opusShare: t('translation.opusShare3Point3'),
				opusSharePlus: t('translation.unlimited'),
			},
			{
				title: t('translation.titlePart2Point4'),
				opusShare: 'true',
				opusSharePlus: 'true',
			},
		],
	},

	{
		title: t('translation.OPUSAssist'),
		options: [
			{
				title: t('translation.classiqueAssist'),
				opusShare: 'true',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.premiumAssist'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.priority'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.intercomAssist'),
				opusShare: 'true',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.phoneAssist'),
				opusShare: t('translation.onDemand'),
				opusSharePlus: 'true',
			},
			{
				title: t('translation.visioAssist'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
		],

	},

	{
		title: t('translation.trainingAndIntegration'),
		options: [
			{
				title: t('translation.onboardingInApp'),
				opusShare: 'true',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.oneHourTraining'),
				opusShare: 'true',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.trainingProcess'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.onSiteIntegration'),
				opusShare: 'false',
				opusSharePlus: t('translation.onDevis'),
			},
			{
				title: t('translation.contributorsTraining'),
				opusShare: t('translation.onDemand'),
				opusSharePlus: 'true',
			},
		],

	},

	{
		title: t('translation.helpAndInnovation'),
		options: [
			{
				title: t('translation.dedicatedConsultant'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.monthlyMeeting'),
				opusShare: 'false',
				opusSharePlus: 'true',
			},
			{
				title: t('translation.collaborativeProposalProject'),
				opusShare: 'false',
				opusSharePlus: t('translation.thirtyFive'),
			},
			{
				title: t('translation.opusResearchRd'),
				opusShare: 'false',
				opusSharePlus: t('translation.onProposal'),
			},
			{
				title: t('translation.betaFeatures'),
				opusShare: 'false',
				opusSharePlus: t('translation.onProposal'),
			},
		],
	},
];
