import React, {useState} from "react";
import locationIcon from "../Assets/Images/locationIcon.png";
import languageIcon from "../Assets/Images/languageIcon.png";
import {useSSR, useTranslation} from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();

    const [width, setWidth] = useState(1200);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    if (width <= 1100) {
        return (
            <div className={'miniFooter'}>
                <div className={'container'}>

                    <div style={{display: 'flex'}}>
                        <div className={'part'}>
                            <p className={'Opus'}>OPUS</p>
                            <div style={{display: 'flex'}}>
                                <img src={locationIcon} alt="" className={'locationIcon'}/>
                                <p className={'designant'}>France</p>
                            </div>

                            <div style={{display: 'flex', marginTop: -20}}>
                                <img src={languageIcon} alt="" className={'languageIcon'}/>
                                <p className={'designant'}>{t('translation.french')}</p>
                            </div>
                        </div>

                        <div className={'part'} style={{marginTop: 0}}>

                            <p className={'titleGroup'}>{t('translation.opusEnvironment')}</p>
                            <a href="/opus-mobile">
                                <p className={'linkGroup'}>OPUS Mobile</p>
                            </a>

                            <a href="/opus-web">
                                <p className={'linkGroup'}>OPUS Web</p>
                            </a>

                            <a href="/fonctionnalites">
                                <p className={'linkGroup'}>{t('translation.functionalities')}</p>
                            </a>


                            <div style={{height: 25}}/>

                            <p className={'titleGroup'}>{t('translation.opusEnvironment')}</p>
                            <a href="/opus-research">
                                <p className={'linkGroup'}>{t('translation.opusResearch')}</p>
                            </a>

                            <a href="/opus-patrimoine">
                                <p className={'linkGroup'}>{t('translation.opusHeritage')}</p>
                            </a>


                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div className={'part'}>
                            <p className={'titleGroup'}>{t('translation.integration')}</p>
                            <a href="/opus-client">
                                <p className={'linkGroup'}>{t('translation.projectClient')}</p>
                            </a>

                            <a href="/opus-professionnel">
                                <p className={'linkGroup'}>{t('translation.professionalOf')}<br/>{t('translation.sector')}</p>
                            </a>


                            <div style={{height: 25}}/>

                            <p className={'titleGroup'}>{t('translation.ressources')}</p>
                            <a href="/a-propos-de-nous">
                                <p className={'linkGroup'}>{t('translation.aboutUs')}</p>
                            </a>

                            <a href="/tarifs">
                                <p className={'linkGroup'}>{t('translation.pricing')}</p>
                            </a>

                            <a href="/contact">
                                <p className={'linkGroup'}>{t('translation.contactHelp')}</p>
                            </a>

                            <a href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f?pvs=74" target="_blank">
                                <p className={'linkGroup'}>{t('translation.confidentiality')}</p>
                            </a>

                            <a href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f?pvs=74" target="_blank">
                                <p className={'linkGroup'}>{t('translation.generalTermsAndConditionOfUse')}</p>
                            </a>

                            <a href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f?pvs=74" target="_blank">
                                <p className={'linkGroup'}>{t('translation.generalTerms')}</p>
                            </a>

                        </div>

                    </div>

                    <div className={'part'} style={{marginTop: -100, width: 300}}>
                        <p className={'rights'}>© OPUS, 2022 All right reserved.</p>
                    </div>

                </div>

            </div>
        )
    } else {
        return (

            <div>

                <div className={'bigFooter'}>

                    <div className={'container'}>

                        <div className={'part'}>
                            <a href="/">
                                <p className={'Opus'}>OPUS</p>
                            </a>

                            <div style={{display: 'flex'}}>
                                <img src={locationIcon} alt="" className={'locationIcon'}/>
                                <p className={'designant'}>France</p>
                            </div>

                            <div style={{display: 'flex', marginTop: -20}}>
                                <img src={languageIcon} alt="" className={'languageIcon'}/>
                                <p className={'designant'}>{t('translation.french')}</p>
                            </div>

                            <p className={'rights'}>© OPUS, 2022 All right reserved.</p>

                        </div>

                        <div className={'part'}>


                            <p className={'titleGroup'}>{t('translation.opusEnvironment')}</p>

                            <a href="/opus-mobile">
                                <p className={'linkGroup'}>OPUS Mobile</p>
                            </a>

                            <a href="/opus-web">
                                <p className={'linkGroup'}>OPUS Web</p>
                            </a>

                            <div style={{height: 25}}/>

                            <p className={'titleGroup'}>{t('translation.opusEnvironment')}</p>

                            <a href="/opus-research">
                                <p className={'linkGroup'}>{t('translation.opusResearch')}</p>
                            </a>

                            <a href="/opus-patrimoine">
                                <p className={'linkGroup'}>{t('translation.opusHeritage')}</p>
                            </a>

                        </div>

                        <div className={'part'}>

                            <p className={'titleGroup'}>Intégration</p>

                            <a href="/opus-client">
                                <p className={'linkGroup'}>{t('translation.projectClient')}</p>
                            </a>

                            <a href="/opus-professionnel">
                                <p className={'linkGroup'}>{t('translation.sectorProfessional')}</p>
                            </a>


                        </div>

                        <div className={'part'}>

                            <p className={'titleGroup'}>{t('translation.ressources')}</p>

                            <a href="/a-propos-de-nous">
                                <p className={'linkGroup'}>{t('translation.aboutUs')}</p>
                            </a>

                            <a href="/tarifs">
                                <p className={'linkGroup'}>{t('translation.pricing')}</p>
                            </a>

                            <a href="/contact">
                                <p className={'linkGroup'}>{t('translation.contactHelp')}</p>
                            </a>

                            <a href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f?pvs=74" target="_blank">
                                <p className={'linkGroup'}>{t('translation.confidentiality')}</p>
                            </a>

                            <a href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f?pvs=74" target="_blank">
                                <p className={'linkGroup'}>{t('translation.generalTermsAndConditionOfUse')}</p>
                            </a>

                            <a href="https://opusoft.notion.site/Conditions-et-Confidentialit-ff3665f3413242acac9d747cde6d9f8f?pvs=74" target="_blank">
                                <p className={'linkGroup'}>{t('translation.generalTerms')}</p>
                            </a>


                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

