import React, {useState, useEffect} from 'react';
import iPhoneWithLaptop from '../../Assets/Images/iPhoneWithLaptop.png'
import opusWithIcons from '../../Assets/Images/opusWithIcons.png'
import miniOpusWithIcons from '../../Assets/Images/miniOpusWithIcons.png'
import miniIPhoneWithLaptop from '../../Assets/Images/miniIPhoneWithLaptop.png'
import leftBlanc from '../../Assets/SVGS/leftBlanc.svg'
import leftBleuRandom from '../../Assets/SVGS/leftBleuRandom.svg'
import checkBleuHomePage from '../../Assets/Images/Home Page/checkBleuHomePage.svg'
import Header from "../../components/Header/Header";
import { useTranslation } from 'react-i18next';

export default function HomePageSecondPart({setOverflow}) {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [petit, setPetit] = useState(width < 1300)

    return(
        <div className={'homePageSecondPart'}>

            <div style={{zIndex: 10}}>
                <Header setOverflow={setOverflow} opus={true}/>
            </div>

            <div style={{display: 'flex', flexDirection: width > 900 ? null : 'column', width: '100vw', justifyContent: 'center', alignItems: width > 900 ? null : 'center'}}>

                <div className={'textPart'} style={{zIndex: 2}}>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: width > 900 ? 'flex-start' : 'flex-start'}}>
                        <p style={{
                            fontSize: width < 900 ? 20 : 21,
                            fontWeight: '700',
                            color: '#5f74fc',
                            marginBottom: 10,
                            marginTop: width < 900 ? 60 : 150,
                        }}>{t('translation.archiDecoMOA')}</p>
                        <h2 className={width > 900 ? 'titleBlack' : 'littleTitleBlack'} style={{
                            fontSize: 55,
                            marginTop: width < 900 ? 20 : 0,
                            marginBottom: width < 900 ? 0 : null
                        }}>
                            {t('translation.revolutionizeMonitoring')} <span style={{color: '#5f74fc'}}>{t('translation.yourProjects')}</span>
                        </h2>

                        <div className={'checksBleu'} style={{marginTop: width < 900 ? 60 : null, marginBottom: width < 900 ? 80 : null}}>
                            <div style={{display: 'flex'}}>
                                <img src={checkBleuHomePage} alt=""/>
                                <p>{t('translation.point11')}<span style={{color: '#000', fontWeight: '700'}}>{t('translation.point12')}</span> </p>
                            </div>

                            <div style={{display: 'flex'}}>
                                <img src={checkBleuHomePage} alt=""/>
                                <p><span style={{color: '#000', fontWeight: '700'}}>{t('translation.point21')}</span> {t('translation.point22')}</p>
                            </div>

                            <div style={{display: 'flex'}}>
                                <img src={checkBleuHomePage} alt=""/>
                                <p>{t('translation.point31')} <span style={{color: '#000', fontWeight: '700'}}>{t('translation.point32')}</span> </p>
                            </div>
                        </div>


                        <div style={{display: 'flex', flexDirection: width > 900 ? 'row' : 'column', alignItems: width < 900 ? 'center' : 'flex-start', width: width < 900 ? '85vw' : null, marginTop: 30,}}>
                            {width > 600 ?
                                <a href="https://opusoft.app/OnBoarding" target="_blank">
                                    <div className={width > 900 ? 'blueButton' : 'littleBlueButton'} style={{
                                        color: '#fff',

                                    }}>
                                        {t('translation.startNow')}
                                        <img src={leftBlanc} alt="" className={'leftBleu'}/>
                                    </div>
                                </a>
                                :
                                <a href="https://apps.apple.com/fr/app/opus-architecture-design/id1591807716" target="_blank">
                                    <div className={width > 900 ? 'blueButton' : 'littleBlueButton'} style={{
                                        color: '#fff',
                                    }}>
                                        {t('translation.startNow')}
                                        <img src={leftBlanc} alt="" className={'leftBleu'}/>
                                    </div>
                                </a>
                            }


                            <a href="/contact">
                                <div className={width > 900 ? 'blueButton' : 'littleBlueButton'} style={{backgroundColor: 'rgba(255,255,255,0)', marginTop: width > 900 ? null : 10}}>
                                    {t('translation.contactTeam')}
                                    <img src={leftBleuRandom} alt="" className={'leftBleu'}/>
                                </div>
                            </a>

                        </div>
                    </div>

                </div>

                <div className={'iconsPart'} style={{
                    marginLeft: width < 1400 ? width < 1300 ? width < 1250 ? width < 900 ? null : -180 : -100 : -50 : 30,
                    backgroundColor: '#F4F8FC',
                    height: 700,
                    marginTop: width < 900 ? -150 : -200,
                    paddingTop: width < 900 ? 140 : 290,
                    paddingBottom: width < 900 ? 100 : 0,
                    width: width < 900 ? 1000 : 900,
                    paddingLeft: width < 900 ? 0 : 100,
                    borderRadius: 40000,
                    marginRight: width < 1400 ? width < 1150 ? width < 1150 ?  width < 900 ? null : -600 : -500 : -400 : -400,
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: width < 900 ? 'center' : 'flex-start',
                    marginBottom: width < 900 ? -150 : null

                }}>
                    <img src={width > 900 ? opusWithIcons : miniOpusWithIcons} alt="" className={'opusWithIcons'}/>
                </div>
            </div>

            <div style={{zIndex: 2, height: width < 900 ? 700 : 975}}>
                <img
                    src={width > 900 ? iPhoneWithLaptop: miniIPhoneWithLaptop}
                    alt=""
                    className={'iPhoneWithLaptop'}
                    style={{
                        marginTop: width < 900 ? -60 : null,
                        width: width < 901 ? 1300 : null
                    }}
                />
            </div>


        </div>
    )
}
