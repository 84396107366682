import React, {useState, useEffect} from 'react';
import Header from "../../components/Header/Header";
import TroisImages from "../../components/TroisImages";
import Footer from "../../components/Footer";
import ContentOpusResearch from "./ContentOpusResearch";

export default function OpusReasearch() {

    const [overflow, setOverflow] = useState(true)

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'}>
            <Header setOverflow={setOverflow}/>

            <div>
                <ContentOpusResearch width={width} />
            </div>

            <TroisImages width={width}/>
            <div style={{height: 150}}/>
            <Footer/>


        </div>
    )
}
