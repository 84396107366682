import React, {useState} from 'react';
import Header from "../../components/Header/Header";
import ContentPolitiqueConfidentialite from "./ContentPolitiqueConfidentialite";
import ContentConditionsGenerales from "./ContentConditionsGenerales";

export default function ConditionsGeneralesVente() {

    const [overflow, setOverflow] = useState(true)

    return(
        <div className={'page'}>

            <div className={'legal'}>
                <p className={'littleTitle'}>CONDITIONS GÉNÉRALES DE VENTE</p>
                <p className={'title'}>
                    Découvrez nos conditions générales <br/>
                    de ventes
                </p>
                <p className={'miseAJour'}>Mis à jour le : 20 juin 2022</p>

                <ContentConditionsGenerales/>
            </div>

            <Header setOverflow={setOverflow}/>

        </div>
    )
}
