import React from "react";
import ReactDOM from "react-dom";

class ContentPolitiqueConfidentialite extends React.Component {
    constructor() {
        super();

        this.state = {
            height: 0,
            width: 0,
            isLoggedIn: false,
            menuLittleScreen: false,
        };

        window.addEventListener("resize", this.update);
    }

    componentDidMount() {
        this.update();
    }

    update = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    refreshPage = () => {
        setTimeout( () => {
            window.location.reload(false);
        }, 200);
    }

    render(props) {

        if (this.state.width < 101) {
            return(
                <div>

                </div>
            )
        } else if (this.state.width > 101 && this.state.width < 100) {
            return (
                <div>

                </div>
            )
        } else {
            return (

                <div className={'contentPolitiqueConfidentialite'}
                style={{
                    paddingLeft: this.state.width < 1000 ? this.state.width < 800 ? this.state.width < 800 ? 40 : 60 : 100 : 140,
                    paddingRight: this.state.width < 1000 ? this.state.width < 800 ? this.state.width < 800 ? 40 : 60 : 100 : 140,
                }}>
                    <p className={'title'}>Introduction</p>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>Dans le cadre de notre mission consistant à proposer à l’ensemble des professionnels de l’architecture et du design ainsi qu’a leurs clients, des
                        outils innovants de gestion et suivi de projets ; nous recueillons et utilisons des informations sur vous :
                        <br /><br />
                        Les architectes, designer, maîtres d’oeuvre et plus largement les professionnels qui utilisent nos services au travers des applications web et
                        mobile OPUS.
                        <br /><br />
                        Les clients de ces professionnels sus-cités à qui est permis un accès à l’application mobile OPUS.
                        <br /><br />
                        Les utilisateurs non catégorisés qui font l’usage des application mobile et/ou web OPUS.
                        <br /><br />
                        Cette politique de confidentialité vous aidera à mieux comprendre comment nous collectons, utilisons et partageons vos informations personnelles.
                        Si nous modifions nos pratiques de confidentialité, nous pouvons mettre à jour cette politique de confidentialité. Si des changements sont important,
                        nous vous en informerons (par exemple, via l’interface administrateur OPUS ou par e-mail).</p>

                    <p className={'title'}>Nos valeurs</p>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>La confiance est le fondement de la plateforme OPUS. L’émergence de nos services est née de notre volonté, entre autre, de renforcer la confiance
                        entre les professionnels de l’architecture, du design, de la maîtrise d’oeuvre et leurs clients. Valeur Ô combien nécessaire à cultiver dans la réalisation
                        de projet ou l’investissement financier, temporel et émotionnel est souvent grand. Pour que cette confiance soit possible, elle inclut de nous faire
                        confiance concernant le traitement de vos informations. Trois valeurs principales nous guident dans le développement de nos produits et services.
                        Celles-ci devraient vous aider à mieux comprendre comment nous considérons vos informations et votre vie privée. </p>

                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} className={'numeros'}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>1</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Vos informations vous appartiennent</p>

                            <p className={'corps'}>Nous analysons attentivement les types d’informations dont nous avons besoin pour fournir nos services, et nous essayons de limiter les informations
                                que nous collectons à celles dont nous avons réellement besoin. Dans la mesure du possible, nous supprimons ou rendons anonymes ces informations
                                lorsque nous n’en avons plus besoin. Lors de la conception et de l’amélioration de nos produits, nos ingénieurs travaillent en étroite collaboration avec
                                nos équipes responsables de la protection de la vie privée et de la sécurité pour créer en tenant compte du caractère confidentiel de ces informations.
                                Durant ces processus, notre principe directeur est que les renseignements vous concernant vous appartiennent, et notre objectif consiste à n’utiliser vos
                                informations qu’à votre avantage.
                            </p>
                        </div>

                    </div>

                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex'}} className={'numeros'}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>2</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Nous protégeons vos informations contre les autres</p>

                            <p className={'corps'}>Si un tiers demande à avoir accès à vos informations personnelles, nous refuserons de les partager à moins que vous nous en accordiez la permission
                                ou que nous en ayons l’obligation légale. Lorsque nous sommes légalement tenus de partager vos informations personnelles, nous vous en informerons
                                à l’avance, sauf si la loi nous l’interdit.
                            </p>
                        </div>

                    </div>

                    <div style={{display: 'flex'}} className={'numeros'}>
                        <div style={{display: 'flex'}}>
                            <p className={'numero'} style={{
                                marginLeft: this.state.width < 1000 ? 0 : null,
                                marginTop: 45}}>3</p>
                            <div className={'barBleue'} style={{marginTop: 45}}/>
                        </div>

                        <div>
                            <p className={'subtitleValeurs'}>Nous traitons vos données d’utilisation avec la même attention que vos données personnelles</p>

                            <p className={'corps'}>Au delà des informations personnelles que vous nous délivrez : nom, prénom, photo de profil, mot de passe, etc… ; nous sommes engagés à ce que
                                la confidentialité des données issues de votre utilisation de nos service soit tout autant garantie. Les informations financières, géographiques,
                                photographiques, les fichiers et rendez-vous que vous créer et ajoutez au sein de nos applications constituent vos données d’utilisation. Nous traitons
                                ces dernières dans le cadre unique de votre expérience utilisateurs ; elles ne font l’objet d’aucun traitement extérieurs à cette seule activité.
                            </p>
                        </div>
                    </div>

                    <p className={'title'}>Pourquoi nous traitons vos informations</p>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>Nous traitons généralement vos informations dans le cadre de vos interactions (création, consultation, modification) avec le contenu au seins de nos applications et
                        auprès des autres utilisateurs. Par exemple, nous lions vos informations personnelles aux projets qui vous sont attribués pour que vous puissiez y avoir accès sur votre
                        interface utilisateur. Le droit européen auquel nous sommes soumis encadre les raisons nous permettant d’utiliser vos données, il appelle ces raisons des « intérêts
                        légitimes ». Ces « intérêts légitimes » comprennent ce qui suit :
                    </p>

                    <div>
                        <p>prévenir les risques de fraude</p>
                    </div>

                        <div>
                            <p>répondre aux questions ou fournir d’autres types d’assistance</p>
                        </div>

                        <div>
                            <p>fournir des produits et services et les améliorer</p>
                        </div>

                        <div>
                            <p>fournir des rapports et des analyses</p>
                        </div>

                        <div>
                            <p>tester des fonctionnalités ou des services supplémentaires</p>
                        </div>

                        <div>
                            <p>offrir une assistance pour le marketing, la publicité ou d’autres communications.</p>
                        </div>

                    <p className={'corps'}>Nous traitons uniquement les informations personnelles dans le cadre de ces « intérêts légitimes » après avoir examiné les risques potentiels pour votre vie privée.
                        Par exemple, en assurant la transparence limpide de nos pratiques de confidentialité, en vous offrant le contrôle de vos informations personnelles le cas échéant,
                        en limitant les informations que nous conservons, en limitant ce que nous faisons avec vos informations, à qui nous les envoyons, combien de temps nous les
                        conservons ou les mesures techniques que nous utilisons pour les protéger.
                        <br /><br />
                        L’une des façons de vous aider à utiliser OPUS consiste à utiliser des techniques comme l’« apprentissage automatique » (Le droit européen appelle
                        cela une « prise de décision automatisée ») pour nous aider à améliorer nos services. Lorsque nous utilisons l’apprentissage automatique, soit : (1) nous avons
                        toujours un être humain impliqué dans le processus (et nous ne sommes donc pas entièrement automatisés) ; ou (2) nous utilisons l’apprentissage automatique
                        d’une façon qui n’a pas d’implications importantes sur la confidentialité (par exemple, réorganiser l’apparence des applications lorsque vous visitez l’App Store).
                    </p>

                    <p className={'title'}>Vos droits sur vos informations</p>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>Nous sommes convaincus que vous devez pouvoir accéder à vos propres informations personnelles et les contrôler, peu importe où vous vivez. Selon la manière dont
                        vous utilisez OPUS, vous pouvez avoir le droit de demander l’accès à vos informations personnelles, de les corriger, modifier, supprimer, transférer vers un autre
                        fournisseur de services, de les restreindre ou de vous opposer à certaines utilisations de celles-ci (par exemple, le marketing direct). Nous ne vous facturerons pas
                        des frais plus importants et nous ne vous fournirons pas un niveau de service différent si vous appliquez vos droits de confidentialité. Concernant vos données
                        d’utilisation, à savoir l’ensemble des informations que vous produisez au sein de nos application et au cours de leur utilisation ; vous pouvez avoir le droit de les
                        modifier, de les supprimer mais nous ne permettons pas le transfert de ces dernières vers un autre fournisseur de service car leur création dépend de nos services.
                        <br /><br />
                        Si vous êtes client d’un professionnel utilisant les applications OPUS et que vous souhaitez exercer ces droits sur les informations relatives à votre utilisation, vous
                        devez contacter directement le professionnel avec lequel vous avez été en contact. Nous ne sommes qu’un sous-traitant qui les représente et ne pouvons pas décider
                        comment traiter leurs informations. Nous allons, bien entendu, aider nos professionnels à répondre à ces demandes en leur fournissant les outils pour le faire et en
                        répondant à leurs questions.
                        <br /><br />
                        Si vous êtes un utilisateur professionnel ou un employé d’OPUS ou une autre personne avec laquelle OPUS a une relation directe, veuillez soumettre votre
                        demande d’accès aux données de personnes concernées sur notre portail en ligne. Veuillez noter que, si vous nous envoyez une demande relative à vos données
                        personnelles, nous devons vérifier votre identité avant de pouvoir vous répondre. Pour ce faire, nous pouvons faire appel à une partie tierce pour collecter et vérifier
                        les documents d’identification.
                        <br /><br />
                        Si vous n’êtes pas satisfait de notre réponse à une demande, vous pouvez nous contacter pour résoudre le problème. Vous avez également le droit de contacter à
                        tout moment votre autorité locale de protection des données ou de confidentialité.
                    </p>

                    <p className={'title'}>Où nous envoyons vos informations</p>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>Nous sommes une entreprise française, mais nous travaillons avec des données sur des personnes et les traitons dans le monde entier. Pour exploiter notre
                        entreprise, nous pouvons envoyer vos informations personnelles en dehors de votre État, province ou pays, y compris aux États-Unis. Ces données peuvent être
                        soumises aux lois des pays où nous les envoyons. Lorsque nous envoyons vos informations au-delà des frontières, nous prenons des mesures pour les protéger et
                        nous essayons de ne les envoyer que vers des pays qui ont des lois strictes en matière de protection des données.</p>

                    <p className={'corps'}>Transferts hors de l’Europe et de la Suisse</p>

                    <p className={'corps'}>Si vous êtes en Europe ou en Suisse, vos informations personnelles sont contrôlées par notre filiale française, OPUS France. Vos informations sont ensuite envoyées
                        à d’autres emplacements OPUS et à des fournisseurs de services, qui peuvent être situés dans d’autres régions, y compris les États-Unis. Lorsque nous envoyons
                        vos informations personnelles en dehors de l’Europe, nous le faisons conformément au droit européen. Ce transfert d’information est avant tout nécessaire pour
                        permettre la meilleure connectivité entre nos utilisateurs internationaux.
                        <br /><br />
                        Enfin, alors que nous faisons de notre mieux pour protéger vos informations personnelles, nous pouvons parfois être légalement tenus de les divulguer (par exemple,
                        si nous recevons une ordonnance judiciaire valide). Pour en savoir plus sur la manière dont nous répondons à ces commandes, veuillez consulter nos directives pour
                        les demandes juridiques.
                    </p>

                    <p className={'corps'}>Comment nous protégeons vos informations</p>

                    <p className={'corps'}>Nos équipes travaillent sans relâche pour protéger vos informations, ainsi que pour assurer la sécurité et l’intégrité de notre plateforme. Nous faisons également
                        appel à des vérificateurs indépendants, qui évaluent la sécurité de notre stockage de données et des systèmes qui traitent les informations financières. Toutefois,
                        nous savons tous qu’il n’existe aucune méthode de transmission sur Internet, et aucune méthode de stockage électronique, qui soit entièrement sécurisée. Par
                        conséquent, nous ne pouvons pas garantir la sécurité absolue de vos informations personnelles. Vous pouvez trouver plus d’informations sur nos mesures de
                        sécurité à https://www.shopify.com/security.
                    </p>

                    <p className={'title'}>Comment vous pouvez nous contacter</p>

                    <div className={'barTitle'} style={{width: 200}}/>

                    <p className={'corps'}>Si vous souhaitez vous informer, poser une question ou soumettre une réclamation sur la manière dont nous traitons vos informations personnelles, veuillez contacter
                        assistance OPUS, ou nous envoyer un courrier à l’une des adresses ci-dessous. Si vous souhaitez soumettre une demande juridiquement contraignante pour avoir
                        accès aux informations personnelles de quelqu’un d’autre (par exemple, si vous avez une assignation à comparaître ou une ordonnance du tribunal), veuillez consulter
                        nos Directives pour les demandes juridiques.
                        <br /><br /><br /><br />
                        OPUS France Inc.
                        <br />
                        Chief Privacy Officer
                        <br />
                        3 avenue du général Mangin
                        <br />
                        Rez-de-chaussée,
                        <br />
                        Versailles, 78000
                        <br />
                        France
                    </p>

                </div>
            )
        }
    }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<ContentPolitiqueConfidentialite />, rootElement);

export default ContentPolitiqueConfidentialite;
