import React, {useState, useEffect} from 'react';
import planSendImage from '../../Assets/Images/Home Page/planSendImage.png'
import plan from '../../Assets/Images/Home Page/plan.png'
import uneFois from '../../Assets/Images/Home Page/uneFois.svg'
import cinqFois from '../../Assets/Images/Home Page/cinqFois.svg'
import cinqFoisRouge from '../../Assets/Images/Home Page/cinqFoisRouge.svg'
import huitFois from '../../Assets/Images/Home Page/huitFois.svg'
import huitFoisRouge from '../../Assets/Images/Home Page/huitFoisRouge.svg'
import unusefulComFr from '../../Assets/Images/Home Page/unusefulComFr.svg'
import unusefulComFrRouge from '../../Assets/Images/Home Page/unusefulComFrRouge.svg'
import planSendImageRouge from '../../Assets/Images/Home Page/planSendImageRouge.svg'
import {useTranslation} from "react-i18next";


export default function HomePageThirdPart() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }



    return(
        <div className={'homePageThirdPart'} style={{paddingTop: width > 740 ? 200 : 100, paddingBottom: 160, marginTop: width > 1200 ? -442 : -350}}>

            <div style={{display: 'flex', flexDirection: 'column', width: '100vw', justifyContent: 'center', alignItems: 'center', marginTop: width < 1300 ? -30 : -60}}>

                <div className={'textPart'} style={{
                    display: 'flex',
                    flexDirection: width > 740 ? 'row' : 'column',

                }}>

                    <div style={{display: 'flex', flexDirection: 'column', width: 700}}>
                        <p style={{
                            fontSize: 18,
                            fontWeight: '700',
                            color: '#5f74fc',
                            marginBottom: 10,
                        }}>{t('translation.todayMajorIssue')}</p>

                        <p className={width > 740 ? 'titleBlack' : 'littleTitleBlack'} style={{
                            fontSize: width > 1300 ? 55 : 50,
                            marginTop: 0,

                        }}>
                            {t('translation.whyAsMany')} <br/>   {t('translation.communication')}
                        </p>

                        <p className={width > 740 ? 'titleBlack' : 'littleTitleBlack'} style={{
                            fontSize: 25,
                            marginTop: 15,
                            letterSpacing: -1,
                            lineHeight: width > 1300 ? null : 1,

                        }}>
                            {t('translation.part1')} <span style={{color: '#5f74fc'}}>{t('translation.part2')}</span> {t('translation.part3')}
                        </p>

                        {width > 1300 ?
                            <div style={{display: 'flex', gap: 70, marginTop: 60}}>
                                <div className={'xFois'}>
                                    <img src={uneFois} alt=""/>
                                    <h2>{t('translation.oneTime')}</h2>
                                    <p>{t('translation.graph1a')} <br/> {t('translation.graph1b')}</p>
                                </div>


                                <div className={'xFois'}>
                                    <img src={cinqFoisRouge} alt=""/>
                                    <h2 style={{color:'#FB7878'}}>{t('translation.fiveTime')}</h2>
                                    <p>{t('translation.graph1a')}<br/> {t('translation.graph2b')}</p>
                                </div>

                                <div className={'xFois'}>
                                    <img src={huitFoisRouge} alt=""/>
                                    <h2 style={{color:'#FB7878'}}>{t('translation.eightTime')}</h2>
                                    <p>{t('translation.graph3a')}<br/>{t('translation.graph3b')}</p>
                                </div>
                            </div>

                            :

                            <div style={{marginTop: 0, width: '100vw', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex', width: '85vw', justifyContent: 'space-between',}}>
                                    <div className={'xFois'}>
                                        <img src={uneFois} alt=""/>
                                        <h2>1 fois</h2>
                                        <p>{t('translation.graph1a')}<br/>{t('translation.to')}{' '}<span style={{color: '#4660ff'}}>{t('translation.aFirst')}</span>{' '}{t('translation.stakeholder')}</p>
                                    </div>
                                    <img src={plan} alt="" style={{width: 'calc(100vw - 300px)', objectFit: 'contain', marginLeft: 30, alignSelf: 'flex-end'}}/>
                                </div>

                                <div style={{display: 'flex', width: '85vw', alignItems: 'center', marginLeft: 0, flexDirection: 'column'}}>
                                    <div className={'horizontalBarBleue'} style={{opacity: 0, marginTop: 20, marginBottom: 20}}/>
                                    <div style={{display: 'flex', width: '85vw', justifyContent: 'space-between',}}>
                                        <div className={'xFois'}>
                                            <img src={cinqFoisRouge} alt=""/>
                                            <h2 style={{color:'#FB7878'}}>5 fois</h2>
                                            <p>{t('translation.graph1a')}<br/>{t('translation.to')}{' '}<span style={{color: width < 900 ? '#fc3a3a' : null}}>{t('translation.otherStakeholder')}</span></p>
                                        </div>

                                        <div className={'xFois'} style={{display: 'flex', alignSelf: 'flex-start', flexDirection: 'column'}}>
                                            <img src={huitFoisRouge} alt=""/>
                                            <h2 style={{color:'#FB7878'}}>8 fois</h2>
                                            <p>{t('translation.theDocumentIs')}<span style={{color: width < 900 ? '#fc3a3a' : null}}>{' '}{t('translation.re')}<br/>{t('translation.transmited')}</span>{' '}{t('translation.becauseLost')}</p>
                                        </div>
                                    </div>

                                    <img
                                        src={unusefulComFrRouge}
                                        alt=""
                                        className={'unusefulComFr'}
                                        style={{
                                            marginTop: width < 1300 ? 50 : null,
                                            marginLeft: width < 1300 ? 0 : 360,
                                        }}
                                    />

                                </div>


                            </div>
                        }

                        {width > 1300 &&
                            <img src={unusefulComFrRouge} alt="" className={'unusefulComFr'}/>

                        }


                    </div>

                    <img src={width < 900 ? planSendImageRouge : planSendImage} alt="" className={'planSendImage'} style={{
                        marginLeft: width < 1300 ? '7.5vw' : 50,
                        marginTop: width < 1300 ? 70 : -150,
                        marginBottom: width < 1300 ? -45 : null,
                        width: width < 900 ? '70vw' : null,
                    }}/>


                </div>

                {/*
                <div style={{
                    width: '100vw',
                    height: 300,
                }}>
                    <BackgroundVideoPlayer src={videoOpus} style={{ width: '100%', height: '800px'}} />

                </div>
                */}


            </div>




        </div>
    )
}
