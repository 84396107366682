import React from 'react';
import ItemOptionCategoryFunctionality from './ItemOptionCategoryFunctionality';

export default function CategoryFunctionality({ category }) {
	return (
		<div className={'categoryFunctionality'}>
			<div className={'headerCategoryFunctionality'}>
				<div className={'firstPart'}>
					<h3 className={'title'}>{category.title}</h3>
				</div>
				<div className={'secondaryPart'} style={{ color: '#6C6C6C' }}>

				</div>

				<div className={'secondaryPart'} style={{ color: '#949ACC' }}>
					OPUS
				</div>

				<div className={'secondaryPart'} style={{ color: '#5667F6' }}>
					OPUS Services
				</div>
			</div>

			<div className={'listFunctionalities'}>
				{category.options.map((item) => (
					<ItemOptionCategoryFunctionality option={item} />
				))}
			</div>
		</div>
	);
}
