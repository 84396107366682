import React, {useContext, useEffect, useState} from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import PretAVousLancer from "../Home Page/PretAVousLancer";
import triangleBleuClair from "../../Assets/SVGS/triangleBleuClair.svg";
import chekDoubleBleu from "../../Assets/SVGS/chekDoubleBleu.svg";
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import blueMail from "../../Assets/SVGS/blueMail.svg";
import bluePhone from "../../Assets/SVGS/bluePhone.svg";
import FormItem from "./FormItem";
import {firebaseOpus} from "../../Firebase/config";
import {useTranslation} from "react-i18next";

export default function Contact() {
    const {t} = useTranslation();

    const [modalContactCommercial, setModalContactCommercial] = useState(false)
    const [modalContactAssistance, setModalContactAssistance] = useState(false);
    const [modalContactPresse, setModalContactPresse] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const [overflow, setOverflow] = useState(true)

    const initialData = {
        surname: '',
        name: '',
        mail: '',
        phone: '',
        company: '',
        companySite: '',
        companySize: '',
        country: '',
        projectNumber: '',
        notes: '',
    }
    const [data, setData] = useState(initialData)

    function handleChange(e) {
        setData({...data, [e.target.id]: e.target.value})
    }

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return (
        <div className={overflow ? 'page' : 'pageMenu'} style={{overflowY: overflow ? 'scroll' : 'hidden'}}>
            <div style={{
                backgroundColor: '#F7F9FC',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 10
            }}>
                <Header setOverflow={setOverflow} bleu={true}/>
            </div>

            <div className='contactPage' style={{zIndex: 1}}>

                <div style={{
                    display: 'flex',
                    backgroundColor: '#F7F9FC',
                    paddingTop: 65,
                    width: '100vw',
                    justifyContent: 'center',
                    zIndex: 10,
                    flexDirection: width > 990 ? 'row' : 'column',
                    alignItems: 'center'
                }}>
                    <div className={'textPart'}>
                        <p className={'title'}
                           style={{
                               marginTop: width > 990 ? null : 10,
                               marginBottom: width > 990 ? null : 0
                           }}>{t('translation.contactTeam1')}<br/>{t('translation.commercial')}</p>

                        <div style={{
                            display: 'flex',
                            flexDirection: width > 990 ? 'column' : 'row',
                            marginBottom: width > 990 ? null : 20
                        }}>
                            <div className={'secondThirdPart'} style={{
                                width: width > 740 ? null : '40vw',
                                marginTop: width > 740 ? null : 50,
                                marginLeft: 10
                            }}>
                                <img src={blueMail} alt="" style={{marginTop: 20, height: 30}} className={'img'}/>
                                <p className={'titlePart'}
                                   style={{
                                       marginBottom: 0,
                                       width: width > 990 ? null : 200
                                   }}>{t('translation.contactOurTeamByMail')}</p>

                                <a href="mailto:sales@opusoft.fr">
                                    <div style={{display: 'flex'}} className={'hover'}>
                                        <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>sales@opusoft.fr</p>
                                    </div>
                                </a>

                            </div>

                            <div className={'secondThirdPart'} style={{
                                width: width > 740 ? null : '40vw',
                                marginTop: width > 740 ? 30 : 50,
                                marginLeft: 10
                            }}>
                                <img src={bluePhone} alt="" style={{marginTop: 20, height: 30}} className={'img'}/>
                                <p className={'titlePart'}
                                   style={{
                                       marginBottom: 0,
                                       width: width > 990 ? null : 200
                                   }}>{t('translation.contactOurTeamByPhone')}</p>

                                <a href="tel:0185360070">
                                    <div style={{display: 'flex'}} className={'hover'}>
                                        <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>+33 (0)1 85 36 00
                                            70</p>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>

                    {isSubmit ?

                        <div className={'formPart'} style={{
                            marginLeft: width > 990 ? null : 0,
                            maxWidth: '90vw',
                            height: isSubmit ? 500 : width > 990 ? 680 : 940,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>

                            <img src={chekDoubleBleu} alt="" style={{width: 60, height: 60}}/>
                            <p style={{
                                fontSize: 22,
                                fontWeight: 600,
                                color: '#5f74fc',
                                marginTop: 40,
                            }}>{t('translation.communicationSent')}!</p>
                            <p style={{
                                fontSize: 18,
                                marginLeft: 70,
                                marginRight: 70,
                                textAlign: 'center',
                                marginTop: 0,
                                lineHeight: 1.5,
                                color: '#5f74fc',
                                fontWeight: 600,
                            }}>
                                {t('translation.ourTeamWillGetBackToYouInNoTime')}
                            </p>

                            <a href="/">
                                <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{
                                    width: width > 740 ? 230 : 264,
                                    paddingRight: 7,
                                    color: '#fff',
                                    marginTop: 50,
                                }}>
                                    {t('translation.returnToHomePage')}
                                    <img src={leftBlanc} alt="" className={'leftBleu'}/>

                                </div>
                            </a>

                        </div>

                        :

                        <div className={'formPart'} style={{
                            marginLeft: width > 990 ? null : 0,
                            maxWidth: '90vw',
                            height: width > 990 ? 680 : 940
                        }}>
                            <FormItem title={t('translation.firstName')} placeholder={'Marie'} id={'surname'}
                                      data={data}
                                      handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.lastName')} placeholder={'Richard'} id={'name'} data={data}
                                      handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.professionalEmail')} placeholder={'Marie@exemple.com'}
                                      id={'mail'}
                                      data={data} handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.professionalPhoneNumber')} placeholder={'+33 7 48 69 54 09'}
                                      id={'phone'} data={data} handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.companyName')} placeholder={'Architecte & Design Agency'}
                                      id={'company'} data={data} handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.companyWebsite')} placeholder={'exemple.com'}
                                      id={'companySite'}
                                      data={data} handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.companySize')} placeholder={''} select={true} taille={true}
                                      id={'companySize'} data={data} handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.country')} placeholder={''} select={true} id={'country'}
                                      data={data}
                                      handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.numberOfOngoingProjet')} placeholder={'13'} number={true}
                                      id={'projectNumber'} data={data} handleChange={handleChange} width={width}/>
                            <FormItem title={t('translation.anythingElseYouWouldLikeToAdd')}
                                      placeholder={t('translation.tellUsMoreAboutYourSituation')}
                                      textArea={true} id={'notes'} data={data} handleChange={handleChange}
                                      width={width}/>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: 550,
                                marginTop: width > 990 ? 17 : 25,
                                maxWidth: '90vw'
                            }}>
                                <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{
                                    paddingRight: 14,
                                    color: '#fff',
                                    marginRight: 20,
                                    whiteSpace: 'nowrap'
                                }} onClick={async () => {
                                    await firebaseOpus.firestore().collection('analytics/site/contact').add({
                                        ...data,
                                        date: new Date().toISOString()
                                    });
                                    setData(initialData);
                                    setIsSubmit(true);
                                }}>
                                    {t('translation.contactOurSalesDepartment')}
                                    <img src={leftBlanc} alt="" className={'leftBleu'}/>
                                </div>
                            </div>

                        </div>

                    }

                </div>

                <img src={triangleBleuClair} alt="" style={{
                    width: '100vw',
                    objectFit: 'contain',
                    marginBottom: width > 990 ? -180 : 60,

                }}
                     className={'miroir'}
                />

                <div style={{marginBottom: -40}}>
                    <PretAVousLancer contact={true}/>
                </div>

                <div className={'transitionFAQ'}>
                    <p className={'titleTransitionFAQ'}>FAQ</p>
                    <p className={'subtitle'}>{t('translation.whatIfTheAnswerYouWereLookingForWasHere')}</p>
                </div>

                <div className={'questionReponse'} style={{alignItems: 'center', width: '100vw'}}>

                    <div style={{
                        display: 'flex',
                        marginTop: 90,
                        flexDirection: width > 990 ? 'row' : 'column',
                        maxWidth: '80vw'
                    }}>

                        <div className={'chiffrePart'}
                             style={{marginLeft: width > 990 ? null : 0, marginBottom: width > 990 ? null : 20}}>
                            <p className={'chiffre'}>1</p>
                            <div className={'barBleue'}/>
                        </div>

                        <div className={'colonneQuestionReponse'}>
                            <p className={'titrePartie'}>{t('translation.titlePart1Faq')}</p>

                            <p className={'questionPosee'}>{t('translation.question1Part1Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer1Part1Faq')}
                            </p>

                            <p className={'questionPosee'} style={{marginTop: 14,}}>{t('translation.question2Part1Faq')}
                            </p>
                            <p className={'reponseQuestion'}>{t('translation.answer2Part1Faq')}
                            </p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question3Part1Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer3Part1Faq')}
                            </p>


                        </div>


                        <div className={'colonneQuestionReponse'}>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question4Part1Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer4Part1Faq')}
                            </p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question5Part1Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer5Part1Faq')}</p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question6Part1Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer6Part1Faq')}
                            </p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question7Part1Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer7Part1Faq')}</p>

                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        marginTop: 100,
                        flexDirection: width > 990 ? 'row' : 'column',
                        maxWidth: '80vw'
                    }}>
                        <div className={'chiffrePart'}
                             style={{marginLeft: width > 990 ? null : 0, marginBottom: width > 990 ? null : 20}}>
                            <p className={'chiffre'}>2</p>
                            <div className={'barBleue'}/>
                        </div>

                        <div className={'colonneQuestionReponse'}>
                            <p className={'titrePartie'}>{t('translation.titlePart2Faq')}</p>

                            <p className={'questionPosee'}>{t('translation.question1Part2Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer1Part2Faq')}
                            </p>

                            <p className={'questionPosee'} style={{marginTop: 14,}}>{t('translation.question2Part2Faq')}
                            </p>
                            <p className={'reponseQuestion'}>{t('translation.answer2Part2Faq')}
                            </p>

                        </div>

                        <div className={'colonneQuestionReponse'}>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question3Part2Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer3Part2Faq')}
                            </p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question4Part2Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer4Part2Faq')}
                            </p>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        marginTop: 100,
                        flexDirection: width > 990 ? 'row' : 'column',
                        maxWidth: '80vw'
                    }}>
                        <div className={'chiffrePart'}
                             style={{marginLeft: width > 990 ? null : 0, marginBottom: width > 990 ? null : 20}}>
                            <p className={'chiffre'}>3</p>
                            <div className={'barBleue'}/>
                        </div>

                        <div className={'colonneQuestionReponse'}>
                            <p className={'titrePartie'}>{t('translation.titlePart3Faq')}</p>

                            <p className={'questionPosee'}>{t('translation.question1Part3Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer1Part3Faq')}
                            </p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question2Part3Faq')}</p>

                            <p className={'reponseQuestion'}>{t('translation.answer2Part3Faq')}
                            </p>

                        </div>

                        <div className={'colonneQuestionReponse'}>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question3Part3Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer3Part3Faq')}
                            </p>

                            <p className={'questionPosee'}
                               style={{marginTop: 14,}}>{t('translation.question4Part3Faq')}</p>
                            <p className={'reponseQuestion'}>{t('translation.answer4Part3Faq')}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
