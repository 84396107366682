import React, {useState, useEffect} from 'react';
import leftBlanc from "../../Assets/SVGS/leftBlanc.svg";
import leftBleuRandom from "../../Assets/SVGS/leftBleuRandom.svg";
import priceLogo from "../../Assets/SVGS/priceLogo.svg";
import integrationLogo from "../../Assets/SVGS/integrationLogo.svg";
import {useTranslation} from "react-i18next";

export default function PretAVousLancer() {
    const { t } = useTranslation();

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={'PretAVousLancer'} style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column', alignItems: width > 1200 ? 'flex-start' : 'center', marginTop: width > 740 ? null : 50, marginBottom: width > 740 ? null : 20}}>

            <div className={'firstPart'}>
                <p className={'title'}>{t('translation.readyToStart')}</p>
                <p className={'description'} style={{width: width > 1200 ? null : width > 740 ? 510 : '80vw', marginRight: width > 1200 ? null : 0}}>
                    {t('translation.textHomePage5')}
                </p>

                <div style={{display: 'flex'}}>

                    {
                        width > 600 &&
                        <a href="https://opusoft.app/OnBoarding" target="_blank">
                            <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{paddingRight: 14, color: '#fff'}}>
                                {t('translation.start')}
                                <img src={leftBlanc} alt="" className={'leftBleu'}/>
                            </div>
                        </a>
                    }

                    { width > 600 ?

                        <a href="/contact">
                            <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{paddingRight: 10, backgroundColor: '#FFFFFF', color: '#5F74FC'}}>
                                {t('translation.contactSalesTeam')}
                                <img src={leftBleuRandom} alt="" className={'leftBleu'}/>
                            </div>
                        </a>
                        :
                        <a href="/contact">
                            <div className={width > 740 ? 'blueButton' : 'littleBlueButton'} style={{paddingRight: 14, color: '#fff'}}>
                                {t('translation.contactSalesTeam')}
                                <img src={leftBlanc} alt="" className={'leftBleu'}/>
                            </div>
                        </a>
                    }



                </div>

            </div>

            <div style={{display: 'flex', flexDirection: width > 740 ? 'row' : 'column', marginTop: width > 1200 ? null : width > 740 ? 70 : 0}}>
                <div className={'secondThirdPart'} style={{width: width > 740 ? null : '90vw', marginTop: width > 740 ? null : 50}}>
                    <img src={priceLogo} alt="" style={{marginTop: 20,}} className={'img'}/>
                    <p className={'title'}>{t('translation.haveAClearIdeaOfWhatYouAreGoingToPay')}</p>
                    <p className={'subtitle'} style={{
                        width: width > 740 ? null : '85vw'
                    }}>{t('translation.clearPrincingTailoredForYourCompany')}</p>
                    <a href="/tarifs">
                        <div style={{display: 'flex'}} className={'hover'}>
                            <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>{t('translation.informationOnPricing')}</p>
                            <img src={leftBleuRandom} alt="" className={width > 740 ? 'leftBleu' : 'littleLeftBleu'}/>
                        </div>
                    </a>

                </div>

                <div className={'secondThirdPart'} style={{width: width > 740 ? null : '90vw', marginTop: width > 740 ? null : 20}}>
                    <img src={integrationLogo} alt="" style={{marginTop: 20,}} className={'img'}/>
                    <p className={'title'}>{t('translation.startYourIntegration')}</p>
                    <p className={'subtitle'} style={{
                        width: width > 740 ? null : '85vw'
                    }}>{t('translation.startOnOpusInLessThan10mins')}</p>

                    {width > 600 ?
                        <a href="https://opusoft.app/OnBoarding" target="_blank">
                            <div style={{display: 'flex'}} className={'hover'}>
                                <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>{t('translation.accessOpus')}</p>
                                <img src={leftBleuRandom} alt="" className={width > 740 ? 'leftBleu' : 'littleLeftBleu'}/>
                            </div>
                        </a>
                        :
                        <a href="https://apps.apple.com/fr/app/opus-architecture-design/id1591807716" target="_blank">
                            <div style={{display: 'flex'}} className={'hover'}>
                                <p className={width > 740 ? 'buttonBleu' : 'littleButtonBleu'}>{t('translation.accessOpus')}</p>
                                <img src={leftBleuRandom} alt="" className={width > 740 ? 'leftBleu' : 'littleLeftBleu'}/>
                            </div>
                        </a>
                    }

                </div>
            </div>



        </div>
    )
}
