import React from 'react';
import {useTranslation} from "react-i18next";

export default function PlanTypeChoices({ planType, setPlanType }) {
	const { t } = useTranslation();
	return (
		<div className={'planTypeChoices'}>
			<p className={'title'}>{t('translation.chooseYourPlan')}</p>
			<div className={'planTypeChoice'} >
				<div
					className={'containerRond'}
					style={{ borderColor: planType === 'monthly' ? '#5667F6' : '#c7c7c7' }}>
					<div
						className={'rond'}
						style={{ backgroundColor: planType === 'monthly' ? '#5667F6' : '#ffffff' }}
					/>
				</div>
				<div style={{ opacity: planType === 'monthly' ? 1 : 0.4 }} className={'transition'}>
					<p className={'planType'}>{t('translation.monthlyBilling')}</p>
					<p className={'recomand'}>{t('translation.recommanded')}</p>
				</div>
			</div>

			<div className={'planTypeChoice'}>
				<div
					className={'containerRond'}
					style={{ borderColor: planType === 'annually' ? '#5667F6' : '#c7c7c7' }}>
					<div
						className={'rond'}
						style={{ backgroundColor: planType === 'annually' ? '#5667F6' : '#ffffff' }}
					/>
				</div>
				<div style={{opacity: planType === 'annually' ? 1 : 0.4}} className={'transition'}>
					<p className={'planType'}>{t('translation.annualBilling')}</p>
					<p className={'soon'}>{t('translation.soonAvailable')}</p>
				</div>
			</div>
		</div>
	);
}
