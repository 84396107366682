import React, {useState, useEffect} from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import LiensApplications from "./LiensApplications";
import HeaderChoosePlanPage from './components/HeaderChoosePlanPage';
import PlanTypeChoices from './components/PlanTypeChoices';
import PlanChoices from './components/PlanChoices';
import TableauFunctionalities from './components/TableauFunctionalities';
import './_choosePlanPage.scss';
import {useTranslation} from "react-i18next";

export default function PolitiqueConfidentialite() {
    const { t } = useTranslation();

    const [overflow, setOverflow] = useState(true)
    const [planType, setPlanType] = useState('monthly');

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    const [width, setWidth] = useState(0);

    window.addEventListener("resize", Update);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={overflow ? 'page' : 'pageMenu'}>

            <div style={{zIndex: 10}}>
                <Header setOverflow={setOverflow} blue={true}/>

            </div>

            <div className={'choosePlanPage'}>
                <HeaderChoosePlanPage/>
                <div className={'containerPlans'}>
                    <PlanTypeChoices planType={planType} setPlanType={setPlanType}/>
                    <PlanChoices/>
                </div>

                <TableauFunctionalities/>
            </div>

            <p className={'moreInfosBigWebsite'}>{t('translation.bodyPricing5')}</p>


            <div style={{zIndex: 1}}>

            </div>

            <LiensApplications/>

            <Footer/>

        </div>
    )
}
