import React, {useState} from 'react';
import Header from "../../components/Header/Header";
import Chart from 'chart.js/auto';
import {Doughnut, Line} from "react-chartjs-2";
import {CategoryScale} from 'chart.js';

export default function Emplois() {


    const [overflow, setOverflow] = useState(true)

    return(
        <div className={'page'}>
            <Header setOverflow={setOverflow}/>

            <div className={'emplois'}>


            </div>

        </div>
    )
}
